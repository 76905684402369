export class MobileVersionModel {
    Id: number;
    Version: string;
    Status: MobileVersionStatus;
    PreviousStatus: MobileVersionStatus;
}

export enum MobileVersionStatus {
    Current = 'Current',
    Compatible = 'Compatible',
    NotSupported = 'Not Supported'
}
