import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Activity, Comment, ActivityReport, ReportFilter } from 'models';
import { ActivityStartJustification } from '#models/activityStartJustification';

@Injectable()
export class ActivityService {
    constructor(
        private http: HttpClient
    ) { }

    public rejectActivity(activity: Activity): Observable<any> {
        return this.http.post<void>(`${environment.apiEndpoint}/activity/reject`, [{
            ActivityId: activity.Id,
            Comment: activity.rejectionComment
        }]);
    }

    public getComments(activityId: number): Observable<Comment[]> {
        return this.http.get<Comment[]>(`${environment.apiEndpoint}/Justification/activity/${activityId}`);
    }

    public getJustifications(activityId: number): Observable<ActivityStartJustification[]> {
        return this.http.get<ActivityStartJustification[]>(`${environment.apiEndpoint}/activityStartJustifications?activityId=${activityId}`);
    }

    public getActivityReport(filter: ReportFilter): Observable<ActivityReport[]> {
        return this.http.post<ActivityReport[]>(`${environment.apiEndpoint}/activity/report`, filter);
    }

    public getActivitiesForProject(projectId : number) : Observable<any> {
      return this.http.get<Comment[]>(`${environment.apiEndpoint}/activity/${projectId}`);
    }

    public sendNotificationEmail(projectId: number) : Observable<any> {
        return this.http.post<any>(`${environment.apiEndpoint}/project/projects/emaillookaheadstatus`, projectId);
    }
}
