import { User } from './user';
import { Crew } from './crew';
import { Project } from './project';

export class CrewAssignment {
  UserId: number;
  CrewId: number;
  ProjectId: number;
  IsReadOnly: boolean;
  User: User;
  Crew: Crew;
  Project: Project;

  IsDeleted: boolean;
}
