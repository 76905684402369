import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ProjectService } from '#services/api';
import { MessageService } from 'primeng/api';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { Instance } from '#models/instance';
import { TranslateService } from '@ngx-translate/core';
import { finalize, firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-master-data-sync',
  templateUrl: './master-data-sync.component.html',
  styleUrls: ['./master-data-sync.component.scss']
})
export class MasterDataSyncComponent  {
  private displayValue: boolean;
  private doSyncValue: boolean = true;
  private instancesValue: Instance[];

  @Output()
  displayChange = new EventEmitter<boolean>();

  @Input()
  get display() {
    return this.displayValue;
  }

  set display(val) {
    this.displayValue = val;
    this.displayChange.emit(this.displayValue);
  }

  @Input()
  get instances() {
    return this.instancesValue;
  }

  set instances(val) {
    this.instancesValue = val;
  }

  constructor(private projectSvc: ProjectService, private messageSvc: MessageService, private loadingIndicatorSvc: LoadingIndicatorService, private translate: TranslateService) {}



  masterDataSync() {
    this.loadingIndicatorSvc.show();

    if(this.instances) {
      this.syncInstances();
    } else {
      this.syncAll();
    }
  }

  private syncAll() {
    this.projectSvc
      .downloadNonProjectData().pipe(
      finalize(() => {
        this.loadingIndicatorSvc.hide();
        this.display = false;
      }))
      .subscribe(() => {
        (async () => {
          this.messageSvc.add({ severity: 'success', summary: await firstValueFrom(this.translate.get('COMPONENTS.MasterDataSynched')) });
        })();
      });
  }

  private async syncInstances() {
    for (let i of this.instances) {
      await this.syncInstance(i);
    }

    this.loadingIndicatorSvc.hide();
    this.display = false;
}

  private async syncInstance(instance: Instance) {
    const successMessage = await firstValueFrom(this.translate.get('COMPONENTS.InstanceName', {instanceName: instance.Name}));
    await firstValueFrom(this.projectSvc.downloadNonProjectData(instance.Id))
      .then(() => {
          this.messageSvc.add({ severity: 'success', summary: successMessage });
      });
  }
}
