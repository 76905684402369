<div class="synchronization-module">
    <div class="panel">
      <div class="row">
        <h3>{{'PROJECTSMANAGEMENT.Title' | translate}}</h3>
      </div>
      <div class="row">
        <div class="form-group col-sm-4">
          <label *ngIf="instanceList?.length > 1" for="projectList">{{'SYNCHRONIZATION.Instance' | translate}}:</label>
          <p-dropdown *ngIf="instanceList?.length > 1" name="instanceList" [options]="instanceList" optionLabel="Name"
            [style]="{'width':'100%'}" [(ngModel)]="currentInstance" (ngModelChange)="resetTable()"
            scrollHeight="300px" [style]="{'width':'100%'}"></p-dropdown>
        </div>
      </div>
      <div class="panel">
        <div class="container">
          <div class="filters">
            <div class="row">
              <div class="col col-sm-2 date-input">
                <label>{{'PROJECTINFO.From' | translate}}: </label>
                <p-calendar [(ngModel)]="startDate" [style]="{'width':'100%'}" [keepInvalid]="true"
                  [inputStyle]="{'width':'100%'}" (onSelect)="filterDate($event, 'StartDate', 'gte')"
                  [readonlyInput]="true">
                </p-calendar>
              </div>
              <div class="col col-sm-2 date-input">
                <label>{{'PROJECTINFO.To' | translate}}: </label>
                <p-calendar [(ngModel)]="finishDate" [style]="{'width':'100%'}" [keepInvalid]="true"
                  [inputStyle]="{'width':'100%'}" (onSelect)="filterDate($event, 'FinishDate', 'lte')"
                  [readonlyInput]="true">
                </p-calendar>
              </div>
              <div class="col col-sm-8 reset">
                <label></label>
                <button type="button" class="btn btn-primary" (click)="resetTable()">{{'PROJECTINFO.Reset' |
                  translate}}</button>
              </div>
            </div>
          </div>
          <p-table #dt [value]="projects" sortMode="multiple" [paginator]="true" [rows]="15" [globalFilterFields]="['ProjectName']">
            <ng-template pTemplate="caption">
              <div class="row">
                <div class="col-sm-6">
                  <input type="text" pInputText placeholder="{{'USERS.Filter' | translate}}" (input)="dt.filterGlobal($event.target.value, 'contains')"
                    class="form-control">
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th scope="col" pSortableColumn="InstanceName">
                  {{'PROJECTINFO.InstanceName' | translate}}
                  <p-sortIcon field="InstanceName" ariaLabel="{{'PROJECTINFO.ActivateSort' | translate}}"
                    ariaLabelDesc="{{'PROJECTINFO.ActivateSortDesc' | translate}}"
                    ariaLabelAsc="{{'PROJECTINFO.ActivateSortAsc' | translate}}">
                  </p-sortIcon>
                </th>
                <th scope="col" pSortableColumn="ProjectName">
                  {{'PROJECTINFO.ProjectName' | translate}}
                  <p-sortIcon field="ProjectName" ariaLabel="{{'PROJECTINFO.ActivateSort' | translate}}"
                    ariaLabelDesc="{{'PROJECTINFO.ActivateSortDesc' | translate}}"
                    ariaLabelAsc="{{'PROJECTINFO.ActivateSortAsc' | translate}}">
                  </p-sortIcon>
                </th>
                <th scope="col" pSortableColumn="ProjectType">
                  {{'PROJECTINFO.ProjectType' | translate}}
                  <p-sortIcon field="ProjectType" ariaLabel="{{'PROJECTINFO.ActivateSort' | translate}}"
                    ariaLabelDesc="{{'PROJECTINFO.ActivateSortDesc' | translate}}"
                    ariaLabelAsc="{{'PROJECTINFO.ActivateSortAsc' | translate}}">
                  </p-sortIcon>
                </th>
                <th scope="col" pSortableColumn="StartDate">
                  {{'PROJECTINFO.StartDate' | translate}}
                  <p-sortIcon field="StartDate" ariaLabel="{{'PROJECTINFO.ActivateSort' | translate}}"
                    ariaLabelDesc="{{'PROJECTINFO.ActivateSortDesc' | translate}}"
                    ariaLabelAsc="{{'PROJECTINFO.ActivateSortAsc' | translate}}">
                  </p-sortIcon>
                </th>
                <th scope="col" pSortableColumn="FinishDate">
                  {{'PROJECTINFO.FinishDate' | translate}}
                  <p-sortIcon field="FinishDate" ariaLabel="{{'PROJECTINFO.ActivateSort' | translate}}"
                    ariaLabelDesc="{{'PROJECTINFO.ActivateSortDesc' | translate}}"
                    ariaLabelAsc="{{'PROJECTINFO.ActivateSortAsc' | translate}}">
                  </p-sortIcon>
                </th>
                <th scope="col" pSortableColumn="Completed">
                  {{'PROJECTINFO.ProjectCompleted' | translate}}
                  <p-sortIcon field="Completed" ariaLabel="{{'PROJECTINFO.ActivateSort' | translate}}"
                    ariaLabelDesc="{{'PROJECTINFO.ActivateSortDesc' | translate}}"
                    ariaLabelAsc="{{'PROJECTINFO.ActivateSortAsc' | translate}}">
                  </p-sortIcon>
                </th>
                <th scope="col" pSortableColumn="Enabled">Active
                  <p-sortIcon field="Completed" ariaLabel="{{'PROJECTINFO.ActivateSort' | translate}}"
                    ariaLabelDesc="{{'PROJECTINFO.ActivateSortDesc' | translate}}"
                    ariaLabelAsc="{{'PROJECTINFO.ActivateSortAsc' | translate}}">
                  </p-sortIcon>
              </tr>
              <tr>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col" [ngSwitch]="'projectType'">
                  <p-multiSelect [ngSwitch]="'projectType'" [options]="projectTypefilterOpts"
                    defaultLabel="{{'PROJECTINFO.AllTypes' | translate}}" (onChange)="filterByProjectType($event)">
                  </p-multiSelect>
                </th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col" [ngSwitch]="'completed'">
                  <p-multiSelect [ngSwitch]="'completed'" [options]="filterCompletedStatusOpts"
                    defaultLabel="{{'PROJECTINFO.AllStatus' | translate}}" (onChange)="filterByProjectState($event)">
                  </p-multiSelect>
                </th>
                <th scope="col"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-info>
              <tr>
                <td>{{info.ProjectInstanceName}} - {{info.ProjectId}}</td>
                <td>{{info.ProjectName}}</td>
                <td>{{info.ProjectType}}</td>
                <td>{{info.StartDate === null ? '---' :(info.StartDate | date:'M/d/yyyy')}}</td>
                <td>{{info.FinishDate === null ? '---' :(info.FinishDate | date:'M/d/yyyy')}}</td>
                <td>{{(info.Completed? 'SHARED.Yes' : 'SHARED.No') | translate}}</td>
                <td>
                    <div>
                      <p-inputSwitch [(ngModel)]="info.Enabled" (click)="selectionChange(info)"></p-inputSwitch>
                    </div>
                  </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
  
    </div>