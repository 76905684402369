<div class="panel">
  <h2>{{ 'COMMUNICATION.TeamCom' | translate }}</h2>
  <h3>{{ 'COMMUNICATION.For' | translate }}</h3>
  <h3>{{ selectedProject.name }}</h3>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h4 style="text-align:left; color: red">{{'COMMUNICATION.Note' | translate}}</h4>
        <h4 style="text-align:left; color: red">{{'COMMUNICATION.NoteText' | translate}}</h4>
      </div>
      <div class="col-sm-12">
        <div  style="margin-top: 2vh;">
          <label style="font-size: 15px;">{{'COMMUNICATION.PrimaveraDownNotification' | translate}}</label>
          <p-button (click)="confirmSendNotification()" [disabled]="!selectedProject.id" style="margin-top: 1vh;" styleClass="ui-button-primary" label="{{'COMMUNICATION.SendNotification' | translate}}"></p-button>
        </div>
      </div>
      <div class="col-sm-12">
        <div style="margin-top: 2vh;">
          <label style="font-size: 15px;">{{'COMMUNICATION.PrimaveraDownEmail' | translate}}</label>
          <p-button (click)="confirmSendEmail()" [disabled]="!selectedProject.id" style="margin-top: 1vh;" styleClass="ui-button-primary" label="{{'COMMUNICATION.SendEmail' | translate}}"></p-button>
        </div>
      </div>
      <div class="col-sm-12">
        <div  style="margin-top: 2vh;">
          <label style="font-size: 15px;">{{'COMMUNICATION.SendGroupNotification' | translate}}</label><br/>
          <p-button (click)="showDialog()" [disabled]="!selectedProject.id" style="margin-top: 1vh;" styleClass="ui-button-primary" label="{{'COMMUNICATION.SendGroupMessage' | translate}}"></p-button>
        </div>
      </div>
    </div>
    <p-dialog header="{{'COMMUNICATION.SendGroupMessage' | translate}}" [(visible)]="displayAddDialog" [responsive]="true" showEffect="fade"
    [modal]="true" styleClass="customDialog">
    <div  class="ui-grid ui-grid-responsive ui-fluid">
      <div class="ui-grid-row">
        <div class="ui-grid-col-4"><label for="text">{{'COMMUNICATION.SelectGroups' | translate}}</label></div>
        <div class="ui-grid-col-8"><p-multiSelect [options]="groupList" [(ngModel)]="selectedGroupList" optionLabel="Crew.Description" appendTo="body" optionValue="Crew.CrewId"></p-multiSelect></div>
      </div>
      <div class="ui-grid-row">
        <p></p>
      </div>
      <div class="ui-grid-row">
        <div class="ui-grid-col-4"><label for="text">{{'COMMUNICATION.Message' | translate}}</label></div>
        <div class="ui-grid-col-8"><textarea pInputTextarea style="width: 100%;" rows="5" id="messageTxt" [(ngModel)]="message" ></textarea></div>
      </div>
    </div>
    <p-footer>
      <div class="ui-dialog-buttonpane ui-helper-clearfix">
        <button type="button" pButton icon="fa-close" (click)="closeDialog()" label="{{'COMMUNICATION.Cancel' | translate}}"></button>
        <button type="button" pButton icon="fa-check" (click)="sendGroupMessage()" label="{{'COMMUNICATION.Send' | translate}}"></button>
      </div>
    </p-footer>
  </p-dialog>
  </div>
</div>
