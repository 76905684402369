export class Shift {
  Id: number;
  Name: string;
  ProjectId: string;
  StartTime: Date;
  EndTime: Date;
  EndTimeOffset: Date;
  Sequence: number;
  Enabled: boolean;

  static create(projectId: number, nameSurfix: string): Shift {
    const newShift = new Shift();
    newShift.init({
      Enabled: true,
      EndTime: '17:00:00',
      EndTimeOffset: '00:00:00',
      Name: `Shift ${nameSurfix}`,
      ProjectId: projectId,
      StartTime: '06:00:00'
    });
    return newShift;
  }

  init(shiftJson: object) {
    this.Id = shiftJson['Id'];
    this.Name = shiftJson['Name'];
    this.ProjectId = shiftJson['ProjectId'];
    this.Sequence = shiftJson['Sequence'];
    this.Enabled = shiftJson['Enabled'];
    this.StartTime = this.getDateFromTimeString(shiftJson['StartTime']);
    this.EndTime = this.getDateFromTimeString(shiftJson['EndTime']);
    this.EndTimeOffset = this.getDateFromTimeString(shiftJson['EndTimeOffset']);
  }

  toJSON(): object {
    return {
      Id: this.Id,
      Name: this.Name,
      ProjectId: this.ProjectId,
      StartTime: this.getTimeStringFromDate(this.StartTime),
      EndTime: this.getTimeStringFromDate(this.EndTime),
      EndTimeOffset: this.getTimeStringFromDate(this.EndTimeOffset),
      Sequence: this.Sequence,
      Enabled: this.Enabled
    };
  }

  private getDateFromTimeString(time: string): Date {
    const timeArr = time.split(':').map(x => parseInt(x, 10));
    const date = new Date();
    date.setHours(timeArr[0], timeArr[1], timeArr[2]);
    return date;
  }

  private getTimeStringFromDate(date: Date): string {
    return `${date.getHours()}:${date.getMinutes()}:00`; // only get hours and minutes
  }
}
