import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Feedback } from 'models';

@Injectable()
export class FeedbackService {
    constructor(
        private http: HttpClient
    ) { }

    public postFeedback(feedback: Feedback): Observable<void> {
        return this.http.post<void>(`${environment.apiEndpoint}/feedback`, feedback);
    }
}
