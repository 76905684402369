import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
@Injectable()
export class EmailService {
    private readonly httpOptions: any = { headers: { 'Content-Type': 'application/json' } };

    constructor(
        private http: HttpClient,
    ) { }

    // public send(params): Observable<any> {
    //     return this.http.post<any>(`${environment.apiEndpoint}/email/send`, params);
    // }

    public newUserNotification(user): Observable<any> {
        return this.http.post<any>(`${environment.apiEndpoint}/email/new-user`, user);
    }


}
