import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { LoadingIndicatorService } from '#services/shared';
import { MessageService, SelectItem } from 'primeng/api';
import { CrewAssignmentService, WbsService } from '#services/api';
import { Crew, WBS, WBSCategory, ReportFilter } from '#models/index';
import { Subscription } from 'rxjs/internal/Subscription';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime, distinctUntilChanged, finalize, switchMap } from 'rxjs/operators';
import { EMPTY, firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-activity-report-filters',
  templateUrl: './activity-report-filters.component.html',
  styleUrls: ['./activity-report-filters.component.scss']
})
export class ActivityReportFiltersComponent implements OnInit, OnDestroy {

  @Input() projectId: number;
  @Output() filter: EventEmitter<ReportFilter> = new EventEmitter<ReportFilter>();
  @Output() groupActivities: EventEmitter<string> = new EventEmitter<string>();
  showCollapse: boolean = false;
  expanded: boolean = false;
  @Output() collapse: EventEmitter<boolean> = new EventEmitter<boolean>();

  hideFilter: boolean = true;

  WBSCategoryFilterData: WBSCategory[];
  WBSFilterData: WBS[];
  crewFilterData: Crew[];

  statusFilterData: SelectItem[] = [];
  groupByData: SelectItem[] = [];

  term$ = new Subject<string>();
  @Output() searchText: EventEmitter<string> = new EventEmitter<string>();
  private searchSubscription: Subscription;
  @Input() searchKey: string = '';
  selectedWBSCategoryFilter: WBSCategory[];
  selectedWBSFilter: WBS[];
  selectedcrewFilter: Crew[];
  selectedstatusFilter: any[];
  reportFilter: ReportFilter;

  constructor(
    private loadingIndicatorSvc: LoadingIndicatorService,
    private messageSvc: MessageService,
    private crewSvc: CrewAssignmentService,
    private wbsSvc: WbsService,
    private translate: TranslateService) {
    this.searchSubscription = this.term$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(term => {
          this.searchText.emit(term);
          return EMPTY;
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.searchSubscription.unsubscribe();
  }

  async ngOnInit() {
    this.groupByData = [
      { label: await firstValueFrom(this.translate.get('COMPONENTS.None')), value: 'None' },
      { label: await firstValueFrom(this.translate.get('COMPONENTS.Job')), value: 'Job' },
      { label: await firstValueFrom(this.translate.get('COMPONENTS.PrimaryCrew')), value: 'PrimaryCrew' },
      { label: await firstValueFrom(this.translate.get('COMPONENTS.SecondaryCrew')), value: 'SecondaryCrew' },
      { label: await firstValueFrom(this.translate.get('COMPONENTS.CoRep')), value: 'CoRepCrew' },
      { label: await firstValueFrom(this.translate.get('COMPONENTS.Status')), value: 'Status' }
    ];
    this.statusFilterData = [
      { label: await firstValueFrom(this.translate.get('COMPONENTS.Approved')), value: 'Approved' },
      { label: await firstValueFrom(this.translate.get('COMPONENTS.Rejected')), value: 'Rejected' },
      { label: await firstValueFrom(this.translate.get('COMPONENTS.CantWork')), value: 'CantWork' },
      { label: await firstValueFrom(this.translate.get('COMPONENTS.NoAction')), value: null }
    ];
    this.getReportFilterData();
  }

 async getReportFilterData() {
    this.loadingIndicatorSvc.show();
    this.crewSvc
      .getCrewList(this.projectId).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe({
        next:(data: Crew[]) => this.crewFilterData = data,
        error:(e) => {
          (async () => {
            this.showError(e, await firstValueFrom(this.translate.get('COMPONENTS.FailedCrews')))
          })();
        },
      });

    this.loadingIndicatorSvc.show();
    this.wbsSvc
      .getWbsforProjectByActivities(this.projectId).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe({
        next:(data: WBS[]) => this.WBSFilterData = data,
        error:(e) => {
          (async () => {
            this.showError(e, await firstValueFrom(this.translate.get('COMPONENTS.FailedWBS')))
          })();
        },
      });

    this.loadingIndicatorSvc.show();
    this.wbsSvc
      .getWbsCategoriesByActivities(this.projectId).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe({
        next:(data: WBSCategory[]) => this.WBSCategoryFilterData = data,
        error:(e) => {
          (async () => {
            this.showError(e, await firstValueFrom(this.translate.get('COMPONENTS.FailedWBSCategories')))
          })();
        },
      });
  }

  private showError(e, message: string) {
    this.messageSvc.add({
      severity: 'error',
      summary: message,
      detail: e.error.Message || e.message || ''
    });
  }

  applyFilters() {
    this.reportFilter = this.reportFilter ? this.reportFilter : new ReportFilter();
    this.reportFilter.ProjectId = this.projectId;
    this.reportFilter.WbsCategoryIds = this.selectedWBSCategoryFilter ? this.selectedWBSCategoryFilter.map(wc => wc.Id) : null;
    this.reportFilter.WbsIds = this.selectedWBSFilter ? this.selectedWBSFilter.map(wbs => wbs.Id) : null;
    this.reportFilter.CrewIds = this.selectedcrewFilter ? this.selectedcrewFilter.map(crew => crew.CrewId) : null;
    this.reportFilter.Statuses = this.selectedstatusFilter ? this.selectedstatusFilter : null;
    this.searchKey = '';
    this.term$.next(this.searchKey);
    this.filter.emit(this.reportFilter);
  }

  clearFilters() {
    this.selectedWBSCategoryFilter = null;
    this.selectedWBSFilter = null;
    this.selectedcrewFilter = null;
    this.selectedstatusFilter = null;
    this.searchKey = '';
    this.term$.next(this.searchKey);
    this.applyFilters();
  }

  applyGroupBy(event) {
    this.showCollapse = event.value && event.value !== 'None' ? true : false;
    this.expanded = false;
    this.searchKey = '';
    this.term$.next(this.searchKey);
    this.collapse.emit(this.expanded);
    this.groupActivities.emit(event.value);
  }

  collapseAll() {
    this.expanded = !this.expanded;
    this.collapse.emit(this.expanded);
  }


}
