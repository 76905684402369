import { EventEmitter, Injectable } from '@angular/core';
import { ErrorDialogModel } from '#models/errorDialogModel';

@Injectable()
export class ErrorDialogService {
  private notificationEvt: EventEmitter<ErrorDialogModel> = new EventEmitter();

  add(model: ErrorDialogModel) {
      if(!model.id) {
        console.error('id must be provided');
        return;
      }
      this.notificationEvt.emit(model);
  }

  public getNotificationSubscription(): EventEmitter<ErrorDialogModel> {
    return this.notificationEvt;
  }
}
