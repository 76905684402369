import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './not-found/not-found.component';
import { NoAccessComponent } from './no-access/no-access.component';
import { UnknownErrorComponent } from './unknown-error/unknown-error.component';
import { SharedModule } from '#services/shared/shared.module';

@NgModule({
    declarations: [
        NotFoundComponent,
        NoAccessComponent,
        UnknownErrorComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ],
    providers: [
    ]
})

export class ErrorPagesModule { }
