import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { catchError, Observable, Subject, of, throwError } from 'rxjs';


import { AuthService } from '#services/shared';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor{
  private refreshTokenInProgress = false;
  token="";

  private tokenRefreshedSource = new Subject();
  private tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(private route:Router,private authService: MsalService,public authSvc: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.addAuthHeader(request);
    return next.handle(request).pipe(catchError(error => {

        if (error.name == "TimeoutError") {
          return next.handle(request);
        }
        if(error.status === 401){
          sessionStorage.clear();
          this.route.navigate(['/login']);
        }

      return throwError(() => error);
    }));

  }

  private addAuthHeader(request) {
    const authHeader = this.authSvc.getAuthorizationHeader();
    if (authHeader) {
      return request.clone({
        setHeaders: {
          Authorization: authHeader
        }
      });
    }
    return request;
  }

  private refreshToken() {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;
      this.authSvc.login();
      this.refreshTokenInProgress = false;
      this.tokenRefreshedSource.next(true);
      return of();
    }
  }
}
