import { Component, OnInit } from '@angular/core';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { MessageService } from 'primeng/api';
import { InstanceService, DateTimeService, ProjectService } from '#services/api';
import { AuthService } from '#services/shared';
import { Instance } from '#models/index';
import { TranslateService } from '@ngx-translate/core';
import { finalize, firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-instances',
  templateUrl: './instances.component.html',
  styleUrls: ['./instances.component.scss']
})
export class InstancesComponent implements OnInit {
  Name: string = '';
  Url: string = '';
  TimeZone: string = '';
  UserNameKey: string = '';
  PasswordKey: string = '';
  instancesList: Instance[];
  selectedInstance: Instance;
  isAdmin: boolean = false;
  timezones: any[];
  selectedTimeZone: any;
  disableSave: boolean = true;
  disableSync: boolean = true;
  displaySyncModal: boolean;
  instancesToSync: Instance[];

  constructor(
    public loadingIndicatorSvc: LoadingIndicatorService,
    private messageSvc: MessageService,
    private instanceSvc: InstanceService,
    private authSvc: AuthService,
    private dateTimeSvc: DateTimeService,
    private projectSvc: ProjectService,
    private translate: TranslateService) {
    // this.isAdmin = this.authSvc.isSystemAdmin(); Temporarily hide add/edit functionality
  }

  ngOnInit() {
    this.refreshInstanceTable();
    this.refreshTimeZones();
    this.emptyInfo();
  }

  private showError(e, message: string) {

    const detail =   e.error.Message ?? e.Message ?? this.translate.get('INSTANCES.SyncErrorMessage');

    this.messageSvc.add({
      severity: 'error',
      summary: message,
      sticky: true,
      detail: detail
    });
  }

  refreshTimeZones() {
    this.dateTimeSvc.getTimezones().subscribe(
      data => {
        this.timezones = data;
    });
  }

  /**
   * Populates instance table
   */
  refreshInstanceTable() {
    this.instanceSvc.getInstances().subscribe({
     next:(data) => {
        this.instancesList = data;
        data.forEach(instance => instance.Selected = false);
     },
       error:(e) => {
        (async () => {
          this.instancesList = [];
          this.showError(e, await firstValueFrom(this.translate.get('INSTANCES.FailedList')));
        })();
      },
  });
  }

  populateFromForm() : Instance {
    const instance = this.selectedInstance;
    instance.Name = this.Name;
    instance.Url = this.Url;
    instance.UserNameKey = this.UserNameKey;
    instance.PasswordKey = this.PasswordKey;
    instance.TimeZone = this.selectedTimeZone.Id;

    return instance;
  }

  populateForm() {
    const instance = this.selectedInstance;
    this.Name = instance.Name;
    this.Url = instance.Url;
    this.UserNameKey = instance.UserNameKey;
    this.PasswordKey = instance.PasswordKey;
    this.selectedTimeZone = this.timezones.find(tz => instance.TimeZone === tz.Id);
    this.validate();

    return instance;
  }

  /**
   * Empties instance info
   */
  emptyInfo() {
    this.selectedInstance = new Instance();
    this.Name = '';
    this.Url = '';
    this.TimeZone = '';
    this.UserNameKey = '';
    this.PasswordKey = '';
    this.selectedTimeZone = '';
    this.validate();
  }

  private editInstance(instance: Instance) {
    this.selectedInstance = instance;
    this.populateForm();
  }

  private selectionChange() {
    this.instancesToSync = this.instancesList.filter(i => i.Selected);
    this.disableSync = !this.areAnyInstancesSelected();
  }

  private areAnyInstancesSelected(): boolean {
    return this.instancesToSync.length > 0;
  }

  /**
   * Saves the instance created to the database
   * Checks to ensure that instance data has been filled out
   */
  async saveInstance(): Promise<void> {
    this.loadingIndicatorSvc.show();

    const instance = this.populateFromForm();

    if (!this.isValid()) {
      this.messageSvc.add({
        severity: 'error',
        sticky: true,
        summary: await firstValueFrom(this.translate.get('INSTANCES.NoInstanceSave')),
        detail: await firstValueFrom(this.translate.get('INSTANCES.FillInstance'))
      });
      this.loadingIndicatorSvc.hide();
      return;
    }

    this.save(instance).pipe(
      finalize(() => {
        this.loadingIndicatorSvc.hide();
        this.emptyInfo();
      }))
      .subscribe({
       next:() => {
          (async () => {
          this.messageSvc.add({ severity: 'success', summary: await firstValueFrom(this.translate.get('INSTANCES.InstanceSaved')), detail: await firstValueFrom(this.translate.get('INSTANCES.InstanceSavedDetail'))});
          this.refreshInstanceTable();
        })();
        },
        error:(e) => {
          (async () => {
            this.showError(e, await firstValueFrom(this.translate.get('INSTANCES.FailedSaveInstance')));
          })();
        },
  });
  }

  async synchronize() {

    if (!this.areAnyInstancesSelected()) {
      this.messageSvc.add({
        severity: 'error',
        sticky: true,
        summary: await firstValueFrom(this.translate.get('INSTANCES.NoInstancesSelected')),
        detail: await firstValueFrom(this.translate.get('INSTANCES.SelectOneInstance'))
      });

      return;
    }

    this.displaySyncModal = true;
  }

  private isValid() {
    return this.selectedInstance?.Name &&
      this.selectedInstance.Url &&
      this.selectedInstance.UserNameKey &&
      this.selectedInstance.PasswordKey &&
      this.selectedInstance.TimeZone;
  }

  private changeForm() {
    this.populateFromForm();
    this.validate();
  }

  private validate() {
    this.disableSave = !this.isValid();
  }

  private save(instance: Instance) {
    return instance.Id ?
      this.instanceSvc.updateInstance(instance) :
      this.instanceSvc.addInstance(instance);
  }
}
