<div class="panel">
  <h2>{{'USERS.Title' | translate}}</h2>
  <div class="container">

      <div class="row">
          <div class="col-sm-12 col-sm-offset-3">
            <label>{{'USERS.ThirdPartyUser' | translate}}:</label><br>
            <p-inputSwitch [(ngModel)]="isNew3rdParty"  (click)="toggleUserType()"></p-inputSwitch>
          </div>
        </div>

    <div class="row" style="margin-bottom: 1em" *ngIf="!isNew3rdParty">
      <div class="col-sm-8 col-sm-offset-3">
        <h3 style="text-align:left"><i class="pi pi-search"></i>{{'USERS.AddNewUser' | translate}}</h3>
        <p-autoComplete (completeMethod)="searchDirectoryUsers($event)" (onSelect)="fillInfo($event)" [suggestions]="directoryUsers"
          [dropdown]="true" [size]="63" placeholder="{{'USERS.Type' | translate}}" field="Cai" [minLength]="2"
          [(ngModel)]="selectedUser">
          <ng-template let-u pTemplate="item">
            <div class="ui-helper-clearfix" style="border-bottom:1px solid #D5D5D5">
              <div><i class="pi pi-user"></i> {{u.FirstName}} {{u.LastName}} ({{u.Email}})</div>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>
    </div>

    <div class="row" style="margin-bottom: 1em" *ngIf="isNew3rdParty">
      <div class="col-sm-6 col-md-6 col-lg-5 col-sm-offset-3">
        <h3 style="text-align:left"><i class="pi pi-search"></i>{{'USERS.AddNewUser' | translate}}</h3>
        <input type="text" pInputText class="form-control" readonly>
      </div>
    </div>




    <div class="row">
      <div class="col-sm-6 col-sm-offset-3">
        <label>{{'USERS.CAI' | translate}}:</label>
        <input type="text" pInputText value={{CAI}} class="form-control" readonly>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-sm-offset-3">
        <label>{{'USERS.FirstName' | translate}}:</label>
        <input type="text" pInputText [(ngModel)]="FirstName" class="form-control" [readonly]="!isNew3rdParty">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-sm-offset-3">
        <label>{{'USERS.LastName' | translate}}:</label>
        <input type="text" pInputText [(ngModel)]="LastName" class="form-control" [readonly]="!isNew3rdParty">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-sm-offset-3">
        <label>{{'USERS.EmailAddress' | translate}}:</label>
        <input type="email" pInputText [(ngModel)]="EmailAddress" class="form-control" [readonly]="!isNew3rdParty">

        <div *ngIf="isNew3rdParty" class="alert alert-warning">{{'USERS.EnsureEmail' | translate}}</div>
      </div>

    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="btn-container labeled right">
          <p-button label="{{'USERS.SaveUser' | translate}}" icon="pi pi-check" styleClass="ui-button-primary" style="float: right;" (click)="saveUser()"></p-button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="panel">
  <div class="container">
    <h4 style="text-align:left"><i class="pi pi-users"></i>{{'USERS.UserList' | translate}}</h4>
    <p-table #tt [value]="userList" [globalFilterFields]="['Cai','FirstName','LastName','Email']">
      <ng-template pTemplate="caption">
        <div class="row">
          <div class="col-sm-6">
            <input type="text" pInputText placeholder="{{'USERS.Filter' | translate}}" (input)="tt.filterGlobal($event.target.value, 'contains')"
              class="form-control">
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th scope="col" pSortableColumn="Cai">{{'USERS.CAI' | translate}}<p-sortIcon field="Cai"></p-sortIcon>
          </th>
          <th scope="col" pSortableColumn="FirstName">{{'USERS.FirstName' | translate}}<p-sortIcon field="FirstName"></p-sortIcon>
          </th>
          <th scope="col" pSortableColumn="LastName">{{'USERS.LastName' | translate}}<p-sortIcon field="LastName"></p-sortIcon>
          </th>
          <th scope="col" pSortableColumn="Email" style="width: 300;">{{'USERS.Email' | translate}}<p-sortIcon field="Email"></p-sortIcon>
          </th>
          <th scope="col" style="width: 110;">{{'USERS.Enabled' | translate}}?</th>
          <th scope="col" pSortableColumn="sysAdmin" style="width: 110;">{{'USERS.SystemAdmin' | translate}}?<p-sortIcon field="sysAdmin"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
        <tr>
          <td>{{user.Cai}}</td>
          <td>{{user.FirstName}}</td>
          <td>{{user.LastName}}</td>
          <td>{{user.Email}}</td>
          <td>
            <div *ngIf="isAdmin">
              <p-inputSwitch [(ngModel)]="!user.IsDeleted" (click)="toggleUserStatus(user)"></p-inputSwitch>
            </div>
            <div *ngIf="!isAdmin">
              <i *ngIf="!user.IsDeleted" class="pi pi-check"></i>
            </div>
          </td>
          <td>
            <div *ngIf="isAdmin">
              <p-inputSwitch [(ngModel)]="user.sysAdmin" (click)="updateUser(user)"></p-inputSwitch>
            </div>
            <div *ngIf="!isAdmin">
              <div *ngIf="user.sysAdmin">
                <i class="pi pi-check"></i>
              </div>
            </div>
          </td>

        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
