<nav class="navbar navbar-default navbar-default-subsite centered" role="navigation" aria-label="Site menu">
 
  <div class="visible-xs">
    <div class="navbar-header centered visible-xs">
      <div class="hamburger-mobile pull-left hidden-sm hidden-md hidden-lg dropdown dropdown-mobile">
        <a href="#" class="dropdown-toggle pull-left" data-toggle="dropdown"
          data-target="#navbar-brand-centered-subsite" aria-expanded="false">
          <span class="sr-only">
            {{'HEADER.ToggleNavigation' | translate}}
          </span>
          <span class="glyphicon glyphicon-menu-hamburger black" aria-hidden="true"></span>
        </a>
      </div>
    </div>
    <div class="navbar-brand navbar-brand-centered visible-xs">
      <div class="hallmark-container">
        <a class="hallmark" href="/" title="Chevron"><img alt="Chevron" src="/assets/images/hallmark.png" width="36"
            height="40"></a>
      </div>
      <div class="sitename-container vertical-align">
        <a class="sitename light-blue font-gotham" href="/">{{'HEADER.SchedulersPortal' | translate}}</a>
      </div>
    </div>

  </div>
  <div class="collapse navbar-collapse js-navbar-collapse centered row hidden-xs font-gotham"
    id="navbar-brand-centered">
    <ul class="nav navbar-nav navbar-left" *ngIf="isAdminMenuVisible">
      <li class="dropdown dropdown-large hamburger pull-left">
        <button type="button" class="navbar-toggle dropdown-toggle" data-toggle="dropdown"
          data-target="#navbar-hamburger-menu" aria-expanded="false">
          <span class="sr-only">{{'HEADER.ToggleNavigation' | translate}}</span>
          <span class="glyphicon glyphicon-menu-hamburger" aria-hidden="true"></span>
          <span class="icon-text menu">{{'HEADER.AdminMenu' | translate}}</span>
        </button>
      </li>
    </ul>
    <div class="navbar-brand navbar-brand-centered">
      <div class="hallmark-container">
        <a class="hallmark" href="/" title="Chevron"><img alt="Chevron" src="/assets/images/hallmark.png" width="42"
            height="46"></a>
      </div>
      <div class="sitename-container vertical-align">
        <a [innerHTML]="'HEADER.LinkSchedulers' | translate" class="sitename light-blue font-gotham" [routerLink]="['']"></a>
      </div>
    </div>
    <ul class="nav navbar-nav navbar-right">
      <li class="dropdown dropdown-large">
        <app-welcome></app-welcome>

      </li>
    </ul>
  </div>
</nav>

<nav class="navbar navbar-subsite centered" role="navigation" aria-label="admin">
  <div class="container-fluid">
    <div
      class="collapse navbar-collapse js-navbar-collapse centered row font-gotham navbar-mobile dropdown-menu *dropdown-menu-mobile"
      id="navbar-brand-centered-subsite">
      
      <div role="dialog" tabindex="-1" [attr.aria-label]="'HEADER.UtilityMenu' | translate" id="navbar-hamburger-menu"
        class="utility-nav dropdown">
        <div class="dropdown-menu show-xs">
          <div class="small-nav-container">
            <div class="small-nav small-nav-corp-links">
              <hr class="hr-mobile-nav visible-xs" />
              <ul class="list-unstyled icon-list">
                <li class="menu-hover">
                  <a [routerLink]="['/admin/users']">{{'HEADER.UserMaintenance' | translate}}</a>
                </li>
                <li class="menu-hover">
                  <a [routerLink]="['/admin/justifications']">{{'HEADER.JustificationReasons' | translate}}</a>
                </li>
                <li class="menu-hover" *ngIf="isInstancesMaintenanceVisible">
                  <a [routerLink]="['/admin/instances']">{{'HEADER.InstancesMaintenance' | translate}}</a>
                </li>
                <li class="menu-hover" *ngIf="isInstancesMaintenanceVisible">
                  <a [routerLink]="['/reporting/project-info']">{{'HEADER.ProjectInfo' | translate}}</a>
                </li>
                <li class="menu-hover" *ngIf="isMasterDataSyncVisible">
                  <a (click)="this.displaySyncModal = true">{{'HEADER.MasterDataSync' | translate}}</a>
                </li>
                <li class="menu-hover" *ngIf="isAdmin">
                  <a (mouseover)="mouseOut()" [routerLink]="['/admin/mobile-management']">{{'HEADER.MobileManagement' | translate}}</a>
                </li>
                <li class="menu-hover" *ngIf="isAdmin">
                  <a (mouseover)="mouseOut()" [routerLink]="['/admin/projects-management']">{{'HEADER.ProjectsManagement' | translate}}</a>
                </li>
                <li class="menu-hover project-level" *ngIf="isAdmin && selectedProject">
                  <a (mouseover)="hoverProjectLevel()">{{'HEADER.ProjectActions' | translate}}</a>
                  <ul>
                    <li class="menu-hover" *ngIf="projectActions">
                      <a (click)="mouseOut()" [routerLink]="['/admin/language']">{{'HEADER.LanguagePreference' | translate}}</a>
                      <a (click)="mouseOut()" [routerLink]="['/admin/team-communication']">{{'HEADER.TeamCom' | translate}}</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>
      <div class="mobile-nav-backdrop visible-xs"></div>
    </div>
  </div>
</nav>
<app-master-data-sync [(display)]="this.displaySyncModal"></app-master-data-sync>
