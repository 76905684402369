<div class="panel">

  <h2>{{'PROJECTINFO.Title' | translate}}</h2>

  <div class="container">
    <div class="filters">
      <div class="row">
        <div class="col col-sm-2 date-input">
          <label>{{'PROJECTINFO.From' | translate}}: </label>
          <p-calendar [(ngModel)]="startDate" [style]="{'width':'100%'}" [keepInvalid]="true" [inputStyle]="{'width':'100%'}"
            (onSelect)="filterDate($event, 'StartDate', 'gte')" [readonlyInput]="true">
          </p-calendar>
        </div>
        <div class="col col-sm-2 date-input">
          <label>{{'PROJECTINFO.To' | translate}}: </label>
          <p-calendar [(ngModel)]="finishDate" [style]="{'width':'100%'}" [keepInvalid]="true" [inputStyle]="{'width':'100%'}"
            (onSelect)="filterDate($event, 'FinishDate', 'lte')" [readonlyInput]="true">
          </p-calendar>
        </div>
        <div class="col col-sm-8 reset">
          <label></label>
          <button type="button" class="btn btn-primary" (click)="resetTable()">{{'PROJECTINFO.Reset' | translate}}</button>
        </div>
      </div>
    </div>
    <p-table #dt [value]="projectInfo" sortMode="multiple" [paginator]="true" [rows]="15">
      <ng-template pTemplate="header">
        <tr>
          <th scope="col" pSortableColumn="InstanceName">
            {{'PROJECTINFO.InstanceName' | translate}}
            <p-sortIcon field="InstanceName" ariaLabel="{{'PROJECTINFO.ActivateSort' | translate}}"
              ariaLabelDesc="{{'PROJECTINFO.ActivateSortDesc' | translate}}" ariaLabelAsc="{{'PROJECTINFO.ActivateSortAsc' | translate}}">
            </p-sortIcon>
          </th>
          <th scope="col" pSortableColumn="ObsName">
            {{'PROJECTINFO.OBSName' | translate}}
            <p-sortIcon field="ObsName" ariaLabel="{{'PROJECTINFO.ActivateSort' | translate}}"
              ariaLabelDesc="{{'PROJECTINFO.ActivateSortDesc' | translate}}" ariaLabelAsc="{{'PROJECTINFO.ActivateSortAsc' | translate}}">
            </p-sortIcon>
          </th>
          <th scope="col" pSortableColumn="ProjectName">
            {{'PROJECTINFO.ProjectName' | translate}}
            <p-sortIcon field="ProjectName" ariaLabel="{{'PROJECTINFO.ActivateSort' | translate}}"
              ariaLabelDesc="{{'PROJECTINFO.ActivateSortDesc' | translate}}" ariaLabelAsc="{{'PROJECTINFO.ActivateSortAsc' | translate}}">
            </p-sortIcon>
          </th>
          <th scope="col" pSortableColumn="ProjectType">
            {{'PROJECTINFO.ProjectType' | translate}}
            <p-sortIcon field="ProjectType" ariaLabel="{{'PROJECTINFO.ActivateSort' | translate}}"
              ariaLabelDesc="{{'PROJECTINFO.ActivateSortDesc' | translate}}" ariaLabelAsc="{{'PROJECTINFO.ActivateSortAsc' | translate}}">
            </p-sortIcon>
          </th>
          <th scope="col" pSortableColumn="FullName">
            {{'PROJECTINFO.PrimarySchedulerName' | translate}}
            <p-sortIcon field="FullName" ariaLabel="{{'PROJECTINFO.ActivateSort' | translate}}"
              ariaLabelDesc="{{'PROJECTINFO.ActivateSortDesc' | translate}}" ariaLabelAsc="{{'PROJECTINFO.ActivateSortAsc' | translate}}">
            </p-sortIcon>
          </th>
          <th scope="col" pSortableColumn="StartDate">
            {{'PROJECTINFO.StartDate' | translate}}
            <p-sortIcon field="StartDate" ariaLabel="{{'PROJECTINFO.ActivateSort' | translate}}"
              ariaLabelDesc="{{'PROJECTINFO.ActivateSortDesc' | translate}}" ariaLabelAsc="{{'PROJECTINFO.ActivateSortAsc' | translate}}">
            </p-sortIcon>
          </th>
          <th scope="col" pSortableColumn="FinishDate">
            {{'PROJECTINFO.FinishDate' | translate}}
            <p-sortIcon field="FinishDate" ariaLabel="{{'PROJECTINFO.ActivateSort' | translate}}"
              ariaLabelDesc="{{'PROJECTINFO.ActivateSortDesc' | translate}}" ariaLabelAsc="{{'PROJECTINFO.ActivateSortAsc' | translate}}">
            </p-sortIcon>
          </th>
          <th scope="col" pSortableColumn="UpdatePeriod">
            {{'PROJECTINFO.UpdatePeriod' | translate}}
            <p-sortIcon field="UpdatePeriod" ariaLabel="{{'PROJECTINFO.ActivateSort' | translate}}"
              ariaLabelDesc="{{'PROJECTINFO.ActivateSortDesc' | translate}}" ariaLabelAsc="{{'PROJECTINFO.ActivateSortAsc' | translate}}">
            </p-sortIcon>
          </th>
          <th scope="col" pSortableColumn="Completed" hidden>
            {{'PROJECTINFO.ProjectCompleted' | translate}}
            <p-sortIcon field="Completed" ariaLabel="{{'PROJECTINFO.ActivateSort' | translate}}"
              ariaLabelDesc="{{'PROJECTINFO.ActivateSortDesc' | translate}}" ariaLabelAsc="{{'PROJECTINFO.ActivateSortAsc' | translate}}">
            </p-sortIcon>
          </th>
        </tr>
        <tr>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col" [ngSwitch]="'projectType'">
            <p-multiSelect
            [ngSwitch]="'projectType'"
            [options]="projectTypefilterOpts"
            defaultLabel="{{'PROJECTINFO.AllTypes' | translate}}"
            (onChange)="filterByProjectType($event)"
            ></p-multiSelect>
          </th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col" [ngSwitch]="'completed'" hidden>
            <p-multiSelect
            [ngSwitch]="'completed'"
            [options]="filterCompletedStatusOpts"
            defaultLabel="{{'PROJECTINFO.AllStatus' | translate}}"
            (onChange)="filterByProjectState($event)"
            ></p-multiSelect>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-info>
        <tr>
          <td>{{info.InstanceName}} - {{info.ProjectId}}</td>
          <td>{{info.ObsName}}</td>
          <td>{{info.ProjectName}}</td>
          <td>{{info.ProjectType}}</td>
          <td>{{info.FullName}}</td>
          <td>{{info.StartDate === null ? '---' :(info.StartDate | date:'M/d/yyyy')}}</td>
          <td>{{info.FinishDate === null ? '---' :(info.FinishDate | date:'M/d/yyyy')}}</td>
          <td>{{info.UpdatePeriod}}</td>
          <td hidden>{{(info.Completed? 'SHARED.Yes' : 'SHARED.No') | translate}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
