import { HttpErrorResponse } from '@angular/common/http';
import { Constants } from '../constants';
import { MtaServerError } from '#models/mtaServerError';
import { ErrorDialogModel } from '#models/errorDialogModel';

export class MtaServerErrorHandler {
  public static convertToErrorDialogModel(response: HttpErrorResponse) : ErrorDialogModel {
    const mtaError = new MtaServerError(response.error);

    const msg = new ErrorDialogModel();
    msg.id = 'error-dialog';
    switch (response.status) {
      case 403:
        msg.severity = 'warn';
        msg.summary = Constants.AuthorizationErrorMsgTitle;
        msg.detail = mtaError.message;
        break;
      case 422:
        msg.severity = 'warn';
        msg.summary = Constants.BusinessErrorMsgTitle;
        msg.detail = mtaError.message;
        break;
      /* case 502:
        msg.severity = 'error';
        msg.summary = Constants.PrimaveraErrorMsgTitle;
        msg.detail = mtaError.message;
        break; */
      case 500:
        msg.severity = 'error';
        msg.summary = Constants.ServerErrorMsgTitle;
        msg.detail = mtaError.message;
        break;
      case 400:
        msg.severity = 'error';
        msg.summary = Constants.BusinessErrorMsgTitle;
        msg.detail = mtaError.message;
        break;
      default:
        break;
    }
    return msg;
  }
}

