import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({name: 'justificationTypeDisplayTextPipe'})
export class JustificationTypeDisplayTextPipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }

  transform(value: any, ...args: any[]): string {
    switch (value) {
      case 'PE':
        return this.translate.instant('COMPONENTS.ProjectedEndDate');
      case 'SR':
        return this.translate.instant('COMPONENTS.SchedulerReject');
      case 'SO':
        return this.translate.instant('COMPONENTS.SchedulerOverride');
      default:
        return this.translate.instant('COMPONENTS.GeneralComment');
    }
  }

}
