import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
@Injectable()
export class ObsService {
  constructor(private http: HttpClient) {}

  public getObsTimezone(obsId: number): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/OBS/${obsId}/TimeZone`);
  }

  public getObsTimezones(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiEndpoint}/OBS/TimeZones`);
  }
}
