<app-header></app-header>
<div class="notification-area">
  <p-toast position="top-right" [baseZIndex]="5000" [style]="{'white-space': 'pre-line'}"></p-toast>
  <p-confirmDialog></p-confirmDialog>
  <div>
    <div id="body">
      <main tabindex="-1" id="main-content" role="main">
        <div style="min-height: 500px;">
          <router-outlet></router-outlet>
        </div>
      </main>
    </div>

    <app-footer></app-footer>

    <app-loading-indicator></app-loading-indicator>
    <app-notification></app-notification>
    <app-error-dialog id="error-dialog"></app-error-dialog>
