import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class ActivityAuditLogService {
  constructor(private httpClient : HttpClient) {
  }

  public Query(query): Observable<any>  {
    let params = new HttpParams();
    params = query.projectObjectId ? params.append('projectObjectId', query.projectObjectId) : params;
    params = query.activityObjectId ? params.append('activityObjectId', query.activityObjectId) : params;
    params = query.startDate ? params.append('startDate', query.startDate) : params;
    params = query.endDate ? params.append('endDate', query.endDate) : params;
    params = query.createdById ? params.append('createdById', query.createdById) : params;

    const response = this.httpClient.get(`${environment.apiEndpoint}/activityAuditLogs`, { params: params});
    return response;
  }

  public GetForActivity(activityId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('activityObjectId', activityId.toString());

    const response = this.httpClient.get(`${environment.apiEndpoint}/activityAuditLogs`, { params: params});
    return response;
  }
}

