import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivityAuditLogService } from '#services/api/activity.audit.log.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '#services/api';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-activity-audit-log',
  templateUrl: './activity.audit.log.component.html',
  styleUrls: ['./activity.audit.log.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivityAuditLogComponent implements OnInit {
  logs: any[];
  queryParams;
  project: Observable<any> = null;

  constructor(
    private activityAuditLogService: ActivityAuditLogService,
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private router: Router
    ) {
  }

  ngOnInit(): void {
    this.queryParams = this.getQueryParams();

    if(!this.queryParams.projectObjectId) {
      this.router.navigate(['error/not-found']);
    }

    this.project = this.projectService.getProject(this.queryParams.projectObjectId);
  }

  ExecuteSearch(data?) {
    data = data? data : {};
    data.projectObjectId = this.queryParams.projectObjectId;
    this.setQueryParams(data);
    this.activityAuditLogService.Query(data)
    .subscribe(logs => {
      this.logs = logs;
    });
  }

  getQueryParams() {
    const projectObjectId = this.activatedRoute.snapshot.queryParamMap.has('projectObjectId')
      ? parseInt(this.activatedRoute.snapshot.queryParamMap.get('projectObjectId'), 10)
      : undefined;

    const activityObjectId = this.activatedRoute.snapshot.queryParamMap.has('activityObjectId')
      ? parseInt(this.activatedRoute.snapshot.queryParamMap.get('activityObjectId'), 10)
      : undefined;

    return {
      projectObjectId: projectObjectId,
      activityObjectId: activityObjectId
    };
  }

  setQueryParams(queryData) {
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryData
      });
  }

  onDeltaReportClick() {
    this.router.navigate([''], {queryParams: {projectObjectId: this.queryParams.projectObjectId}});
  }
}
