export class Crew {
  CrewId: number;
  Name: string;
  Description: string;
  Type: string;
  IsDeleted: boolean;
  HierarchyPath: string;
  ProjectId?: number;
  typeName: string;
}
