import { Injectable } from '@angular/core';

@Injectable()
export class Utils {
    public static guid(): string {
        return Utils.s4() + Utils.s4() + '-' +
            Utils.s4() + '-' +
            Utils.s4() + '-' +
            Utils.s4() + '-' +
            Utils.s4() + Utils.s4() + Utils.s4();
    }

    private static s4(): string {
        return crypto.getRandomValues(new Uint32Array(1))[0].toString(16).substring(0,4)
    }
}
