import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Activity } from 'models';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-activity-grid',
  templateUrl: './activitygrid.component.html',
  styleUrls: ['./activitygrid.component.scss']
})
export class ActivityGridComponent implements OnInit,OnChanges {
  sortKey: string;
  sortField: string;
  sortOrder: number;
  pageSize = 1000;
  showPaginator: boolean;

  selectedActivity: Activity = new Activity();

  private _activities: Activity[];
  @Input()
  set activities(activities: Activity[]) {
    this._activities = activities || [];
    this._activities.forEach(a => this.initActivity(a));
    this.showPaginator = this._activities.length > this.pageSize;

    if (this.dt) {
      this.dt.first = 0;
    }
  }
  get activities(): Activity[] {
    return this._activities;
  }

  @Input()
  showrejectpanel = false;

  @ViewChild('dt') dt: Table;

  @Output()
  reject: EventEmitter<Activity> = new EventEmitter();

  @Output()
  loadComments = new EventEmitter();

  @Input()
  searchText: string;

  @Input()
  projectId: number;

  ngOnInit() {
    this.sortOrder = -1;
    this.sortField = 'ActivityId';
  }
dataValue:any;
   ngOnChanges(changes: SimpleChanges) {
    let value:string="";
    for (const propName in changes) {
      if (propName === 'searchText') {
        const chng = changes[propName];
         value = chng.currentValue;
        if(this.dt){
            this.dt.globalFilterFields = ['ActivityId','ActivityName','PrimaryResource','Crew1','Crew2','CoRep','Comment'];
            this.dt.filterGlobal(value,'contains');
        }
      }
    }
   }



  constructor(
    private router: Router
  ) {}

  private initActivity(activity: Activity) {
    activity.isApproved = (activity.InternalStatus || '').toUpperCase() === 'APPROVED';
    activity.rejectionComment = '';
  }

  onSortChange(event) {
    this.sortOrder = this.sortField === event ? this.sortOrder * -1 : 1;
    this.sortField = event;
  }

  cancelRejection(activity: Activity) {
    this.initActivity(activity);
  }

  raiseRejection(activity: Activity) {
    this.reject.next(activity);
  }

  showActivityComment(sender: OverlayPanel, event, activity: Activity) {
    this.selectedActivity = activity;
    this.loadComments.emit({ activity: activity, sender: sender, event: event });
  }

  onClickShowJustifications(activity) {
    activity.DisplayJustifications = activity.DisplayJustifications !== true ? true : false;
  }

  onAuditLogClick(activity) {
    this.router.navigate(['activityauditlog'], {queryParams: {projectObjectId: this.projectId, activityObjectId: activity.Id}});
  }
}
