<h3>{{'COMPONENTS.Justifications' | translate}}</h3>
<ng-container *ngIf="justifications !== undefined && justifications.length >= 1; else noJustifications">
  <p-table [value]="justifications">
    <ng-template pTemplate="header">
      <tr>
        <th scope="col">{{'COMPONENTS.CreatedAt' | translate}}</th>
        <th scope="col">{{'COMPONENTS.Comment' | translate}}</th>
        <th scope="col">{{'COMPONENTS.Type' | translate}}</th>
        <th scope="col">{{'COMPONENTS.NewDate' | translate}}</th>
        <th scope="col">{{'COMPONENTS.CreatedBy' | translate}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-justification>
      <tr>
        <td>{{justification.CreateDate | momentPipe: 'L HH:mm'}}</td>
        <td><div style="word-wrap: break-word">{{justification.Text}}</div></td>
        <td><div style="word-wrap: break-word">{{justification.TypeText}}</div></td>
        <td>{{justification.NewDate | momentPipe: 'L HH:mm'}}</td>
        <td>{{justification.UserUpn}}</td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>
<ng-template #noJustifications>
  {{'COMPONENTS.NoJustifications' | translate}}
</ng-template>


<h3>{{'COMPONENTS.Comments' | translate}}</h3>
<ng-container *ngIf="comments !== undefined && comments.length >= 1; else noComments">
  <p-table [value]="comments">
    <ng-template pTemplate="header">
      <tr>
        <th scope="col">{{'COMPONENTS.CreatedAt' | translate}}</th>
        <th scope="col">{{'COMPONENTS.Text' | translate}}</th>
        <th scope="col">{{'COMPONENTS.Type' | translate}}</th>
        <th scope="col">{{'COMPONENTS.CreatedBy' | translate}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-comment>
      <tr>
        <td>{{comment.CreateDate | momentPipe: 'L HH:mm'}}</td>
        <td><div style="word-wrap: break-word">{{comment.Text}}</div></td>
        <td>{{comment.Type | justificationTypeDisplayTextPipe}}</td>
        <td>{{comment.CreatedBy}}</td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>
<ng-template #noComments>
  {{'COMPONENTS.NoComments' | translate}}
</ng-template>
