import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AsyncCommunicationService {
  private project = new Subject<string>();

  sendProject(projectId: string) {
    this.project.next(projectId);
  }

  clearProject() {
    this.project.next('');
  }

  getProject(): Observable<string> {
    return this.project.asObservable();
  }
}
