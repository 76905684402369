<p-dialog
  header="Alert"
  [visible]="_isDisplayConfirmDlg"
  [modal]="true"
  [closable]="false">
  <p>{{'COMPONENTS.HitF5' | translate}}</p>
  <br>
  <p-checkbox [(ngModel)]="_isConfirmCommitChk" binary="true" label="{{'COMPONENTS.Refreshed' | translate}}"></p-checkbox>
  <p-footer>
    <button (click)="this.initData()" type="button" pButton icon="pi pi pi-check"
      label="{{'COMPONENTS.Continue' | translate}}" [disabled]="!_isConfirmCommitChk">
    </button>
    <button (click)="hideModals()" type="button" pButton icon="pi pi-times" label="{{'COMPONENTS.Cancel' | translate}}"></button>
  </p-footer>
</p-dialog>

<p-dialog
  styleClass="upload-dialog"
  header="{{'COMPONENTS.ConflictsPreview' | translate}}"
  [visible]="display && _isConfirmCommitChk"
  [modal]="true"
  [blockScroll]="true"
  [positionTop]="0"
  [closable]="false">
  <p [innerHTML]="'COMPONENTS.ConflictsDescription' | translate"></p>
  <hr>
  <app-activity-upload-grid [data]="this.previewData"></app-activity-upload-grid>
  <p-footer>
    <button (click)="commit()" type="button" pButton icon="pi pi pi-cloud-upload" label="{{'COMPONENTS.ConfirmUpload' | translate}}"></button>
    <button (click)="hideModals()" type="button" pButton icon="pi pi-times" label="{{'COMPONENTS.Cancel' | translate}}"></button>
  </p-footer>
</p-dialog>
