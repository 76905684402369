import { Instance } from 'models';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';
import { share } from 'rxjs/operators';

@Injectable()
export class InstanceService {
    private getInstancesObservable : Observable<Instance[]>;

    constructor(
        private http: HttpClient
    ) { }

    public getInstancesMulticast() : Observable<Instance[]> {
        if (!this.getInstancesObservable) {
            this.getInstancesObservable = this.getInstances().pipe(share<Instance[]>());
        }

        return this.getInstancesObservable;
    }

    public getInstances(): Observable<Instance[]> {
        return this.http.get<Instance[]>(`${environment.apiEndpoint}/instance`);
    }

    public addInstance(instance: Instance): Observable<Instance> {
        return this.http.post<Instance>(`${environment.apiEndpoint}/instance`, instance);
    }

    public updateInstance(instance: Instance): Observable<Instance> {
        return this.http.put<Instance>(`${environment.apiEndpoint}/instance`, instance);
    }
}
