import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from '#environments/environment';
import { SCHEDULER, SYSTEM_ADMIN, User, WEB_PORTAL } from 'models';
@Injectable()
export class UserService {
    private readonly httpOptions: any = { headers: { 'Content-Type': 'application/json' } };

    private currentUser: User;
    constructor(private http: HttpClient) { }

    loadCurrentUserInfo(){
        return firstValueFrom(this.getCurrentUser())
        .then(
            (userInfo: User)=>{
                this.currentUser=userInfo;
                return userInfo;
            });
    }

    isAuthorized(): boolean {
        return this.isInRole([WEB_PORTAL, SYSTEM_ADMIN]);
    }

    isSystemAdmin(): boolean {
        let isAdmin = sessionStorage.getItem('SystemAdmin');
        if(isAdmin === 'true'){
          return true;
        }else{
          return false;
        }
    }

    isScheduler(): boolean {
      let isScheduler = sessionStorage.getItem("Scheduler");
      if(isScheduler === 'true'){
        return true;
      }else{
        return false;
      }
    }

    public isInRole(roleName: string | string[]): boolean {
        if (!this.currentUser?.ApplicationRoles) {
            return false;
        }

        let result = null;
        if (Array.isArray(roleName)) {
          result = this.currentUser.ApplicationRoles.find(role => roleName.find(n => n === role)) ?? null
        } else {
            result = this.currentUser.ApplicationRoles.find(role => role === roleName);
        }

        return result;
    }

    public isInProjectRole(projectId: number, roleName: string | string[]): boolean {
        if (!this.currentUser?.ProjectRoles) {
            return false;
        }

        const projectRoles = this.currentUser.ProjectRoles.find(p => p.ProjectId === projectId);
        if (projectRoles !== null || projectRoles?.Roles !== undefined) {
            let result = null;
            if (Array.isArray(roleName)) {
                result = projectRoles.Roles.find(role => roleName.find(n => n === role)) ?? null;
            } else {
                result = projectRoles.Roles.find(role => role === roleName);
            }
            return result;
        }
        return false;
    }


    public getCurrentUser(): Observable<any> {
        return this.http.get<User>(`${environment.apiEndpoint}/user/current/`);
    }

    public getAll(): Observable<User[]> {
        return this.http.get<User[]>(`${environment.apiEndpoint}/user/users/`);
    }

    public search(term: string): Observable<any> {
        const param = (term || '').trim();

        if (param.length === 0) {
            return this.getAll();
        }

        return this.http.post<User[]>(`${environment.apiEndpoint}/user/local-search`, `"${param}"`, this.httpOptions);
    }

    /**
     * This sends a post method with new User to the database
     * It has to put the user into an empty array since the API calls for a Collection of Users
     * @param user The user to be added
     */
    public addUser(user): Observable<any> {
        return this.http.post<any>(`${environment.apiEndpoint}/user/addusers/`, [user]);
    }

    /**
     * Searches for users in an Azure Directory by Cai, email, first or last name
     * @param term The term to be searched in the Azure Directory
     */
    public getUserFromDirectory(term: string): Observable<any> {
        const param = (term || '').trim();

        if (param.length === 0) {
            return this.getAll();
        }

        return this.http.post<User[]>(`${environment.apiEndpoint}/user/azure-search`, `"${param}"`, this.httpOptions);
    }

    /**
     * Sends an update request to api
     * It has to put the user into an empty array since the API calls for a Collection of Users
     * @param user user to be updated
     */
    public updateUser(user: User): Observable<User> {
        return this.http.put<User>(`${environment.apiEndpoint}/user/updateusers`, [user]);
    }

    public deleteUser(id: number): Observable<User> {
        return this.http.delete<User>(`${environment.apiEndpoint}/user/${id}`);
    }


}
