<div class="panel">
  <h2>{{'INSTANCES.Title' | translate}}</h2>
  <div class="container" *ngIf="isAdmin">

    <div class="row" style="margin-bottom: 1em" *ngIf="isNew3rdParty">
      <div class="col-sm-6 col-md-6 col-lg-5 col-sm-offset-3">
        <h3 style="text-align:left"><i class="pi pi-search"></i>{{'INSTANCES.AddNewInstance' | translate}}</h3>
        <input type="text" pInputText class="form-control">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-sm-offset-3">
        <label>{{'INSTANCES.Name' | translate}}:</label>
        <input type="text" pInputText [(ngModel)]="Name" (change)="changeForm()" class="form-control">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-sm-offset-3">
        <label>{{'INSTANCES.URL' | translate}}:</label>
        <input type="text" pInputText [(ngModel)]="Url" (change)="changeForm()" class="form-control">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-sm-offset-3">
        <label>{{'INSTANCES.UsernameSecret' | translate}}:</label>
        <input type="text" pInputText [(ngModel)]="UserNameKey" (change)="changeForm()" class="form-control">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-sm-offset-3">
        <label>{{'INSTANCES.PasswordSecret' | translate}}:</label>
        <input type="email" pInputText [(ngModel)]="PasswordKey" (change)="changeForm()" class="form-control">
      </div>

    </div>

    <div class="row">
      <div class="col-sm-6 col-sm-offset-3">
        <label>{{'INSTANCES.TimeZone' | translate}}:</label>
        <br>
        <p-dropdown [options]="timezones"
          [(ngModel)]="selectedTimeZone"
          optionLabel="DisplayName"
          [autoWidth]="true"
          placeholder="{{'INSTANCES.SelectTimeZone' | translate}}"
          [disabled]="!this.timezones"
          (click)="changeForm()"
        >
        </p-dropdown>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="btn-container labeled right">
          <p-button label="{{'INSTANCES.SaveInstance' | translate}}" icon="pi pi-check" styleClass="ui-button-primary" style="float: right;" (click)="saveInstance()" [disabled]="disableSave"></p-button>
          <p-button label="{{'INSTANCES.Reset' | translate}}" icon="pi pi-refresh" styleClass="ui-button-primary" style="float: right;" (click)="emptyInfo()"></p-button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="panel">
  <div class="container">
    <h4 style="text-align:left"><i class="pi pi-users"></i>{{'INSTANCES.InstanceList' | translate}}</h4>
    <p-table #tt [value]="instancesList" [globalFilterFields]="['Name','Url']">
      <ng-template pTemplate="caption">
        <div class="row">
          <div class="col-sm-6">
            <input type="text" pInputText placeholder="{{'INSTANCES.Filter' | translate}}" (input)="tt.filterGlobal($event.target.value, 'contains')"
              class="form-control">
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th scope="col" pSortableColumn="Name">{{'INSTANCES.Name' | translate}} <p-sortIcon field="Name"></p-sortIcon>
          </th>
          <th scope="col" pSortableColumn="Url">{{'INSTANCES.URL' | translate}}<p-sortIcon field="Url"></p-sortIcon>
          </th>
          <th scope="col" pSortableColumn="TimeZone">{{'INSTANCES.TimeZone' | translate}}<p-sortIcon field="TimeZone"></p-sortIcon>
          </th>
          <th scope="col" style="width: 110;" *ngIf="isAdmin">{{'INSTANCES.Edit' | translate}}</th>
          <th scope="col" style="width: 110;">{{'INSTANCES.Synchronize' | translate}}?</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-instance>
        <tr [class]="this.selectedInstance.Id == instance.Id ? 'selected' : ''">
          <td>{{instance.Name}}</td>
          <td>{{instance.Url}}</td>
          <td>{{instance.TimeZone}}</td>
          <td *ngIf="isAdmin">
            <div>
              <p-button label="{{'INSTANCES.Edit' | translate}}" styleClass="ui-button-primary" (click)="editInstance(instance)"></p-button>
            </div>
          </td>
          <td>
            <div>
              <p-inputSwitch [(ngModel)]="instance.Selected" (click)="selectionChange()"></p-inputSwitch>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>

  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="btn-container labeled right">
          <p-button label="{{'INSTANCES.Synchronize' | translate}}" icon="pi pi-check" styleClass="ui-button-primary" style="float: right;" (click)="synchronize()" [disabled]="disableSync"></p-button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-master-data-sync [(display)]="this.displaySyncModal" [instances]="instancesToSync"></app-master-data-sync>
