import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { ProjectInfo } from 'models';

@Injectable()
export class ProjectInfoService {
  constructor(
    private http: HttpClient
  ) {

  }

  public saveProjectInfo(projectInfo: ProjectInfo): Observable<any> {
    return this.http.post<ProjectInfo>(`${environment.apiEndpoint}/projectinfo`, projectInfo);
  }

  public updateProjectInfo(projectInfo: ProjectInfo, projectId: number): Observable<any> {
    return this.http.put<any>(`${environment.apiEndpoint}/project/${projectId}/info`, projectInfo);
  }

  public getProjectInfo(projectId: number) {
    return this.http.get<any>(`${environment.apiEndpoint}/project/${projectId}/info`);
  }

  public getAllProjectInfo() {
    return this.http.get<any>(`${environment.apiEndpoint}/projectinfo`);
  }

  public setProjectAsCompleted(projectId: number) {
    return this.http.put<any>(`${environment.apiEndpoint}/projectinfo/completeProject/${projectId}`, {});
  }

  
  public enableProject(id: number): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/projectinfo/enableProject`, id);
  }

  public disableProject(id: number): Observable<any> {
      return this.http.post<any>(`${environment.apiEndpoint}/projectinfo/disableProject`, id );
  }
}
