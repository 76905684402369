import { Component } from '@angular/core';
declare const require: any;

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent  {
  version: string = require('../../../package.json').version;

  
}
