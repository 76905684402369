export class Constants {
  public static get PrimaveraErrorMsgTitle(): string {
    return 'Primavera error';
  }

  public static get BusinessErrorMsgTitle(): string {
    return 'Error detected';
  }

  public static get AuthorizationErrorMsgTitle(): string {
    return 'Authorization error';
  }

  public static get ServerErrorMsgTitle(): string {
    return 'Server error';
  }

  public static get Warning(): string {
    return 'Warning';
  }
}
