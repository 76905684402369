<div class="panel">
  <h2>{{ 'LANGUAGE.Title' | translate }}</h2>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-sm-offset-3">
        <h3 style="text-align:left">{{'LANGUAGE.Select' | translate}}</h3>
      </div>
      <div class="col-sm-12 col-sm-offset-3">
          <select [(ngModel)]="selectedLanguage" (change)="onLanguageSelected()" [disabled]="!languageSelectionEnabled">
            <option *ngFor="let language of languageList" [ngValue]="language.value">{{language.name}}</option>
          </select>
      </div>
    </div>
  </div>
</div>
