<div class="panel">
  <h2>{{'JUSTIFICATIONREASONS.Title' | translate}}</h2>

  <div class="panel">
    <div class="container">
      <h4 style="text-align:left">{{'JUSTIFICATIONREASONS.Table' | translate}}</h4>

      <p-button type="button" (click)="showDialog()"  icon="pi pi-plus" label="{{'JUSTIFICATIONREASONS.AddJustification' | translate}}" styleClass="ui-button-success"></p-button>

      <p-table #tt [value]="justificationTypes" [tableStyle]="{'table-layout':'auto'}">

        <ng-template pTemplate="header">
          <tr>
            <th scope="col" pSortableColumn="text">{{'JUSTIFICATIONREASONS.JustificationReason' | translate}}<p-sortIcon field="text"></p-sortIcon>
            </th>
            <th scope="col" pSortableColumn="enabled">{{'JUSTIFICATIONREASONS.Disabled' | translate}}<p-sortIcon field="enabled"></p-sortIcon>
            </th>
            <th scope="col">{{'JUSTIFICATIONREASONS.Options' | translate}}</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-justificationType>
          <tr>
            <td>
              <p *ngIf="!justificationType.editable">{{justificationType.Text}}</p>
              <p *ngIf="justificationType.editable"><input type="text" pInputText placeholder="{{'JUSTIFICATIONREASONS.JustificationReason' | translate}}"
                  [(ngModel)]="justificationType.Text"></p>
            </td>
            <td>
              <p-inputSwitch [(ngModel)]="!justificationType.Enabled" (click)="toggleJustification(justificationType)"></p-inputSwitch>
            </td>
            <td>
              <div class="btn-container right">
                <p-button *ngIf="!justificationType.editable" icon="pi pi-pencil" styleClass="ui-button-primary ui-button-xs"
                  (click)="justificationType.editable = true"></p-button>
                <p-button *ngIf="justificationType.editable" icon="pi pi-save" styleClass="ui-button-primary ui-button-xs"
                  (click)="saveJustification(justificationType)"></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>


      <p-dialog header="{{'JUSTIFICATIONREASONS.AddJustificationReason' | translate}}" [(visible)]="displayAddDialog" [responsive]="true" showEffect="fade"
        [modal]="true">
        <div class="ui-grid ui-grid-responsive ui-fluid" *ngIf="justificationType">
          <div class="ui-grid-row">
            <div class="ui-grid-col-4"><label for="text">{{'JUSTIFICATIONREASONS.Reason' | translate}}</label></div>
            <div class="ui-grid-col-8"><input pInputText id="vin" [(ngModel)]="justificationType.text" /></div>
          </div>
        </div>
        <p-footer>
          <div class="ui-dialog-buttonpane ui-helper-clearfix">
            <button type="button" pButton icon="fa-close" (click)="closeDialog()" label="{{'JUSTIFICATIONREASONS.Cancel' | translate}}"></button>
            <button type="button" pButton icon="fa-check" (click)="createJustification()" label="{{'JUSTIFICATIONREASONS.Save' | translate}}"></button>
          </div>
        </p-footer>
      </p-dialog>
    </div>
  </div>
</div>
