import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class DateTimeService {
    constructor(
        private http: HttpClient
    ) { }

    public getTimezones(): Observable<any[]> {
        return this.http.get<any[]>(`${environment.apiEndpoint}/datetime/timezones`);
    }
}
