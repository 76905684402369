<p-dataView #dv [value]="assignments" [paginator]="showPaginator" [rows]="pageSize" paginatorPosition="bottom" [sortOrder]="sortOrder" [sortField]="sortField" filterBy="User.FirstName,User.LastName,User.Cai,User.Email,Crew.Name,Crew.Description">
<p-header class="ug clearfix row-header">
  <div class="row">
    <div class="col-sm-4">
      <input type="search" pInputText placeholder="{{'COMPONENTS.SearchAssignment' | translate}}" (keyup)="dv.filter($event.target.value)"
        [(ngModel)]="filterValue" class="form-control">
    </div>
    <div class="col-sm-8">
      <div class="btn-container right" *ngIf="!isProjectCompleted">
        <p-autoComplete [(ngModel)]="newUser" [suggestions]="userList" (completeMethod)="searchUsers($event)"
          (onSelect)="addUsers($event, dv)" [size]="50" placeholder="{{'COMPONENTS.SearchUsers' | translate}}" field="Cai" [minLength]="2"
          [dropdown]="true">
          <ng-template let-u pTemplate="item">
            <div class="ui-helper-clearfix" style="border-bottom:1px solid #D5D5D5">
              <div><i class="pi pi-user"></i> {{u.FirstName}} {{u.LastName}} ({{u.Email}})</div>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>
    </div>
  </div>
</p-header>
  <p-header class="row-header">
    <div class="grid">
      <div [ngClass]="{'selected-col': sortField === 'User.FirstName'}" class="col-3 d-center d-sort" (click)="sort('User.FirstName')">
        {{'COMPONENTS.FullName' | translate}}
        <i *ngIf="sortField !== 'User.FirstName'" class="pi pi-sort"></i>
        <i *ngIf="sortField === 'User.FirstName' && sortOrder < 0" class="pi pi-sort-up"></i>
        <i *ngIf="sortField === 'User.FirstName' && sortOrder > 0" class="pi pi-sort-down"></i>
      </div>
      <div [ngClass]="{'selected-col': sortField === 'User.Cai'}" class="col-1 d-center d-sort" (click)="sort('User.Cai')">
        {{'COMPONENTS.CAI' | translate}}
        <i *ngIf="sortField !== 'User.Cai'" class="pi pi-sort"></i>
        <i *ngIf="sortField === 'User.Cai' && sortOrder < 0" class="pi pi-sort-up"></i>
        <i *ngIf="sortField === 'User.Cai' && sortOrder > 0" class="pi pi-sort-down"></i>
      </div>
      <div [ngClass]="{'selected-col': sortField === 'User.Email'}" class="col-3 d-center d-sort" (click)="sort('User.Email')">
        {{'COMPONENTS.Email' | translate}}
        <i *ngIf="sortField !== 'User.Email'" class="pi pi-sort"></i>
        <i *ngIf="sortField === 'User.Email' && sortOrder < 0" class="pi pi-sort-up"></i>
        <i *ngIf="sortField === 'User.Email' && sortOrder > 0" class="pi pi-sort-down"></i>
      </div>
      <div [ngClass]="{'selected-col': sortField === 'Crew.Name'}" class="col-2 d-center d-sort" (click)="sort('Crew.Name')">
        {{'COMPONENTS.Crew' | translate}}
        <i *ngIf="sortField !== 'Crew.Name'" class="pi pi-sort"></i>
        <i *ngIf="sortField === 'Crew.Name' && sortOrder < 0" class="pi pi-sort-up"></i>
        <i *ngIf="sortField === 'Crew.Name' && sortOrder > 0" class="pi pi-sort-down"></i>
      </div>
      <div class="col-1 d-center">
        {{'COMPONENTS.ReadOnly' | translate}}
      </div>
    </div>
  </p-header>

  <ng-template let-a let-i="index" pTemplate="listItem">
      <div class="col-3 col">{{a.User.FirstName}} {{a.User.LastName}}</div>
      <div class="col-1 col">{{a.User.Cai}}</div>
      <div class="col-3 col">{{a.User.Email}}</div>
      <div class="col-2 col">
        <p-dropdown *ngIf="a.backup" [(ngModel)]="a.Crew" [options]="crews" [group]="true" [style]="{'width':'100%'}">
          <ng-template let-it pTemplate="item">
            <span class="list-sub-item">{{it.value.HierarchyPath}}</span><span *ngIf="it.value && it.value.Description">:
              {{it.value.Description}}</span>
          </ng-template>
          <ng-template let-it pTemplate="selectedItem">
            <span>{{it.value.HierarchyPath}}</span>
            <span *ngIf="it.value && it.value.Description">: {{it.value.Description}}</span>
          </ng-template>
        </p-dropdown>
        <div *ngIf="!a.backup">
          <span>{{a.Crew.HierarchyPath}}</span>
          <span *ngIf="a.Crew.Description">: {{a.Crew.Description}}</span>
        </div>
      </div>
      <div class="col-1 col"> <p-inputSwitch [(ngModel)]="a.IsReadOnly" (click)="updateUserViewOnlyAccess(a)"></p-inputSwitch></div>
      <div class="col-2 col">
        <div class="btn-container right" *ngIf="!a.backup">
          <p-button icon="pi pi-pencil" styleClass="p-button-rounded p-button-info p-button-text" (click)="enterEditMode(a)" [disabled]="isProjectCompleted"></p-button>
          <p-button icon="pi pi-trash" styleClass="p-button-rounded p-button-danger p-button-text" (click)="deleteAssignment(a,dv)" [disabled]="isProjectCompleted"></p-button>
        </div>
        <div class="btn-container right" *ngIf="a.backup">
          <p-button icon="pi pi-save" styleClass="p-button-rounded p-button-success p-button-text" (click)="saveAssignment(a)" [disabled]="isProjectCompleted"></p-button>
          <p-button icon="pi pi-times" styleClass="p-button-rounded p-button-danger p-button-text" (click)="exitEditMode(a)" [disabled]="isProjectCompleted"></p-button>
        </div>
      </div>
  </ng-template>
</p-dataView>

<p-dialog
  header="Add Multiple Crews"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '70vw', height: '400px' }"
  [draggable]="false"
  [resizable]="false"
  >
  <p-toast></p-toast>
    <table class="table table-bordered">
      <caption>Add Multiple Crews</caption>
      <thead>
            <tr>
                <th scope="col">FullName</th>
                <th scope="col">CAI</th>
                <th scope="col">Email</th>
                <th scope="col">Crew</th>
                <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
                <td class="col-md-2">{{fname}} {{lname}}</td>
                <td class="col-md-1">{{ucai}}</td>
                <td class="col-md-2">{{uemail}}</td>
                <td class="col-sm-6">
                  <p-multiSelect
                  [options]="crews"
                  [(ngModel)]="selectedCrew"
                  defaultLabel="Select Crews"
                  optionLabel="name"
                  [group]="true"
                  [style]="{'width':'100%'}"

                  >
                  <ng-template let-it pTemplate="item">
                    <div>{{it.value.HierarchyPath}}</div><div *ngIf="it.value && it.value.Description">:
                      {{it.value.Description}}</div>
                  </ng-template>
                  <ng-template let-it pTemplate="selectedItem">
                    <div>{{it.value.HierarchyPath}}</div>
                    <div *ngIf="it.value && it.value.Description">: {{it.value.Description}}</div>
                  </ng-template>
                  </p-multiSelect>
                </td>
                <td class="col-sm-1">
                  <p-button icon="pi pi-bookmark" label="Save" styleClass="p-button-raised" (click)="saveCrew(dv)"></p-button>
                </td>
            </tr>
          </tbody>
          </table>
  </p-dialog>

