import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';
import { CommitActivityUpload } from '#models/CommitActivityUpload';

@Injectable()
export class SynchronizationService {
  constructor(private httpClient: HttpClient) {
  }

  public uploadPreview(projectId: number) : Observable<any> {
    const response = this.httpClient.get(`${environment.apiEndpoint}/primavera/upload/${projectId}/preview`);
    return response;
  }

  public commitUpload(projectId:number, uploads: Array<CommitActivityUpload>): Observable<any> {
    const response = this.httpClient.post(`${environment.apiEndpoint}/primavera/upload/${projectId}/commit`, uploads);
    return response;
  }
}
