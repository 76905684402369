import { NgModule, ErrorHandler, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import {
  AppComponent, GlobalErrorHandler, VersionComponent, HeaderComponent, FooterComponent, WelcomeComponent,
  LoadingIndicatorComponent, FeedbackComponent
} from './';
import { AppRoutingModule } from './app.routing.module';
import { LoadingIndicatorService } from '#services/shared';
import { SharedModule } from '#services/shared/shared.module';
import { ComponentsModule } from '#components/components.module';
import { environment } from '#environments/environment';
import {  of } from 'rxjs';
import { AuthInterceptor } from '#services/interceptors';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessageService, ConfirmationService } from 'primeng/api';
import { BlockUIModule } from 'primeng/blockui';
import { DialogModule } from 'primeng/dialog';
import { ServerErrorInterceptor } from '#services/interceptors/ServerErrorInterceptor';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { WebpackTranslateLoader } from './webpack-translate-loader';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserCacheLocation,
	      InteractionType,
	      IPublicClientApplication,
	      LogLevel,
	      PublicClientApplication, } from '@azure/msal-browser';
import { UserService } from '#services/api';


export const protectedResourceMap: [string, string[]][] = [
  ['https://graph.microsoft.com/v1.0/me', ['user.read']]
];

export function loggerCallback(logLevel: LogLevel, message: string) {
	console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication{
  return new PublicClientApplication ({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.msalConfig.auth.authority,
      redirectUri:'/',
      postLogoutRedirectUri: '/',
    },
    cache: {
      cacheLocation: "sessionStorage",
    },
		system: {
			allowNativeBroker: false, // Disables WAM Broker
			loggerOptions: {
				loggerCallback,
				logLevel: LogLevel.Info,
				piiLoggingEnabled: false,
			},
		},
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
    'user.read',
     environment.msalConfig.auth.user_impersonation]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read', environment.msalConfig.auth.user_impersonation],
    },
    loginFailedRoute: '/login-failed'
  };
}



export function loadUserInfo(userSvc:UserService){
    return ()=>userSvc.loadCurrentUserInfo()
    .then(
      user => {
        return user;
      },
      error => {
        return of(error);
      });
}

@NgModule({
  declarations: [
    AppComponent,
    VersionComponent,
    HeaderComponent,
    FooterComponent,
    WelcomeComponent,
    LoadingIndicatorComponent,
    FeedbackComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    ReactiveFormsModule,
    ComponentsModule,
    ToastModule,
    ConfirmDialogModule,
    BlockUIModule,
    DialogModule,
    FontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader
      }
    }),
    MsalModule.forRoot(MSALInstanceFactory(), MSALGuardConfigFactory(), MSALInterceptorConfigFactory()),
      ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    LoadingIndicatorService,
    MessageService,
    ConfirmationService
  ],
  bootstrap: [AppComponent,MsalRedirectComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA],
})

export class AppModule { }
