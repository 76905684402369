import { ProjectRole } from './role';

export class User {
  Id: number;
  FirstName: string;
  LastName: string;
  Cai: string;
  Email: string;
  Permissions: number;
  ObsId: number;

  ApplicationRoles: string[];
  ProjectRoles: ProjectRole[];
  sysAdmin: boolean;

  private _fullName: string;
  get fullName(): string {
    return `${this.FirstName} ${this.LastName}`;
  }
  set fullName(value: string) {
    this._fullName = value;
  }

}
