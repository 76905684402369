
export class ActivityReport {
  Id: number;
  ActivityId: string;
  Name: string;
  InternalStatus: string;
  PrimaryResource: string;
  PrimaveraPercentComplete: number;
  InternalPercentComplete: number;
  ProjectedEndDate: Date;
  Crew1: string;
  Crew2: string;
  CoRep: string;
  HasJustifications: boolean;
  WbsId: number;
  Crew1Id: number;
  Crew2Id: number;
  CoRepId: number;
  ActualStartDate: string;
  ActualFinishDate: string;
  WbsName: string;
  WbsCategory: string;
  WbsCategoryId: number;
}

export class ReportFilter {
  ProjectId: number;
  WbsIds: number[];
  WbsCategoryIds: number[];
  CrewIds: number[];
  Statuses: string[];
}
