import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  constructor(private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.translate.addLangs(['en', 'ru', 'kaz']);
    this.translate.setDefaultLang('en');
    const lang = localStorage.getItem('language');
    if (lang) {
      this.translate.use(lang);
    } else {
      this.translate.use('en');
    }
  }
}
