import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { InstanceService, UserService } from '#services/api';
import { AsyncCommunicationService } from '#services/shared';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public isAdminMenuVisible: boolean;
  public displaySyncModal: boolean = false;
  public isInstancesMaintenanceVisible: boolean;
  public isMasterDataSyncVisible: boolean;
  public isAdmin: boolean;
  public projectActions: boolean;
  public selectedProject: string;
  private projectSubscription: Subscription;

  constructor(
    private userSvc: UserService,
    private instanceSvc: InstanceService,
    private asyncService: AsyncCommunicationService) {
      this.projectSubscription = this.asyncService.getProject().subscribe(project => {
        this.selectedProject = project;
      });
  }

  ngOnDestroy() {
    this.projectSubscription.unsubscribe();
  }

  ngOnInit() {
    let fullname = sessionStorage.getItem('FullName');
    if(!fullname){
  let interval =  setInterval(() => {
    const isAdmin = this.userSvc.isSystemAdmin();
    const isScheduler = this.userSvc.isScheduler();
    this.instanceSvc.getInstancesMulticast().subscribe(
      v => {
        this.isMasterDataSyncVisible = !isAdmin && isScheduler && v.length === 1;
        this.isInstancesMaintenanceVisible = isScheduler && v.length > 1 || isAdmin;
      });
    this.isAdminMenuVisible = isAdmin || isScheduler;
    this.isAdmin = isAdmin;
    clearInterval(interval);
  },4000);
}else{
  const isAdmin = this.userSvc.isSystemAdmin();
  const isScheduler = this.userSvc.isScheduler();
  this.instanceSvc.getInstancesMulticast().subscribe(
    v => {
      this.isMasterDataSyncVisible = !isAdmin && isScheduler && v.length === 1;
      this.isInstancesMaintenanceVisible = isScheduler && v.length > 1 || isAdmin;
    });
  this.isAdminMenuVisible = isAdmin || isScheduler;
  this.isAdmin = isAdmin;
}
  }

  hoverProjectLevel() {
    this.projectActions = true;
  }

  mouseOut() {
    this.projectActions = false;
  }
}
