import { Injectable, EventEmitter } from '@angular/core';
import { NotificationConfig } from '#models/notification-config';

@Injectable()
export class NotificationService {
  private notificationEvt: EventEmitter<NotificationConfig> = new EventEmitter();


  public success(message: string, id: string, closeTimeout: number): void {
    this.show(message, 'success', id, closeTimeout);
  }

  public info(message: string, id: string, closeTimeout: number): void {
    this.show(message, 'info', id, closeTimeout);
  }

  public warning(message: string, id: string, closeTimeout: number): void {
    this.show(message, 'warning', id, closeTimeout);
  }

  public error(message: string, id: string, closeTimeout: number): void {
    this.show(message, 'danger', id, closeTimeout);
  }

  public getNotificationSubscription(): EventEmitter<NotificationConfig> {
    return this.notificationEvt;
  }

  private show(message: string, type: string, id: string, autoCloseTimeout: number): void {
    const notification: NotificationConfig = {
      id: id,
      message: message,
      type: type,
      autoCloseTimeout: autoCloseTimeout
    };

    this.notificationEvt.emit(notification);
  }
}
