import { UploadPreviewDisplayData } from './uploadPreviewDisplayData';

export class CommitActivityUpload {
  ActivityObjectId;
  ForceMta;
  OverrideComment;

  constructor(activity: UploadPreviewDisplayData) {
    this.ActivityObjectId = activity.activityObjectId;
    this.ForceMta = activity.upload;
    this.OverrideComment = activity.overrideComment;
  }
}
