import { Component, OnInit, Input } from '@angular/core';
import { Shift, Project } from '#models/index';
import { ShiftService } from '#services/api';
import { LoadingIndicatorService } from '#services/shared';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';
import { extendMoment } from 'moment-range';
import { finalize } from 'rxjs';
const momentHasRange = extendMoment(moment);

@Component({
  selector: 'app-shift-grid',
  templateUrl: './shift-grid.component.html',
  styleUrls: ['./shift-grid.component.scss']
})
export class ShiftGridComponent implements OnInit {
  @Input() project: Project;
  @Input() shifts: Shift[];
  @Input() offsetHour: number = 0;
  @Input() offsetMinute: number = 0;
  @Input() projectLocation: string;
  @Input() projectTimezone: string;
  @Input() isProjectCompleted: boolean;
  offsetHourOptions: number[];
  offsetMinuteOptions: number[];
  timeOffset: Date;
  enableSaveButton: boolean = false;
  constructor(private shiftService: ShiftService, private loadingIndicatorSvc: LoadingIndicatorService, private messageSvc: MessageService) {}

  ngOnInit() {
    this.offsetHourOptions = new Array(4).fill(0).map((x, i) => i);
    this.offsetMinuteOptions = new Array(6).fill(0).map((x, i) => i * 10);
  }

  addShift() {
    const newShift = Shift.create(this.project.Id, `${this.shifts.length + 1}`);
    this.shifts.push(newShift);
    this.enableSaveButton = true;
  }

  deleteShift(shift: Shift) {
    shift.Enabled = false;
    //remove shift from list to re-enable [+] button
    this.shifts.splice(this.shifts.indexOf(shift), 1);
    if(this.shifts.length > 0){
    this.enableSaveButton = true;
    }
    else{
      this.enableSaveButton =false;
    }
  }

  isShiftLimitReached() {
    if (this.shifts && this.shifts.length >= 3){
      return true;
    }
    return false;
  }

  isShiftsValid(): boolean {
    for (let i = 0; i < this.shifts.length; i++) {
      const shift1 = this.shifts[i];
      if (!shift1.Enabled) {
        continue;
      }
      const startTime1 = momentHasRange(shift1.StartTime);
      const endTime1 = momentHasRange(shift1.EndTime);
      if (endTime1.isBefore(startTime1)) {
        endTime1.add(1, 'day');
      }
      const range1 = momentHasRange.range(startTime1, endTime1);
      for (let j = i + 1; j < this.shifts.length; j++) {
        const shift2 = this.shifts[j];
        if (!shift2.Enabled) {
          continue;
        }
        const startTime2 = momentHasRange(shift2.StartTime);
        const endTime2 = momentHasRange(shift2.EndTime);
        if (endTime2.isBefore(startTime2)) {
          endTime2.add(1, 'day');
        }
        let range2 = momentHasRange.range(startTime2, endTime2);
        if (range1.overlaps(range2)) {
          return false;
        }

        startTime2.add(1, 'day');
        endTime2.add(1, 'day');
        range2 = momentHasRange.range(startTime2, endTime2);
        if (range1.overlaps(range2)) {
          return false;
        }

        startTime2.subtract(2, 'day');
        endTime2.subtract(2, 'day');
        range2 = momentHasRange.range(startTime2, endTime2);
        if (range1.overlaps(range2)) {
          return false;
        }
      }
    }

    return true;
  }

  saveProjectShifts() {
    this.loadingIndicatorSvc.show();
    // validate no overlap in shifts
    if (this.isShiftsValid()) {
      const endtimeOffset = new Date();
      endtimeOffset.setHours(this.offsetHour, this.offsetMinute, 0);
      this.shiftService
        .createShiftList(this.shifts, endtimeOffset).pipe(
        finalize(() => {
          this.loadingIndicatorSvc.hide();
        }))
        .subscribe({
         next: (data) => console.log('save shifts successfully.',data),

        error:(e) => this.showError(e, 'Failed to save shifts')

    });
        this.enableSaveButton = false;
    } else {
      this.loadingIndicatorSvc.hide();
      const msg = 'There is overlap in shifts.';
      const err = new Error(msg);
      this.showError(err, msg);
    }
  }

  toggleSaveButton() {
    this.enableSaveButton = true;
  }

  private showError(e, message: string) {
    let content = '';
    if (e.error?.message) {
      content = e.error.message;
    } else {
      if (e?.message) {
        content = e.message;
      }
    }
    this.messageSvc.add({
      severity: 'error',
      summary: message,
      sticky: true,
      detail: content
    });
  }
}
