import { Component, OnInit } from '@angular/core';
import { ActivityService, ProjectService } from '#services/api';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityReport, ReportFilter, Project } from '#models/index';
import { LoadingIndicatorService } from '#services/shared';
import { from, Observable } from 'rxjs';
import { groupBy, mergeMap, toArray, map, finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-activity-report',
  templateUrl: './activity-report.component.html',
  styleUrls: ['./activity-report.component.scss']
})
export class ActivityReportComponent implements OnInit {

  currentProjectId: number;
  currentProject: Project;
  activities: ActivityReport[];
  reportFilter: ReportFilter;
  groupedActivities: Observable<any>;
  isGrouped: boolean = false;
  objectKeys = Object.keys;
  groupBy: string = null;
  searchText: string = '';
  tabExpanded: boolean = false;
  sortField: string = 'ActivityId';
  sortOrder: number = 1;
  get pageSize(): number { return this.isGrouped ? 10 : 25; }

  constructor(private route: ActivatedRoute,
    private activitySvc: ActivityService,
    private projectSvc: ProjectService,
    private loadingIndicatorSvc: LoadingIndicatorService,
    private router: Router,
    private translate: TranslateService
  ) {
    if (this.route.params) {
      this.route.params.subscribe(params => {
        this.currentProjectId = params.projectId;
        if (this.currentProjectId) {
          this.projectSvc.getProject(this.currentProjectId).subscribe(data => {
            this.currentProject = data;
          });
        }
      });
    }
  }

  ngOnInit() {
    this.getActivityReport();
  }


  getActivityReport() {
    if (this.currentProjectId) {
      this.reportFilter = this.reportFilter ? this.reportFilter : new ReportFilter();
      this.reportFilter.ProjectId = this.currentProjectId;
      this.loadingIndicatorSvc.show();
      this.activitySvc.getActivityReport(this.reportFilter).pipe(
        finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe(data => {
          this.activities = data;
        });
    } else {
      this.activities = [];
    }
  }

  applyFilter(reportFilter: ReportFilter) {
    this.loadingIndicatorSvc.show();
    this.activitySvc.getActivityReport(reportFilter).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe(data => {
        this.activities = data;
        this.searchActivities(this.searchText);
      });
  }


  groupActivitiesBy(groupFilter: any, filteredActivities?: ActivityReport[]) {
    this.groupBy = groupFilter;
    filteredActivities = filteredActivities ? filteredActivities : this.activities;
    filteredActivities = this.sortActivitiesbyActivityId(filteredActivities);
    switch (this.groupBy) {
      case null:
        this.isGrouped = false;
        break;
      case 'None':
        this.isGrouped = false;
        break;
      case 'Job':
        this.groupedActivities = from(filteredActivities).pipe(
          groupBy(activity => activity.WbsName),
          mergeMap(obs => {
            return obs.pipe(
              toArray(),
              map(acts => {
                return { header: obs.key ? obs.key : this.translate.instant('ACTIVITYREPORT.NoWBS'), activities: acts };
              })
            );
          }),
          toArray(),
        );
        this.isGrouped = true;
        break;
      case 'Status':
        this.groupedActivities = from(filteredActivities).pipe(
          groupBy(activity => activity.InternalStatus),
          mergeMap(obs => {
            return obs.pipe(
              toArray(),
              map(acts => {
                return { header: obs.key ? obs.key : this.translate.instant('ACTIVITYREPORT.NoAction'), activities: acts };
              })
            );
          }),
          toArray(),
        );
        this.isGrouped = true;
        break;
      case 'PrimaryCrew':
        this.groupedActivities = from(filteredActivities).pipe(
          groupBy(activity => activity.Crew1),
          mergeMap(obs => {
            return obs.pipe(
              toArray(),
              map(acts => {
                return { header: obs.key ? obs.key : this.translate.instant('ACTIVITYREPORT.NoPrimaryCrew'), activities: acts };
              })
            );
          }),
          toArray(),
        );
        this.isGrouped = true;
        break;
      case 'SecondaryCrew':
        this.groupedActivities = from(filteredActivities).pipe(
          groupBy(activity => activity.Crew2),
          mergeMap(obs => {
            return obs.pipe(
              toArray(),
              map(acts => {
                return { header: obs.key ? obs.key : this.translate.instant('ACTIVITYREPORT.NoSecondaryCrew'), activities: acts };
              })
            );
          }),
          toArray(),
        );
        this.isGrouped = true;
        break;
      case 'CoRepCrew':
        this.groupedActivities = from(filteredActivities).pipe(
          groupBy(activity => activity.CoRep),
          mergeMap(obs => {
            return obs.pipe(
              toArray(),
              map(acts => {
                return { header: obs.key ? obs.key : this.translate.instant('ACTIVITYREPORT.NoCompanyRep'), activities: acts };
              })
            );
          }),
          toArray(),
        );
        this.isGrouped = true;
        break;
      default:
        this.isGrouped = false;
        break;
    }
  }

  searchActivities(searchTerm: string) {
    this.searchText = searchTerm;
    if (this.groupBy && this.groupBy !== 'None') {
      const searchRegex = new RegExp(searchTerm, 'gi');
      try {
        const filteredActivities = this.activities.filter(activity => {
          for (const property in activity) {
            if (property && (property === 'ActivityId' || property === 'Name' || property === 'WbsName' || property === 'Status' || property === 'Crew1' || property === 'Crew2' || property === 'CoRep')) {
              if (activity[property] && String(activity[property]).match(searchRegex)) {
                return true;
              }
            }
          }
          return false;
        });
        this.groupActivitiesBy(this.groupBy, filteredActivities);
      } catch (error) {
      }
    }
  }

  collapseAll(event) {
    this.tabExpanded = event ? event : false;
  }

  onDeltaReportClick() {
    this.router.navigate([''], { queryParams: { projectObjectId: this.currentProjectId } });
  }

  sortActivitiesbyActivityId(activities: ActivityReport[]): ActivityReport[] {
    this.sortField = 'ActivityId';
    this.sortOrder = 1;
    return activities.sort((obj1, obj2) => {
      return obj1.ActivityId > obj2.ActivityId ? 1 : -1;
    });
  }

}
