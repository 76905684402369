import { NgModule,CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { NotificationComponent } from './notification/notification.component';
import { ActivityGridComponent } from './activitygrid/activitygrid.component';
import { AssignmentGridComponent } from './assignmentgrid/assignmentgrid.component';
import { NotificationService } from '#services/shared';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MessageService, ConfirmationService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TooltipModule } from 'primeng/tooltip';
import { ShiftGridComponent } from './shift-grid/shift-grid.component';
import {  DialogModule,  } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import {RadioButtonModule} from 'primeng/radiobutton';
import { ActivityJustificationsComponent } from './activityJustifications/activity-justifications.component';
import { ActivityReportFiltersComponent } from './activity-report-filters/activity-report-filters.component';
import { ActivityReportGridComponent } from './activity-report-grid/activity-report-grid.component';
import { MomentPipe } from './pipes/momentPipe';
import { ActivityAuditLogGridComponent } from './activityAuditLog/activityAuditLogGrid/activity.audit.log.grid.component';
import { ActivityAuditLogDetailsComponent } from './activityAuditLog/activityAuditLogDetails/activity.audit.log.details.component';
import { ActivityAuditLogFilterComponent } from './activityAuditLog/activityAuditLogFilter/activity.audit.log.filter.component';
import { ReturnToDeltaReportBtnComponent } from './return-to-delta-report-btn/return.to.delta.report.btn.component';
import { ActivityUploadComponent } from '#components/activityUpload/activity.upload.component';
import { ActivityUploadGridComponent } from './activityUpload/activityUploadGrid/activity.upload.grid.component';
import { MasterDataSyncComponent } from './master-data-sync/master-data-sync.component';
import { JustificationTypeDisplayTextPipe } from './pipes/justificationTypeDisplayTextPipe';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { SharedModule } from '#services/shared/shared.module';
import { InputSwitchModule } from 'primeng/inputswitch';
@NgModule({
  declarations: [
    NotificationComponent,
    ActivityGridComponent,
    AssignmentGridComponent,
    ShiftGridComponent,
    ActivityJustificationsComponent,
    ActivityReportFiltersComponent,
    ActivityReportGridComponent,
    ActivityAuditLogGridComponent,
    ActivityAuditLogDetailsComponent,
    ActivityAuditLogFilterComponent,
    MomentPipe,
    ReturnToDeltaReportBtnComponent,
    ActivityUploadComponent,
    ActivityUploadGridComponent,
    MasterDataSyncComponent,
    JustificationTypeDisplayTextPipe,
    ErrorDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    InputTextareaModule,
    CheckboxModule,
    CardModule,
    ButtonModule,
    TableModule,
    DataViewModule,
    DropdownModule,
    OverlayPanelModule,
    AutoCompleteModule,
    CalendarModule,
    MultiSelectModule,
    DialogModule,
    RadioButtonModule,
    TooltipModule,
    InputSwitchModule,
    SharedModule
  ],
  providers: [MessageService, ConfirmationService, NotificationService, MomentPipe],
  exports: [
    NotificationComponent,
    ActivityGridComponent,
    AssignmentGridComponent,
    ShiftGridComponent,
    ActivityJustificationsComponent,
    ActivityAuditLogGridComponent,
    ActivityAuditLogFilterComponent,
    ActivityReportFiltersComponent,
    ActivityReportGridComponent,
    ReturnToDeltaReportBtnComponent,
    ActivityUploadComponent,
    ActivityUploadGridComponent,
    MasterDataSyncComponent,
    ErrorDialogComponent,
    MomentPipe
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA],
})
export class ComponentsModule { }
