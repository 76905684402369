export class ProjectInfo {
  constructor(
    public ProjectType: string = null,
    public UserId?: number,
    public User: any = null,
    public ContactInformation: string = '',
    public ProjectId: number = 0,
    public ProjectName: string = '',
    public ProjectInstanceName: string = '',
    public StartDate: Date = null,
    public FinishDate: Date = null,
    public UpdatePeriod: string = '',
    public Completed: boolean = false) { }
}

