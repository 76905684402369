<div class="synchronization-module">
  <div class="panel">
    <div class="row">
      <h3>{{'SYNCHRONIZATION.Title' | translate}}</h3>
    </div>
    <div class="row">
      <div class="form-group col-sm-4">
        <label *ngIf="instanceList?.length > 1" for="projectList">{{'SYNCHRONIZATION.Instance' | translate}}:</label>
        <p-dropdown *ngIf="instanceList?.length > 1" name="instanceList" [options]="instanceList" optionLabel="Name"
          [style]="{'width':'100%'}" [(ngModel)]="currentInstance" (ngModelChange)="resetProjects()"
          scrollHeight="300px" [style]="{'width':'100%'}"></p-dropdown>
        <label for="projectList">{{'SYNCHRONIZATION.Project' | translate}}:</label>
        <p-dropdown name="projectList" [options]="projectList" placeholder="{{'SHARED.PleaseSelect' | translate}}" [(ngModel)]="currentProject"
          [disabled]="!instancesLoaded" (ngModelChange)="getProjectData()" optionLabel="Name" [showClear]="true"
          [filter]="true" filterBy="Name,ProjectId" scrollHeight="300px" [style]="{'width':'100%'}"
          (click)="this.loadProjects()">
          <ng-template let-it pTemplate="selectedItem">
            <span *ngIf="it"><b>{{it.ProjectId}}</b>:&nbsp;</span>
            <span>{{it.Name}}</span>
          </ng-template>
          <ng-template let-it pTemplate="item">
            <div *ngIf="it">
              <label class="flex align-items-center gap-2">{{it.ProjectId}}</label><small>{{it.Name}}</small>
            </div>
            <div *ngIf="!it"><span>{{it}}</span></div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="form-group col-sm-4" *ngIf="report != null">
        <div class="row">
          <button type="button" class="btn btn-primary sync-btn" [disabled]="!currentProject || report.Completed" (click)="download()">
            <span class="pi pi-cloud-download"></span>
            <span>{{'SYNCHRONIZATION.PublishLookahead' | translate}}</span>
          </button>
        </div>
        <div class="row" *ngIf="syncData1 != null">
          <span class="sync-comment">{{'SYNCHRONIZATION.LastSynchronized' | translate}} <b>{{syncData1.LastAttempt | momentPipe: 'L HH:mm:ss'}}</b>
            {{'SYNCHRONIZATION.By' | translate}} <b>{{syncData1.UserName}}</b></span>
        </div>
      </div>
      <div class="form-group col-sm-4">
        <div class="row">
          <button class="btn btn-primary sync-btn" [disabled]="!report || report.Completed" (click)="upload()">
            <span class="pi pi-cloud-upload"></span>
            <span>{{'SYNCHRONIZATION.UpdateActivity' | translate}}</span>
          </button>
        </div>
        <div class="row" *ngIf="syncData2 != null">
          <span class="sync-comment">{{'SYNCHRONIZATION.LastSynchronized' | translate}} <b>{{syncData2.LastAttempt |  momentPipe: 'L HH:mm:ss'}}</b>
            {{'SYNCHRONIZATION.By' | translate}} <b>{{syncData2.UserName}}</b></span>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="currentProject && initialDownload === true">
    <div class=col-sm-12>
      <p>
        {{'SYNCHRONIZATION.Project' | translate}} <b>{{currentProject.ProjectId}}</b> ({{currentProject.Name}}) {{'SYNCHRONIZATION.IsBeingDownloaded' | translate}}<br />
        {{'SYNCHRONIZATION.MightTake' | translate}}
      </p>
    </div>
  </div>
  <div class="panel" *ngIf="report != null">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="row">
            <h3>{{'SYNCHRONIZATION.DeltaReport' | translate}}</h3>
          </div>
        </div>
        <div class="col-sm-8">
          <div class="row">
            <div class="col-sm-4"><label>{{'SYNCHRONIZATION.ProjectName' | translate}}:</label></div>
            <div class="col-sm-6"><span>{{report.ProjectName}}</span></div>
          </div>
          <div class="row">
            <div class="col-sm-4"><label>{{'SYNCHRONIZATION.PrimaveraId' | translate}}:</label></div>
            <div class="col-sm-6"><span>{{report.ProjectCode}}</span></div>
          </div>
          <div class="row">
            <div class="col-sm-4"><label>{{'SYNCHRONIZATION.TotalActivities' | translate}}:</label></div>
            <div class="col-sm-6"><span>{{report.ActivityCount}}</span></div>
          </div>
          <div class="row">
            <button class="btn btn-primary btn-configure" (click)="onClickConfigureProject()">
              <span class="pi pi-cog"></span>
              <span>{{'SYNCHRONIZATION.ConfigureProject' | translate}}</span>
            </button>
            <button class="btn btn-primary btn-configure" style="margin-left: 10px" (click)="onClickActivityReport()">
              <span class="pi pi-chart-bar"></span>
              <span>{{'SYNCHRONIZATION.ActivityReport' | translate}}</span>
            </button>
            <button class="btn btn-primary btn-configure" style="margin-left: 10px" (click)="onClickActivityHistory()">
              <span class="pi pi-calendar-plus"></span>
              <span>{{'SYNCHRONIZATION.ActivityHistory' | translate}}</span>
            </button>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <a (click)="getReport()"><span class="pi pi-refresh"></span><span> {{'SYNCHRONIZATION.Refresh' | translate}}</span></a>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <p-chart type="pie" responsive="false" [data]="pieData" [height]="150"></p-chart>
        </div>
      </div>
    </div>
    <div class="row search-container">
      <h4 style="text-align:left">{{'SYNCHRONIZATION.SearchActivity' | translate}}</h4>
      <div class="col-sm-5" *ngIf="!WBSGrouping">
        <input type="search" pInputText placeholder="{{'SHARED.StartTyping' | translate}}"
          (keyup)="term$.next($event.target.value)" class="form-control">
      </div>
      <div class="col-sm-5" *ngIf="WBSGrouping">
        <input type="text" pInputText [(ngModel)]="WbsSearch" (keyup)="wbsTerm$.next($event.target.value)"
          placeholder="{{'SHARED.StartTyping' | translate}}" class="form-control">
      </div>
    </div>

    <div class="row">
      <br>
      <button id="expandButton" *ngIf="WBSGrouping" class="btn btn-primary" type="button" (click)="toggleAccordionTab()">
          {{ (!expandTab ? 'SYNCHRONIZATION.ExpandAll' : 'SYNCHRONIZATION.CollapseAll' ) | translate }}
      </button>
      <div class="float-right">
        <label style="font-weight: bold; font-size: initial;">{{'SYNCHRONIZATION.WBSGrouping' | translate}}: </label>
        <input id="WBSGroupCheck" type="checkbox" [(ngModel)]="WBSGrouping" data-toggle="toggle" (change)="toggleWBSGrouping()" />
      </div>
    </div>
    <br />

    <p-accordion [multiple]="true" *ngIf="!WBSGrouping">
      <p-accordionTab class="blueTab"
        header="Approved: {{report.ApprovedActivities.length}}/{{report.ActivityCount}} ({{report.ApprovedPercent|number:'1.2-2'}}%)"
        [selected]="report.ApprovedActivities.length > 0">
        <app-activity-grid [activities]="report.ApprovedActivities" [showrejectpanel]="true" [searchText]="searchText"
          (reject)="rejectActivity($event)" (loadComments)="getActivityComment($event)"
          [projectId]="this.currentProject.Id"></app-activity-grid>
      </p-accordionTab>
      <p-accordionTab class="blueTab"
        header="Rejected: {{report.RejectedActivities.length}}/{{report.ActivityCount}} ({{report.RejectedPercent|number:'1.2-2'}}%)"
        [selected]="report.RejectedActivities.length > 0">
        <app-activity-grid [activities]="report.RejectedActivities" (loadComments)="getActivityComment($event)"
          [searchText]="searchText" [projectId]="this.currentProject.Id">
        </app-activity-grid>
      </p-accordionTab>
      <p-accordionTab class="blueTab"
        header="Can't work: {{report.CantWorkActivities.length}}/{{report.ActivityCount}} ({{report.CantWorkPercent|number:'1.2-2'}}%)"
        [selected]="report.CantWorkActivities.length > 0">
        <app-activity-grid [activities]="report.CantWorkActivities" (loadComments)="getActivityComment($event)"
          [searchText]="searchText" [projectId]="this.currentProject.Id">
        </app-activity-grid>
      </p-accordionTab>
      <p-accordionTab class="blueTab"
        header="No verification: {{report.NoVerificationActivities.length}}/{{report.ActivityCount}} ({{report.NoVerificationPercent|number:'1.2-2'}}%)"
        [selected]="report.NoVerificationActivities.length > 0">
        <app-activity-grid [activities]="report.NoVerificationActivities" (loadComments)="getActivityComment($event)"
          [searchText]="searchText" [projectId]="this.currentProject.Id">
        </app-activity-grid>
      </p-accordionTab>
      <p-accordionTab class="blueTab"
        header="No Action: {{report.NotTouchedActivities.length}}/{{report.ActivityCount}} ({{report.NotTouchedPercent|number:'1.2-2'}}%)"
        [selected]="report.NotTouchedActivities.length > 0">
        <app-activity-grid [activities]="report.NotTouchedActivities" (loadComments)="getActivityComment($event)"
          [searchText]="searchText" [projectId]="this.currentProject.Id">
        </app-activity-grid>
      </p-accordionTab>
    </p-accordion>

    <p-accordion [multiple]="true" *ngIf="WBSGrouping">
      <!-- Approved -->
      <p-accordionTab class="blueTab"
        header="Approved: {{approvedCount}}/{{report.ActivityCount}} ({{report.ApprovedPercent|number:'1.2-2'}}%)"
        [selected]="filterReport.ApprovedGroupedActivities?.length && expandTab">
        <div *ngIf="!filterReport.ApprovedGroupedActivities.length">
          <h5>{{'SYNCHRONIZATION.NoData' | translate}}</h5>
        </div>
        <div class="WbsAccordion" *ngFor="let WBS of filterReport.ApprovedGroupedActivities">
          <p-accordionTab *ngIf="WBS.Jobs.length" class="whiteTab" header="{{WBS.EquipmentClassName}}" [selected]="WBS.Jobs.length && expandTab">
            <div class="JobAccordion" *ngFor="let Job of WBS.Jobs">
              <p-accordionTab *ngIf="Job.Activities.length" class="greyTab" header="{{Job.Name}}" [selected]="Job.Activities.length && expandTab">
                <app-activity-grid [activities]="Job.Activities" (loadComments)="getActivityComment($event)"
                  (reject)="rejectActivity($event)" [showrejectpanel]="true"
                  [projectId]="this.currentProject.Id">
                </app-activity-grid>
              </p-accordionTab>
            </div>
          </p-accordionTab>
        </div>
      </p-accordionTab>

      <!-- Rejected -->
      <p-accordionTab class="blueTab"
        header="Rejected: {{rejectedCount}}/{{report.ActivityCount}} ({{report.RejectedPercent|number:'1.2-2'}}%)"
        [selected]="filterReport.RejectedGroupedActivities?.length && expandTab">
        <div *ngIf="!filterReport.RejectedGroupedActivities.length">
          <h5>{{'SYNCHRONIZATION.NoData' | translate}}</h5>
        </div>
        <div class="WbsAccordion" *ngFor="let WBS of filterReport.RejectedGroupedActivities">
          <p-accordionTab *ngIf="WBS.Jobs.length" class="whiteTab" header="{{WBS.EquipmentClassName}}" [selected]="WBS.Jobs.length && expandTab">
            <div class="JobAccordion" *ngFor="let Job of WBS.Jobs">
              <p-accordionTab *ngIf="Job.Activities.length" class="greyTab" header="{{Job.Name}}" [selected]="Job.Activities.length && expandTab">
                <app-activity-grid [activities]="Job.Activities" (loadComments)="getActivityComment($event)"
                [projectId]="this.currentProject.Id">
                </app-activity-grid>
              </p-accordionTab>
            </div>
          </p-accordionTab>
        </div>
      </p-accordionTab>

      <!-- Can't Work -->
      <p-accordionTab class="blueTab"
        header="Can't Work: {{cantworkCount}}/{{report.ActivityCount}} ({{report.CantWorkPercent|number:'1.2-2'}}%)"
        [selected]="filterReport.CantWorkGroupedActivities?.length && expandTab">
        <div *ngIf="!filterReport.CantWorkGroupedActivities.length">
          <h5>{{'SYNCHRONIZATION.NoData' | translate}}</h5>
        </div>
        <div class="WbsAccordion" *ngFor="let WBS of filterReport.CantWorkGroupedActivities">
          <p-accordionTab *ngIf="WBS.Jobs.length" class="whiteTab" header="{{WBS.EquipmentClassName}}" [selected]="WBS.Jobs.length && expandTab">
            <div class="JobAccordion" *ngFor="let Job of WBS.Jobs">
              <p-accordionTab *ngIf="Job.Activities.length" class="greyTab" header="{{Job.Name}}" [selected]="Job.Activities.length && expandTab">
                <app-activity-grid [activities]="Job.Activities" (loadComments)="getActivityComment($event)"
                [projectId]="this.currentProject.Id">
                </app-activity-grid>
              </p-accordionTab>
            </div>
          </p-accordionTab>
        </div>
      </p-accordionTab>

      <!-- No Verification -->
      <p-accordionTab class="blueTab"
        header="No Verification: {{noverificationCount}}/{{report.ActivityCount}} ({{report.NoVerificationPercent|number:'1.2-2'}}%)"
        [selected]="filterReport.NoVerificationGroupedActivities?.length && expandTab">
        <div *ngIf="!filterReport.NoVerificationGroupedActivities.length">
          <h5>{{'SYNCHRONIZATION.NoData' | translate}}</h5>
        </div>
        <div class="WbsAccordion" *ngFor="let WBS of filterReport.NoVerificationGroupedActivities">
          <p-accordionTab *ngIf="WBS.Jobs.length" class="whiteTab" header="{{WBS.EquipmentClassName}}" [selected]="WBS.Jobs.length && expandTab">
            <div class="JobAccordion" *ngFor="let Job of WBS.Jobs">
              <p-accordionTab *ngIf="Job.Activities.length" class="greyTab" header="{{Job.Name}}" [selected]="Job.Activities.length && expandTab">
                <app-activity-grid [activities]="Job.Activities" (loadComments)="getActivityComment($event)"
                [projectId]="this.currentProject.Id">
                </app-activity-grid>
              </p-accordionTab>
            </div>
          </p-accordionTab>
        </div>
      </p-accordionTab>

      <!-- No Action -->
      <p-accordionTab class="blueTab"
        header="No Action: {{notTouchedCount}}/{{report.ActivityCount}} ({{report.NotTouchedPercent|number:'1.2-2'}}%)"
        [selected]="filterReport.NotTouchedGroupedActivities?.length && expandTab">
        <div *ngIf="!filterReport.NotTouchedGroupedActivities.length">
          <h5>{{'SYNCHRONIZATION.NoData' | translate}}</h5>
        </div>
        <div class="WbsAccordion" *ngFor="let WBS of filterReport.NotTouchedGroupedActivities">
          <p-accordionTab *ngIf="WBS.Jobs.length" class="whiteTab" header="{{WBS.EquipmentClassName}}" [selected]="WBS.Jobs.length && expandTab">
            <div class="JobAccordion" *ngFor="let Job of WBS.Jobs">
              <p-accordionTab class="greyTab" header="{{Job.Name}}" [selected]="Job.Activities.length && expandTab">
                <app-activity-grid [activities]="Job.Activities" (loadComments)="getActivityComment($event)"
                [projectId]="this.currentProject.Id">
                </app-activity-grid>
              </p-accordionTab>
            </div>
          </p-accordionTab>
        </div>
      </p-accordionTab>
    </p-accordion>

  </div>
</div>

<p-dialog header="Connection Issues" [(visible)]="showPrimaveraErrorModal" [modal]="true" [closable]="false">
  <p [innerHTML]="'SYNCHRONIZATION.ConnectionIssued' | translate">
  </p>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="this.LoadOfflineProject()" label="{{'SYNCHRONIZATION.GoOffline' | translate}}"></button>
    <button type="button" pButton icon="pi pi-check" (click)="showPrimaveraErrorModal = false;" label="{{'SYNCHRONIZATION.TryAgain' | translate}}"></button>
  </p-footer>
</p-dialog>

<app-activity-upload [projectId]="this.currentProject?.Id" [(display)]="this.showUploadPreview"
  (uploadCompleted)="onUploadCompleted()">
</app-activity-upload>
