import { Component, OnInit } from '@angular/core';
import { ActivityStartJustificationType } from '#models/activityStartJustificationType';
import { ActivityStartJustificationTypeService } from '#services/api/activityStartJustification.service';
import { MessageService } from 'primeng/api';

import { LoadingIndicatorService } from '#services/shared';
import { TranslateService } from '@ngx-translate/core';
import { finalize, firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-activity-start-justification-type',
  templateUrl: './activity-start-justification-type.component.html',
  styleUrls: ['./activity-start-justification-type.component.scss']
})
export class ActivityStartJustificationTypeComponent implements OnInit {
  justificationTypes: ActivityStartJustificationType[];
  justificationType = new ActivityStartJustificationType();
  displayAddDialog: boolean = false;

  constructor(private justificationService: ActivityStartJustificationTypeService, private loadingIndicatorSvc: LoadingIndicatorService, private messageSvc: MessageService, private translate: TranslateService) {}

  ngOnInit() {
    this.refreshJustificationTypes();
  }

  refreshJustificationTypes() {
    this.justificationService.getAll().subscribe({
      next:(data) => this.justificationTypes = data,
      error:(e) => {
        (async () => {
        this.justificationTypes.length = 0;
        this.showError(e, await firstValueFrom(this.translate.get('JUSTIFICATIONREASONS.FailedRetrieveJustification')))
        })();
      },
  });
  }

  toggleJustification(justificationType: any) {
    this.loadingIndicatorSvc.show();
    justificationType.Enabled = !justificationType.Enabled;
    this.justificationService
      .update(justificationType).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe({
        next:(data) => {},
        error: (e) => {
          (async () => {
            this.showError(e, await firstValueFrom(this.translate.get('JUSTIFICATIONREASONS.FailedRetrieveJustification')))
          })();
        },
  });
  }

  saveJustification(justificationType: any) {
    this.loadingIndicatorSvc.show();
    this.justificationService
      .update(justificationType).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe({
        next:(data) => justificationType.editable = false,
        error: (e) => {
          (async () => {
            this.showError(e, await firstValueFrom(this.translate.get('JUSTIFICATIONREASONS.FailedRetrieveJustification')));
          })();
        },
  });
  }

  createJustification() {
    this.loadingIndicatorSvc.show();
    this.justificationType.enabled = true;
    this.justificationService
      .add(this.justificationType).pipe(
      finalize(() => {
        this.loadingIndicatorSvc.hide();
        this.closeDialog();
        this.refreshJustificationTypes();
      }))
      .subscribe({
        next:(data) => this.justificationType = new ActivityStartJustificationType(),
        error:(e) => {
          (async () => {
            this.showError(e, await firstValueFrom(this.translate.get('JUSTIFICATIONREASONS.FailedRetrieveJustification')));
          })();
        },
  });
  }

  private showError(e, message: string) {
    this.messageSvc.add({
      severity: 'error',
      summary: message,
      sticky: true,
      detail: e.error.Message || ''
    });
  }

  showDialog() {
    this.displayAddDialog = true;
  }

  closeDialog() {
    this.displayAddDialog = false;
  }
}
