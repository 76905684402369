export class ActivityStartJustificationType {
  public createdDate: Date;
  public createdById: number;
  public id: number;
  public updatedById: number;
  public updatedDate: Date;

  public enabled: boolean;
  public text: string;
}
