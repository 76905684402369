import { Component, Input, OnInit } from '@angular/core';
import { ActivityService } from '#services/api';
import { MessageService } from 'primeng/api';
import { LoadingIndicatorService } from '#services/shared';
import { ActivityStartJustification } from '#models/activityStartJustification';
import { Comment } from 'models';
import { TranslateService } from '@ngx-translate/core';
import { finalize, firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-activity-justifications',
  templateUrl: './activity-justifications.component.html'
})
export class ActivityJustificationsComponent implements OnInit {
  private _activityId: number;
  public comments: Comment[];
  public justifications: ActivityStartJustification[];

  @Input()
  set activityId(activityId: number) {
    this._activityId = activityId;
  }

  constructor(private activityService: ActivityService, private messageSvc: MessageService, private loadingIndicatorSvc: LoadingIndicatorService, private translate: TranslateService) {}

  ngOnInit(): void {
    this.loadingIndicatorSvc.show();
    this.activityService
      .getComments(this._activityId).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe(comments => (this.comments = comments));

    this.loadingIndicatorSvc.show();
    this.activityService
      .getJustifications(this._activityId).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe({
        next:(justifications) => this.justifications = justifications,
        error:(e) => {
          (async () => {
            await this.showError(e);
          })();
        },
      });
  }

  private async showError(error?) {
    this.messageSvc.add({
      severity: 'error',
      summary: await firstValueFrom(this.translate.get('COMPONENTS.ErrorSynching')),
      sticky: true,
      detail: error.error.Message || error.message || ''
    });
  }
}
