import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { ActivityCodeType, PRIMARY_CREW, SUPPORTING_CREW, COMPANY_REPS, PA_JOB_NUMBER } from 'models';

@Injectable()
export class ProjectConfigurationService {
    private readonly httpOptions: any = { headers: { 'Content-Type': 'application/json' } };

    constructor(
        private http: HttpClient
    ) {

    }

    public searchActivityCodes(actCodeTypeList: any, term: string): Observable<any> {
        const param = (term || '').trim();
        return this.http.post<ActivityCodeType[]>(`${environment.apiEndpoint}/activitycodetype/search?term=${param}`, actCodeTypeList, this.httpOptions);
    }

    public searchActivityCodeTypesFromPrimavera(projectId: number): Observable<ActivityCodeType[]> {
        return this.http.get<any>(`${environment.apiEndpoint}/ActivityCodeType/PrimaveraSearchByProject/${projectId}`);
    }

    public getProjectConfig(projectId: number): Observable<any> {
        return this.http.get<any>(`${environment.apiEndpoint}/activity-code-configuration/project/${projectId}`);
    }

    public saveProjectConfig(projectId: number, config): Observable<any> {
        return this.http.post<any>(`${environment.apiEndpoint}/activity-code-configuration/project/${projectId}`, config);
    }

    public getEmptyConfig(): any {
        const result = {};
        [PRIMARY_CREW, SUPPORTING_CREW, COMPANY_REPS, PA_JOB_NUMBER].forEach(key => {
          result[key] = new ActivityCodeType();

        });
        return result;
    }

    public fillProjectConfig(config, codes): any {
      const result = {};
      [PRIMARY_CREW, SUPPORTING_CREW, COMPANY_REPS, PA_JOB_NUMBER].forEach(key => {
          if (config[key]) {
            result[key] = codes.find(e => e.Id === config[key].Id);
          } else {
            result[key] = new ActivityCodeType();
          }
      });
      return result;
  }
}
