import { Component, OnInit, Input, ViewChildren, QueryList, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { ActivityReport } from '#models/index';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-activity-report-grid',
  templateUrl: './activity-report-grid.component.html',
  styleUrls: ['./activity-report-grid.component.scss']
})
export class ActivityReportGridComponent implements OnInit, OnChanges {


  private _sortOrder: number;
  @Input()
  public get sortOrder(): number {
    return this._sortOrder;
  }
  public set sortOrder(value: number) {
    this._sortOrder = value;
    this.sortOrderChange.emit(value);
  }
  @Output() sortOrderChange: EventEmitter<number> = new EventEmitter<number>();

  private _sortField: string;
  @Input()
  public get sortField(): string {
    return this._sortField;
  }
  public set sortField(value: string) {
    this._sortField = value;
    this.sortFieldChange.emit(value);
  }
  @Output() sortFieldChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() pageSize: number;
  showPaginator: boolean;

  @Input() groupActivitiesBy: string;
  private _activities: ActivityReport[];
  @Input()
  set activities(activities: ActivityReport[]) {
    this._activities = activities || [];
    this.showPaginator = this._activities.length > this.pageSize;

    if (this.dataView?.first) {
      this.dataView.first.first = 0;
    }
  }
  get activities(): ActivityReport[] {
    return this._activities;
  }
  @Input()
  searchText: string;
  totalRecords: number;


  @ViewChildren('dvr') dataView: QueryList<any>;


  ngOnInit() {
    this.totalRecords = this.dataView?.first ? this.dataView.first.totalRecords : this.activities.length;
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'searchText') {
        const chng = changes[propName];
        const value = chng.currentValue;
          this.dataviews(value)
      } else if (propName === 'activities') {
        this.totalRecords = this.activities.length;
      }
    }
  }

  constructor(private translate: TranslateService) { }

  dataviews(value:string){
    if (this.dataView?.first) {
      this.dataView.first.filter(value);
      if (this.dataView.first.filterValue) {
        this.totalRecords = this.dataView.first.filteredValue ? this.dataView.first.filteredValue.length : this.dataView.first.totalRecords;
      } else {
        this.totalRecords = this.dataView?.first ? this.dataView.first.totalRecords : this.activities.length;
      }
    }
  }

  onSortChange(event) {
    this.sortOrder = this.sortField === event ? this.sortOrder * -1 : 1;
    this.sortField = event;
  }

  getStatusText(status: string) {
    return status ? status : this.translate.get('COMPONENTS.NoAction');
  }

  onClickShowJustifications(activity) {
    activity.DisplayJustifications = activity.DisplayJustifications !== true ? true : false;
  }


}
