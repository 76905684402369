import { ApplicationRef, Component, OnDestroy, OnInit } from "@angular/core";
import { AuthService,LoadingIndicatorService } from '#services/shared';
import { Router } from '@angular/router';
import { UserService } from '#services/api';
import { MsalService, MsalBroadcastService } from "@azure/msal-angular";
import {
  EventMessage,
  EventType,
  InteractionStatus,
} from "@azure/msal-browser";
import { Subject } from "rxjs";
import { filter, finalize, takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginDisplay = false;
  private readonly _destroying$ = new Subject<boolean>();
  constructor(private _authService: AuthService,private userSvc: UserService,private router: Router, private _msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService, private readonly _appRef: ApplicationRef, private readonly _loadingIndicatorService: LoadingIndicatorService) { }

  ngOnInit() {

      this.setLoginDisplay();
      this._msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
      this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        if (this._msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
        }
      });

      this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        if (this._authService.isAuthenticated()) {
          this._getUserData();
        }
      });
      if (this._authService.isAuthenticated()) {
        this._getUserData();
      }
  }

  ngOnDestroy(): void {
    this._destroying$.next(true);
    this._destroying$.complete();
  }

  setLoginDisplay() {
    this.loginDisplay = this._msalService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this._msalService.instance.getActiveAccount();
    if (
      !activeAccount &&
      this._msalService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this._msalService.instance.getAllAccounts();
      this._msalService.instance.setActiveAccount(accounts[0]);
    }
    // handle auth redired/do all initial setup for msal
    this._msalService.instance.handleRedirectPromise().then(authResult=>{
      // Check if user signed in
      const account = this._msalService.instance.getActiveAccount();
      if(!account){
        // redirect anonymous user to login page
        this._authService.login();
      }
    }).catch(err=>{
      console.log(err);
    });
  }

  private _getUserData(): void {
    this._authService
      .getUserData()
      .pipe(
        finalize(() => {
          this._appRef.tick();
        })
      )
      .subscribe(() => {
        if (this.userSvc.isAuthorized()) {
          this.router.navigate(['']);
        }
      });
  }
}
