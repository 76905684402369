import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { User, Crew, CrewAssignment } from 'models';
@Injectable()
export class CrewAssignmentService {
    constructor(
        private http: HttpClient
    ) { }

    public getCrewList(projectId: number): Observable<Crew[]> {
        return this.http.get<Crew[]>(`${environment.apiEndpoint}/crew/${projectId}`);
    }

    public getCrewListByProjectIdAndActivities(projectId: number): Observable<Crew[]> {
        return this.http.get<Crew[]>(`${environment.apiEndpoint}/crew/GetByProjectIdAndActivitiesAssignment/${projectId}`);
    }

    public refreshCrews(projectId: number): Observable<Crew[]> {
        return this.http.get<Crew[]>(`${environment.apiEndpoint}/crew/refresh/${projectId}`);
    }

    public updateCrew(crew: Crew): Observable<Crew> {
        return this.http.put<Crew>(`${environment.apiEndpoint}/crew/`, crew);
    }

    public getAssignmentList(projectId: number): Observable<CrewAssignment[]> {
        return this.http.get<CrewAssignment[]>(`${environment.apiEndpoint}/CrewAssignment/${projectId}`);
    }


    public deleteAssignment(id: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiEndpoint}/CrewAssignment/${id}`);
    }

    public updateAssignment(assignment: CrewAssignment[]): Observable<any> {
      const body = this.mapAssignment(assignment);
        return this.http.post<CrewAssignment[]>(`${environment.apiEndpoint}/CrewAssignment`, body);
    }

    private mapAssignment(assignment: CrewAssignment[]): any {
      const result = assignment.map(e => ({
        UserId: e.UserId,
        CrewId: e.Crew.CrewId,
        ProjectId: e.ProjectId,
        IsDeleted: e.IsDeleted,
        IsReadOnly: e.IsReadOnly
      }));
      return result;
    }

    public createAssigntmentModel(user: User): any {
        const r = new CrewAssignment();
        r.User = user;
        r.UserId = user.Id;
        return this.fullfiilModel(r);
    }

    public fullfiilModel(assignment: CrewAssignment): CrewAssignment {

        if (!assignment.User) {
            assignment.User = new User();
        }

        if (!assignment.Crew) {
            assignment.Crew = new Crew();
        }

        return assignment;
    }
}
