import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-activity-audit-log-details',
  templateUrl: './activity.audit.log.details.component.html'
})
export class ActivityAuditLogDetailsComponent  {
  @Input()
  justifications;

  @Input()
  comments;

}
