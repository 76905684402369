import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'momentPipe' })
export class MomentPipe implements PipeTransform {
    transform(value: Date | moment.Moment, dateFormat: string, ignoreTz: boolean = false): any {
      if(!value) {
        return '';
      }

      const locale = navigator.language;
      const t = ignoreTz
        ? moment.parseZone(value)
        : moment(value);

      const result = t.locale(locale).format(dateFormat);
      return result;
    }
}
