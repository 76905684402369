import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProjectsComponent, UsersComponent } from './';
import { ApiModule } from '#services/api/api.module';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { SharedModule } from '#services/shared/shared.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ComponentsModule } from '#components/components.module';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { DataViewModule } from 'primeng/dataview';
import { CardModule } from 'primeng/card';
import { TabViewModule } from 'primeng/tabview';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ActivityStartJustificationTypeComponent } from './activity-start-justification-type/activity-start-justification-type.component';
import { EmailService } from '#services/api/email.service';
import { InstancesComponent } from './instances/instances.component';
import { MobileManagementComponent } from './mobile-management/mobile-management.component';
import { ProjectInfoReportComponent } from './project-info-report/project-info-report.component';
import { ProjectsManagementComponent} from './projects-management/projects-management.component'
import { MultiSelectModule } from 'primeng/multiselect';
import { LanguageComponent } from './language/language.component';
import { TeamCommunicationComponent } from './team-communication/team-communication.component';

@NgModule({
    declarations: [
        ProjectsComponent,
        UsersComponent,
        InstancesComponent,
        ActivityStartJustificationTypeComponent,
        MobileManagementComponent,
        ProjectInfoReportComponent,
        LanguageComponent,
        TeamCommunicationComponent,
        ProjectsManagementComponent
    ],
    imports: [
        // Angular
        CommonModule,
        SharedModule,
        FormsModule,
        ComponentsModule,
        ReactiveFormsModule,
        MultiSelectModule,

        // PrimeNG
        InputTextModule,
        ButtonModule,
        DropdownModule,
        TabViewModule,
        DataViewModule,
        TableModule,
        CardModule,
        AutoCompleteModule,
        CheckboxModule,
        InputSwitchModule,
        DialogModule,
        CalendarModule,
        RadioButtonModule,

        // App
        ApiModule,
        SharedModule
    ],
    providers: [
        LoadingIndicatorService,
        MessageService,
        ConfirmationService,
        EmailService
    ]
})

export class AdminPagesModule { }
