import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WBS } from '#models/index';
import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class WbsService {

  constructor(private http: HttpClient, ) { }

  public getWbsforProject(projectId: number): Observable<any> {
    return this.http.get<WBS>(`${environment.apiEndpoint}/wbs/${projectId}`);
  }

  public getWbsCategories(): Observable<any> {
    return this.http.get<WBS>(`${environment.apiEndpoint}/WBSCategory`);
  }

  public getWbsforProjectByActivities(projectId: number): Observable<any> {
    return this.http.get<WBS>(`${environment.apiEndpoint}/wbs/byActivities/${projectId}`);
  }

  public getWbsCategoriesByActivities(projectId: number): Observable<any> {
    return this.http.get<WBS>(`${environment.apiEndpoint}/WBSCategory/byActivities/${projectId}`);
  }
}
