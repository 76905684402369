import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-return-to-delta-report-btn',
  templateUrl: './return.to.delta.report.btn.component.html',
  styleUrls: ['./return.to.delta.report.btn.component.scss']
})
export class ReturnToDeltaReportBtnComponent {
  @Input()
  projectObjectId : number;

  constructor(
    private router: Router
  ) {}

  onBtnClick() {
    this.router.navigate([''], {queryParams: {projectObjectId: this.projectObjectId}});
  }
}
