import { Comment } from './comment';
export class Activity {
  Id: number;
  WorkPriority: string;
  ActivityId: string;
  ActivityName: string;
  InternalStatus: string;
  PrimaryResource: string;
  PrimaveraPercentComplete: number;
  InternalPercentComplete: number;
  ProjectedEndDate: Date;
  Crew1: string;
  Crew2: string;
  CoRep: string;
  Comment: string;
  CommentCount: number;
  IsFutureShift: boolean;
  IsOutOfSequence: boolean;
  HasJustifications: boolean;

  Comments: Comment[];

  isApproved: boolean;
  rejectionComment: string;
}
