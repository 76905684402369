import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { SharedModule } from '#services/shared/shared.module';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { SynchronizationComponent } from './synchronization/synchronization.component';

import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '#components/components.module';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { TableModule } from 'primeng/table';
import { DataViewModule } from 'primeng/dataview';
import { ChartModule } from 'primeng/chart';
import { ConfirmationService,MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { ActivityReportComponent } from './reporting/activity-report/activity-report.component';
import { ActivityAuditLogComponent } from './auditLog/activity.audit.log.component';

@NgModule({
    declarations: [
        LoginComponent,
        HomeComponent,
        DocumentationComponent,
        SynchronizationComponent,
        ActivityReportComponent,
        ActivityAuditLogComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        SharedModule,
        FormsModule,
        ComponentsModule,

        // PrimeNG
        TooltipModule,
        InputTextModule,
        ButtonModule,
        DropdownModule,
        AccordionModule,
        TableModule,
        DataViewModule,
        ChartModule,
        DialogModule
    ],
    providers: [
        LoadingIndicatorService,
        MessageService,
        ConfirmationService
    ]
})

export class PagesModule { }
