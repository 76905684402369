import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { MobileVersionModel } from '#models/mobile-management';

@Injectable({
  providedIn: 'root'
})
export class MobileManagementService {

  constructor(private http: HttpClient) { }

  getAllVersions(): Observable<MobileVersionModel[]> {
    return this.http.get<MobileVersionModel[]>(`${environment.apiEndpoint}/MobileVersion/GetAllVersions`);
  }

  addOrUpdateVersion(versionObject: MobileVersionModel): Observable<MobileVersionModel> {
    return this.http.post<MobileVersionModel>(`${environment.apiEndpoint}/MobileVersion/CreateOrUpdateVersion`, versionObject);
  }

  public deleteVersion(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiEndpoint}/MobileVersion/DeleteById/${id}`);
  }
}
