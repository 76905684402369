import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {

  selectedLanguage: string;
  languageList = [{value: 'en', name: 'English'}, {value: 'ru', name: 'русский'}, {value: 'kaz', name: 'Қазақ'}];
  languageSelectionEnabled: boolean;
  constructor(private translate: TranslateService) { }

  ngOnInit() {
    this.getSelectedLanguage();
  }

  private async getSelectedLanguage() {
    try {
      const lang =  localStorage.getItem('language');
      this.selectedLanguage = lang ? lang : 'en';
      const selectedInstance = parseInt( localStorage.getItem('selectedInstance'));
      this.languageSelectionEnabled = environment.TCOInstanceId.includes((selectedInstance).toString());
    } catch (error) {}
  }

  onLanguageSelected() {
    this.translate.use(this.selectedLanguage);
    localStorage.setItem('language', this.selectedLanguage);
  }

}
