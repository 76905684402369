<ng-container *ngIf="justifications && justifications.length != 0">
<h3>{{'COMPONENTS.Justifications' | translate}}</h3>
<p-table [value]="justifications">
    <ng-template pTemplate="header">
      <tr>
        <th scope="col">{{'COMPONENTS.CreatedAt' | translate}}</th>
        <th scope="col">{{'COMPONENTS.Comment' | translate}}</th>
        <th scope="col">{{'COMPONENTS.Type' | translate}}</th>
        <th scope="col">{{'COMPONENTS.NewDate' | translate}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-justification>
      <tr>
        <td>{{justification.CreateDate | momentPipe: 'L HH:mm'}}</td>
        <td>{{justification.ActivityStartJustificationText}}</td>
        <td>{{justification.ActivityStartJustificationTypeText}}</td>
        <td>{{justification.ActivityStartJustificationNewDate | momentPipe: 'L HH:mm'}}</td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>

<ng-container *ngIf="comments !== undefined && comments.length >= 1">
<h3>{{'COMPONENTS.Comments' | translate}}</h3>
<p-table [value]="comments">
    <ng-template pTemplate="header">
      <tr>
        <th scope="col">{{'COMPONENTS.CreatedAt' | translate}}</th>
        <th scope="col">{{'COMPONENTS.Text' | translate}}</th>
        <th scope="col">{{'COMPONENTS.Type' | translate}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-comment>
      <tr>
        <td>{{comment.CreateDate | momentPipe: 'L HH:mm'}}</td>
        <td>{{comment.CommentText}}</td>
        <td>{{ comment.CommentType | justificationTypeDisplayTextPipe }}</td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>
