import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';
import { ActivityCodeType } from '#models/activityCodeType';

@Injectable()
export class ActivityCodeTypeService {
  constructor(private httpClient : HttpClient) {
  }

  public query(query): Observable<ActivityCodeType[]> {
    let params = new HttpParams();
    params = query.projectObjectId ? params.append('projectObjectId', query.projectObjectId) : params;
    params = query.scope ? params.append('scope', query.scope) : params;

    const response = this.httpClient.get<ActivityCodeType[]>(`${environment.apiEndpoint}/activitycodetype`, { params: params});
    return response;
  }
}

