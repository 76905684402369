import { Component, ViewEncapsulation, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SynchronizationService } from '#services/api/synchronization.service';
import { LoadingIndicatorService } from '#services/shared';
import { UploadPreviewDisplayData } from '#models/uploadPreviewDisplayData';
import { CommitActivityUpload } from '#models/CommitActivityUpload';
import { MessageService } from 'primeng/api';
import { finalize, firstValueFrom, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-activity-upload',
  templateUrl: './activity.upload.component.html',
  styleUrls: ['./activity.upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivityUploadComponent implements OnDestroy{
  private _isDisplayed = false;
  _isConfirmCommitChk = false;
  _isDisplayConfirmDlg = false;
  private _projectId;
  previewData = new Array<UploadPreviewDisplayData>();
  subscriptions = new Array<Subscription>();

  @Output()
  uploadCompleted = new EventEmitter();

  get projectId() {
    return this._projectId;
  }

  @Input()
  set projectId(value: number) {
    this._projectId = value;
  }

  @Output()
  displayChange = new EventEmitter<boolean>();

  @Input()
  get display() {
    return this._isDisplayed;
  }

  set display(val) {
    if(val) {
      this.showPreUploadModal();
    } else {
      this.hideModals();
    }
  }

  constructor(
    private synchronizationService: SynchronizationService,
    private loadingIndicatorSvc: LoadingIndicatorService,
    private messageService: MessageService,
    private translate: TranslateService
    ) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  initData() {
    this.loadingIndicatorSvc.show();
    const uploadSubscription = this.synchronizationService.uploadPreview(this.projectId).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe(e =>  {
        (async () => {
          this.populatePreviewData(e)
        })();
      });
    this.subscriptions.push(uploadSubscription);
  }

  async populatePreviewData(data: { Uploads: any[]; Conflicts: any[]; }) {
    if (data && (data.Uploads.length > 0 || data.Conflicts.length > 0)) {
      this.previewData = this.formatData(data.Uploads, data.Conflicts);
      // Automatically upload if there are no conflicts
      if (!this.previewData.some(p => p.hasConflict)) {
        this.commit();
      } else {
        this.hidePreUploadModal();
        this.showPreviewModal();
      }
    } else {
      this.messageService.add({
        severity: 'info',
        summary: await firstValueFrom(this.translate.get('COMPONENTS.NoResults')),
        detail: await firstValueFrom(this.translate.get('COMPONENTS.NoActivitiesUpload'))
      });
      this.hideModals();
    }
  }

  async commit() : Promise<void> {
    // Validate
    if(this.previewData.find(e => !e.upload && !e.overrideComment)) {
      this.messageService.add({
        severity: 'error',
        summary: await firstValueFrom(this.translate.get('COMPONENTS.ValidationError')),
        detail: await firstValueFrom(this.translate.get('COMPONENTS.UncheckedActivites'))
      });
      return;
    }

    // Commit
    const uploadData = this.previewData.map(e => new CommitActivityUpload(e));
    this.loadingIndicatorSvc.show();
    const commitSubscription = this.synchronizationService.commitUpload(this.projectId, uploadData).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe( e => {
        ( async () => {
        this.messageService.add({
          severity: 'success',
          summary: await firstValueFrom(this.translate.get('COMPONENTS.CommitComplete')),
          detail: await firstValueFrom(this.translate.get('COMPONENTS.ChangesCommited'))
        });
        this.uploadCompleted.emit();
      })();
      });

    this.subscriptions.push(commitSubscription);
    this.hideModals();
  }

  formatData(uploads: any[], conflicts: any[]): UploadPreviewDisplayData[] {
    const uploadDisplayData = uploads.map(e => new UploadPreviewDisplayData(e));
    const conflictsDisplayData = conflicts.map(e => new UploadPreviewDisplayData(e.MtaData, e.PrimaveraData));
    return uploadDisplayData.concat(conflictsDisplayData);
  }

  showPreviewModal() {
    this._isDisplayed = true;
  }

  showPreUploadModal() {
    this.displayChange.emit(true);
    this._isDisplayConfirmDlg = true;
  }

  hidePreUploadModal() {
    this._isDisplayConfirmDlg = false;
  }

  hideModals() {
    this.displayChange.emit(false);
    this._isDisplayed = false;
    this._isConfirmCommitChk = false;
    this._isDisplayConfirmDlg = false;
  }
}
