import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { ActivityStartJustificationType } from '#models/activityStartJustificationType';

@Injectable()
export class ActivityStartJustificationTypeService {
  private readonly httpOptions: any = { headers: { 'Content-Type': 'application/json' } };
  private readonly endpoint = `${environment.apiEndpoint}/activityStartJustificationTypes/`;

  constructor(private client: HttpClient) {}

  public getAll(): Observable<any> {
    const result = this.client.get<ActivityStartJustificationType[]>(`${environment.apiEndpoint}/activityStartJustificationTypes/`);
    return result;
  }

  public add(activityStartJustificationType): Observable<any> {
    return this.client.post<any>(this.endpoint, activityStartJustificationType);
  }

  public getById(id: number) {
    const queryParams = new HttpParams();
    queryParams.set('id', id.toString());
    this.client.get<ActivityStartJustificationType>(this.endpoint, { params: queryParams });
  }

  public update(justification: any) {
    return this.client.put<ActivityStartJustificationType>(`${this.endpoint}/${justification.Id}`, justification);
  }

  public create(justification: any) {
    return this.client.post(this.endpoint, justification);
  }
}
