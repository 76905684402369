import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService, ConfirmationService, SelectItem } from 'primeng/api';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { Instance, ProjectsManagement } from '#models/index';
import { ProjectService, InstanceService } from '#services/api';
import { environment } from '../../../environments/environment';
import { Table } from 'primeng/table';
import { finalize, firstValueFrom, Subscription } from 'rxjs';
import * as bigInt from 'big-integer';
import { TranslateService } from '@ngx-translate/core';
import { ProjectInfoService } from '#services/api/projectInfo.service';

@Component({
  selector: 'app-synchronization',
  templateUrl: './projects-management.component.html',
  styleUrls: ['./projects-management.component.scss']
})
export class ProjectsManagementComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  projectQueryparam;
  startDate: any;
  finishDate: any;
  projects: ProjectsManagement[] = [];

  currentInstance: Instance;
  instancesLoaded: boolean = false;
  instanceList: Instance[];
  projectTypefilterOpts: SelectItem[];
  filterCompletedStatusOpts: SelectItem[];
  @ViewChild('dt') table: Table;

  private searchSubscription: Subscription;

  constructor(
    private confirmationSvc: ConfirmationService,
    private messageSvc: MessageService,
    private loadingIndicatorSvc: LoadingIndicatorService,
    private projectSvc: ProjectService,
    private projectInfoSvc: ProjectInfoService,
    private activatedRoute: ActivatedRoute,
    private instanceSvc: InstanceService,
    private translate: TranslateService,
  ) { }

  async ngOnInit() {
    this.projectQueryparam = this.activatedRoute.snapshot.queryParamMap.has('projectObjectId')
      ? parseInt(this.activatedRoute.snapshot.queryParamMap.get('projectObjectId'), 10)
      : undefined;
    this.instanceSvc
      .getInstancesMulticast()
      .subscribe((data: Instance[]) => {
        (async () => {
        this.instanceList = data;
        this.instancesLoaded = true;
        this.currentInstance = this.instanceList[0];
        parseInt( localStorage.getItem('selectedInstance'));
        this.getProjectsByInstance()
      })();
      });



    this.projectTypefilterOpts = [
      { label: await firstValueFrom(this.translate.get('PROJECTINFO.Live')), value: 'Live' },
      { label: await firstValueFrom(this.translate.get('PROJECTINFO.Test')), value: 'Test' }
    ];
    this.filterCompletedStatusOpts = [
      { label: await firstValueFrom(this.translate.get('PROJECTINFO.Completed')), value: 'Completed' },
      { label: await firstValueFrom(this.translate.get('PROJECTINFO.InProgress')), value: 'Progress' }
    ];
  }

  getProjectsByInstance() {

    this.projectSvc.getProjectByInstance(this.currentInstance.Id).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe({
        next:(data: ProjectsManagement[]) => {
        this.projects = data;
        this.projects.forEach(project => {
          project.StartDate = project.StartDate ? new Date(project.StartDate) : null;
          project.FinishDate = project.FinishDate ? new Date(project.FinishDate) : null;
        });
      },
      error:(e) => {
        (async () => {
        (console.log('Error',e))
      })();
      }
    });
  }

  selectionChange(project) {
    if (!project.Enabled) {
        this.confirmationSvc.confirm({
            header: this.translate.instant('TurnOn project'),
            message: this.translate.instant('Do you want to disable this project'),
            acceptLabel: this.translate.instant('SHARED.Yes'),
            rejectVisible: true,
            accept: async() => {
              await this.disabledProject(project);
            },
            reject: () => {
               project.Enabled = true;
              }
          });
    } else{
        this.confirmationSvc.confirm({
            header: this.translate.instant('Disable project'),
            message: this.translate.instant('Do you want to enable this project'),
            acceptLabel: this.translate.instant('SHARED.Yes'),
            rejectVisible: true,
            accept: async() => {
              await this.enabledProject(project);
            },
            reject: () => {
                project.Enabled = false;
            }
          });
    }
  }


  async validateLanguage() {
    const selectedInstance = parseInt( localStorage.getItem('selectedInstance'));
    let languageSelectionEnabled = environment.TCOInstanceId.includes((selectedInstance).toString());
    if (!languageSelectionEnabled) {
      this.translate.use('en');
    }
  }

  getInstanceId(combinedId): number {
    const instanceId = bigInt(combinedId).and(0xFFFFFFFF00000000).shiftRight(32).add(1);
    return instanceId.toJSNumber();
  }

  resetTable() {
    this.startDate = '';
    this.finishDate = '';
    this.table.reset();
    localStorage.setItem('selectedInstance', this.currentInstance.Id.toString());
    this.getProjectsByInstance()
  }

  filterByProjectState(event) {
    const filter = event.value.length == 1 ? event.value[0] : 'both';
    switch (filter) {
      case 'Completed':
        this.table.filter(true, 'Completed', 'equals');
        break;
      case 'inProgress':
        this.table.filter(false, 'Completed', 'equals');
        break;
      default:
        this.table.filter('', 'Completed', 'equals');
        break;
    }
  }

  filterByProjectType(event) {
    const filter = event.value.length == 1 ? event.value[0] : 'both';
    switch (filter) {
      case 'Live':
        this.table.filter('Live', 'ProjectType', 'equals');
        break;
      case 'Test':
        this.table.filter('Test', 'ProjectType', 'equals');
        break;
      default:
        this.table.filter('', 'ProjectType', 'equals');
        break;
    }
  }

  filterDate(date, field, condition) {
    this.table.filter(date, field, condition);
  }

  async enabledProject(project: ProjectsManagement){
     this.projectInfoSvc.enableProject(project.ProjectId).subscribe({
      next:(dto) => {
        this.sendMessage('success', this.translate.instant('COMMUNICATION.ProjectChange'), project.ProjectName +  this.translate.instant('COMMUNICATION.EnableProject'));
        if (dto === null) {
          this.resetTable();
        }
      },
      error:(e) => {
        (async () => {
        project.Enabled = false;
      })();
      }
    });
  }

  async disabledProject(project: ProjectsManagement){
     this.projectInfoSvc.disableProject(project.ProjectId).subscribe({
      next:(dto) => {
        this.sendMessage('success', this.translate.instant('COMMUNICATION.ProjectChange'), project.ProjectName +  this.translate.instant('COMMUNICATION.DisableProject'));
        if (dto === null) {
          this.resetTable();
        }
      },
      error:(e) => {
        (async () => {
        project.Enabled = true;
      })();
      }
    })
  }

  sendMessage(type, message, detail) {
    this.messageSvc.add({
      severity: type,
      summary: message,
      sticky: true,
      detail: detail
    });
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
