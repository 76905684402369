import { NgModule } from '@angular/core';
import { BusinessUnitsService, FeedbackService } from './';
import { SharedModule } from '#services/shared/shared.module';
import { ProjectService } from '#services/api/project.service';
import { CrewAssignmentService } from '#services/api/crewAssignment.service';
import { UserService } from '#services/api/user.service';
import { ProjectConfigurationService } from './projectConfiguration.service';
import { ActivityService } from './activity.service';
import { ActivityStartJustificationTypeService } from './activityStartJustification.service';
import { ShiftService } from './shift.service';
import { ObsService } from './obs.service';
import { WbsService } from './wbs.service';
import { ActivityAuditLogService } from './activity.audit.log.service';
import { InstanceService } from './instance.service';
import { SynchronizationService } from './synchronization.service';
import { ActivityCodeTypeService } from './activityCodeType.service';
import { DateTimeService } from './datetime.service';
import { ProjectInfoService } from './projectInfo.service';

@NgModule({
  declarations: [],
  imports: [SharedModule],
  providers: [
    BusinessUnitsService,
    DateTimeService,
    FeedbackService,
    ProjectService,
    ActivityService,
    ProjectConfigurationService,
    ProjectInfoService,
    CrewAssignmentService,
    UserService,
    ShiftService,
    ObsService,
    InstanceService,
    ActivityStartJustificationTypeService,
    WbsService,
    ActivityAuditLogService,
    SynchronizationService,
    ActivityCodeTypeService
  ]
})
export class ApiModule { }
