import * as moment from 'moment';

export class UploadPreviewDisplayData {
  activityObjectId;
  activityId;
  activityName;
  newPrimaveraPercent;
  oldPrimaveraPercent;
  hasConflict;
  newActualStartDate: any;
  newActualFinishDate: any;
  upload = true;
  overrideComment: string;

  constructor(mtaActivity, primaveraData?) {
    this.hasConflict = primaveraData ? true : false;

    this.activityId = mtaActivity.ActivityId;
    this.activityObjectId = mtaActivity.Id;
    this.activityName = mtaActivity.Name;
    this.oldPrimaveraPercent = primaveraData ? primaveraData.PercentComplete : mtaActivity.PercentComplete;
    this.oldPrimaveraPercent = Math.round(this.oldPrimaveraPercent * 100) ;
    this.newPrimaveraPercent = mtaActivity.InternalPercentComplete;
    this.newActualStartDate = mtaActivity.InternalActualStartDate
      ? moment(mtaActivity.InternalActualStartDate).format('llll')
      : null;
    this.newActualFinishDate = mtaActivity.InternalActualFinishDate
      ? moment(mtaActivity.InternalActualFinishDate).format('llll')
      : null;
    this.overrideComment = `Previously approved activity has been overridden by scheduler from ${this.newPrimaveraPercent}% to ${this.oldPrimaveraPercent}%`;
  }
}
