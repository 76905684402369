<div class="activity-report-filter">

  <div class="row">
    <div class="col-sm-4" style="margin-top: 20px;">
      <button pButton (click)="hideFilter=!hideFilter" class="btn btn-primary"
        label="{{(!hideFilter ? 'COMPONENTS.HideFilter':'COMPONENTS.ShowFilter') | translate}}"
        icon="{{!hideFilter ? 'pi pi-chevron-circle-up':'pi pi-chevron-circle-down'}}" iconPos="right">
      </button>
    </div>
    <div class="col-sm-8">
      <div style="display: flex; flex-direction: row; justify-content: flex-end;">
        <div class="col-sm-3">
          <label>{{'COMPONENTS.GroupBy' | translate}}</label>
          <p-dropdown [options]="groupByData" [style]="{'width':'100%'}" showClear="true"
            (onChange)="applyGroupBy($event)">
          </p-dropdown>
        </div>
        <div style="margin-top: 25px;">
          <button pButton (click)="collapseAll()" class="ui-button-rounded"
            label="{{(!expanded ? 'COMPONENTS.ExpandAll':'COMPONENTS.CollapseAll') | translate}}"
            icon="{{!expanded ? 'pi pi-chevron-circle-down':'pi pi-chevron-circle-up'}}" iconPos="right"
            *ngIf="showCollapse">
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!hideFilter">
    <div class="row">
      <div class="col-sm-2">
        <label>{{'COMPONENTS.EquipmentClass' | translate}}</label>
        <p-multiSelect [options]="WBSCategoryFilterData" [(ngModel)]="selectedWBSCategoryFilter" optionLabel="Name"
          [style]="{'width':'100%'}" [filter]="true">
        </p-multiSelect>
      </div>
      <div class="col-sm-2">
        <label>{{'COMPONENTS.Job' | translate}}</label>
        <p-multiSelect [options]="WBSFilterData" [(ngModel)]="selectedWBSFilter" optionLabel="Name"
          [style]="{'width':'100%'}" [filter]="true"></p-multiSelect>
      </div>
      <div class="col-sm-2">
        <label>{{'COMPONENTS.Crew' | translate}}</label>
        <p-multiSelect [options]="crewFilterData" [(ngModel)]="selectedcrewFilter" optionLabel="Name"
          [style]="{'width':'100%'}" [filter]="true"></p-multiSelect>
      </div>
      <div class="col-sm-2">
        <label>{{'COMPONENTS.Status' | translate}}</label>
        <p-multiSelect [options]="statusFilterData" [(ngModel)]="selectedstatusFilter" [style]="{'width':'100%'}"
          [filter]="true"></p-multiSelect>
      </div>
    </div>
    <div class="row">
      <div style="float: right">
        <button type="button" class="btn btn-primary" (click)="clearFilters()">
          <span>{{'COMPONENTS.Clear' | translate}}</span>
        </button>
        <button type="button" class="btn btn-primary left-margin" (click)="applyFilters()">
          <span>{{'COMPONENTS.Apply' | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>
