import { Component, OnInit, ViewChild  } from '@angular/core';
import { Subscription, firstValueFrom, forkJoin } from 'rxjs';
import { ProjectInfo, User } from 'models/';
import { ProjectInfoService } from '#services/api/projectInfo.service';
import { UserService } from '#services/api';
import { SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-project-info-report',
  templateUrl: './project-info-report.component.html',
  styleUrls: ['./project-info-report.component.scss']
})
export class ProjectInfoReportComponent implements OnInit {
  projectInfo: ProjectInfo[] = [];
  users: User[] = [];
  statusList: any[] = [];
  isAddMode = false;
  completedStatus = 'both';
  subscriptions = new Array<Subscription>();
  projectTypefilterOpts: SelectItem[];
  filterCompletedStatusOpts: SelectItem[];
  @ViewChild('dt') table: Table;
  startDate: any;
  finishDate: any;
  constructor(
    private projectInfoSvc: ProjectInfoService, private userSvc: UserService, private translate: TranslateService) { }

  

  async ngOnInit() {
    this.getAllProjectInfoAndUsers();
    this.projectTypefilterOpts = [
      {label: await firstValueFrom(this.translate.get('PROJECTINFO.Live')), value: 'Live'},
      {label: await firstValueFrom(this.translate.get('PROJECTINFO.Test')), value: 'Test'}
    ];
    this.filterCompletedStatusOpts = [
      {label: await firstValueFrom(this.translate.get('PROJECTINFO.Completed')), value: 'Completed'},
      {label: await firstValueFrom(this.translate.get('PROJECTINFO.Progress')), value: 'Progress'}
    ];
  }

  getAllProjectInfoAndUsers() {
    forkJoin([this.projectInfoSvc.getAllProjectInfo(), this.userSvc.getAll()]).subscribe(response => {
      this.projectInfo = response[0];
      this.users = response[1];
      this.projectInfo.forEach(project => {
        const user = this.users.find(userRecord => userRecord.Id === project.UserId);
        project['FullName'] = `${user.FirstName} ${user.LastName}`;
        project.StartDate = project.StartDate ? new Date(project.StartDate) : null;
        project.FinishDate = project.FinishDate ? new Date(project.FinishDate) : null;
      });
    });
  }

  filterDate(date, field, condition) {
    this.table.filter(date, field, condition);
  }

  resetTable() {
    this.startDate = '';
    this.finishDate = '';
    this.table.reset();
  }

  filterByProjectState(event) {
    const filter = event.value.length == 1 ? event.value[0] : 'both';
    switch (filter) {
      case 'Completed':
        this.table.filter(true, 'Completed', 'equals');
        break;
      case 'inProgress':
        this.table.filter(false, 'Completed', 'equals');
        break;
      default:
        this.table.filter('', 'Completed', 'equals');
        break;
    }
  }

  filterByProjectType(event) {
    const filter = event.value.length == 1 ? event.value[0] : 'both';
    switch (filter) {
      case 'Live':
        this.table.filter('Live', 'ProjectType', 'equals');
        break;
      case 'Test':
        this.table.filter('Test', 'ProjectType', 'equals');
        break;
      default:
        this.table.filter('', 'ProjectType', 'equals');
        break;
    }
  }

}
