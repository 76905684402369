import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Project, Activity, ProjectsManagement } from 'models';
import { GroupMessage } from '#models/groupmessage';
import { DeltaReport } from '#models/delta-report';

@Injectable()
export class ProjectService {
    constructor(
        private http: HttpClient
    ) { }

    private readonly endpoint: string = `${environment.apiEndpoint}/project`;

    public getProjectList(instanceId: number): Observable<Project[]> {
        return this.http.get<Project[]>(`${this.endpoint}/primavera/projects/${instanceId || ''}`);
    }

    public getProject(projectId: number): Observable<Project> {
        return this.http.get<Project>(`${this.endpoint}/${projectId}`);
    }

    public deleteProject(id: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiEndpoint}/Project/${id}`);
    }

    public getBackendSchedulerProjects(selectedInstance :string): Observable<Project[]> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append('active', true.toString());
        queryParams = queryParams.append('forScheduler', true.toString());
        queryParams = queryParams.append('instanceId', selectedInstance);
        return this.http.get<Project[]>(this.endpoint, { params: queryParams });
    }

    public getDeltaReport(projectId: number): Observable<DeltaReport> {
        return this.http.get<DeltaReport>(`${environment.apiEndpoint}/reports/delta/${projectId}`);
    }

    public getSyncInfo(projectId: number): Observable<any> {
        return this.http.get<void>(`${environment.apiEndpoint}/primavera/info/${projectId}`);
    }

    public downloadNonProjectData(instanceId: number = null): Observable<any> {
        let query = instanceId == null ? '' : `?instanceId=${instanceId}`;
        return this.http.get<void>(`${environment.apiEndpoint}/primavera/download${query}`);
    }

    public downloadProjectData(projectId: number): Observable<any> {
        return this.http.get<void>(`${environment.apiEndpoint}/primavera/download/${projectId}`);
    }

    public uploadProject(projectId: number): Observable<Activity[]> {
        return this.http.post<Activity[]>(`${environment.apiEndpoint}/primavera/upload/${projectId}`, null);
    }

    public rebuildProjectCache(projectId: number): Observable<void> {
      return this.http.post<void>(`${environment.apiEndpoint}/project/${projectId}/rebuildCache`, null);
    }

    public primaveraDownProjectNotification(projectId: number): Observable<void> {
      return this.http.get<void>(`${environment.apiEndpoint}/project/projects/${projectId}/primaveradownnotification`);
    }

    public emailLookaheadProjectStatus(projectId: number): Observable<void> {
      return this.http.post<void>(`${environment.apiEndpoint}/project/projects/emaillookaheadstatus`, projectId );
    }

    public getProjectByInstance(instanceId: number): Observable<ProjectsManagement[]> {
        return this.http.get<ProjectsManagement[]>(`${environment.apiEndpoint}/project/projects/${instanceId}/ProjectsByInstance`);
    }

    public sendMessageToGroups(groupMessage: GroupMessage): Observable<void> {
      return this.http.post<void>(`${environment.apiEndpoint}/project/messagetogroups`, groupMessage);
  }
}
