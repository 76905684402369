import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { PagesModule } from '#pages/pages.module';
import { IsSystemAdmin, IsApplicationUser } from './app.routing.guards';

import { HomeComponent } from '#pages/home/home.component';
import { LoginComponent } from '#pages/login/login.component';

import { ErrorPagesModule } from '#pages/error/error.pages.module';
import { NotFoundComponent } from '#pages/error/not-found/not-found.component';
import { NoAccessComponent } from '#pages/error/no-access/no-access.component';
import { UnknownErrorComponent } from '#pages/error/unknown-error/unknown-error.component';

import { AdminPagesModule } from '#pages/admin/admin.pages.module';
import { UsersComponent, ProjectsComponent, InstancesComponent, MobileManagementComponent } from '#pages/admin';
import { ActivityStartJustificationTypeComponent } from '#pages/admin/activity-start-justification-type/activity-start-justification-type.component';
import { ActivityReportComponent } from '#pages/reporting/activity-report/activity-report.component';
import { ActivityAuditLogComponent } from '#pages/auditLog/activity.audit.log.component';
import { ProjectInfoReportComponent } from '#pages/admin/project-info-report/project-info-report.component';
import { ProjectsManagementComponent } from "#pages/admin/projects-management/projects-management.component";

import { LanguageComponent } from '#pages/admin/language/language.component';
import { TeamCommunicationComponent } from '#pages/admin/team-communication/team-communication.component';
import { MsalGuard } from '@azure/msal-angular';

const routes: Route[] = [
  // Home page and slash-appender
  {
    path: '',
    canActivate: [MsalGuard, IsSystemAdmin],
    component: HomeComponent
  },

  // Activity Audit Log
  {
    path: 'activityauditlog',
    // canActivate: [IsApplicationUser],
    component: ActivityAuditLogComponent
  },

  // Admin pages
  {
    path: 'admin',
    children: [
      { path: 'justifications', component: ActivityStartJustificationTypeComponent, canActivate: [IsSystemAdmin] },
      { path: 'users', component: UsersComponent, canActivate: [IsSystemAdmin] },
      { path: 'language', component: LanguageComponent, canActivate: [IsSystemAdmin] },
      { path: 'team-communication', component: TeamCommunicationComponent, canActivate: [IsSystemAdmin] },
      { path: 'instances', component: InstancesComponent, canActivate: [IsSystemAdmin] },
      { path: 'mobile-management', component: MobileManagementComponent, canActivate: [IsSystemAdmin] },
      { path: 'projects/:id', component: ProjectsComponent, canActivate: [IsSystemAdmin] },
      { path: 'projects', component: ProjectsComponent, canActivate: [IsSystemAdmin] },
      { path: 'projects-management', component: ProjectsManagementComponent, canActivate: [IsSystemAdmin] }
    ]
  },

  // reporting pages
  {
    path: 'reporting',
    children: [
      { path: 'activityreport/:projectId', component: ActivityReportComponent, canActivate: [IsSystemAdmin] },
      { path: 'project-info', component: ProjectInfoReportComponent, canActivate: [IsSystemAdmin] },
    ]
  },


  // login wait screen
  {
    path: 'login',
    component: LoginComponent
  },
  // Error pages
  {
    path: 'error',
    children: [
      { path: 'not-found', component: NotFoundComponent },
      { path: 'no-access',component: NoAccessComponent },
      { path: 'unknown-error', component: UnknownErrorComponent },
    ]
  },
  // Displays 404 "Not Found" error page for all unrecognized router.
  // Instead of redirecting, renders context to preserve original "incorrect" url
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    PagesModule,
    ErrorPagesModule,
    AdminPagesModule
  ],
  exports: [RouterModule],
  providers: [
    IsSystemAdmin,
    IsApplicationUser
  ]
})
export class AppRoutingModule { }
