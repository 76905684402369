<ng-container *ngIf="this.tableData && this.tableData.length !== 0; else elseTemplate">
  <div class="non-scrollable-table">
    <p-table [columns]="this.cols"
      [value]="this.tableData"
      [autoLayout]="true"
      [paginator]="true"
      [rows]="20"
      sortField="CreateDate"
      sortOrder="-1">

      <ng-template pTemplate="header" let-columns>
        <tr>
          <th scope="col" *ngFor="let col of columns" [pSortableColumn]="col.filterBy ? col.filterBy : col.field">
            {{col.header}}<p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
          <th scope="col">{{'COMPONENTS.Details' | translate}}</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">{{rowData[col.field]}}</td>
          <td>
            <a *ngIf="this.syncHasCommentsOrJustifications(rowData)"
              (click)="rowData.displayDetails = !rowData.displayDetails;">
              {{'COMPONENTS.DetailsU' | translate}}
            </a>
          </td>
        </tr>
        <tr *ngIf="rowData.displayDetails">
          <td colspan="8">
            <app-activity-audit-log-details
            [justifications]="this.displayDataMap.get(GetActivityMapKey(rowData)).justifications"
            [comments]="this.displayDataMap.get(GetActivityMapKey(rowData)).comments"
            ></app-activity-audit-log-details>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <H4>{{'COMPONENTS.NoDataToShow' | translate}}</H4>
</ng-template>
