import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MomentPipe } from '#components/pipes/momentPipe';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-activity-audit-log-grid',
  templateUrl: './activity.audit.log.grid.component.html',
  styleUrls: ['./activity.audit.log.grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivityAuditLogGridComponent implements OnInit {

  constructor(private momentPipe: MomentPipe, private translate: TranslateService) {
  }

  displayDataMap: Map<string, any>;

  tableData = [];
  cols;

  GetActivityMapKey(act) {
    return `${act.SyncId},${act.ActivityObjectId}`;
  }

  @Input()
  set logs(logs) {
    if(!logs) {
      return;
    }
    const syncMap = this.groupBy(logs, (log) => this.GetActivityMapKey(log));
    this.displayDataMap = new Map<string, any>();
    syncMap.forEach((val, key, map) => {
      const dis : any = {};
      const percentChangeLog = val.find(e => e.InternalPercentComplete || e.InternalPercentComplete === 0);
      dis.percentChange = percentChangeLog ? percentChangeLog : val[0];
      dis.comments = val.filter(e => e.CommentType);
      dis.justifications = val.filter(e => e.ActivityStartJustificationTypeText);
      this.displayDataMap.set(key, dis);
    });
    this.tableData = [];
    this.displayDataMap.forEach((val) => {
      val.percentChange.DisplayDate = this.momentPipe.transform(val.percentChange.CreateDate, 'LLLL');
      this.tableData.push(val.percentChange);
    });
  }

  async ngOnInit(): Promise<void> {
    this.cols = [
      { field: 'InternalStatus', header: await firstValueFrom(this.translate.get('COMPONENTS.Status')) },
      { field: 'ActivityId', header: await firstValueFrom(this.translate.get('COMPONENTS.ActivityId')) },
      { field: 'InternalPercentComplete', header: await firstValueFrom(this.translate.get('COMPONENTS.Complete')) },
      { field: 'DisplayDate', header: await firstValueFrom(this.translate.get('COMPONENTS.TimeUpdated')), filterBy: 'CreateDate' },
      { field: 'CreatedBy', header: await firstValueFrom(this.translate.get('COMPONENTS.User')) },
      { field: 'UserRoleText', header: await firstValueFrom(this.translate.get('COMPONENTS.UserRole')) }
    ];
  }

  syncHasCommentsOrJustifications(activity) {
    const displayData = this.displayDataMap.get(this.GetActivityMapKey(activity));
    return displayData
    && ((displayData.justifications && displayData.justifications.length !== 0)
    || (displayData.comments && displayData.comments.length !== 0));
  }

  groupBy (arr, criteria) {
    const result = new Map<any, any[]>();
    arr.forEach(e => {
      const key = typeof criteria === 'function' ? criteria(e) : e[criteria];
      if(!result.has(key)) {
        result.set(key, []);
      }
      result.get(key).push(e);
    });
    return result;
  }
}
