import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Shift } from '#models/shift';
@Injectable()
export class ShiftService {
  constructor(private http: HttpClient) {}

  public getShiftList(projectId: number): Observable<Shift[]> {
    return this.http.get<Shift[]>(`${environment.apiEndpoint}/shifts?projectId=${projectId}&enabled=true`);
  }

  public createShiftList(shifts: Shift[], endtimeOffset: Date): Observable<Shift[]> {
    const body = [];
    for (let i = 0; i < shifts.length; i++) {
      const shift = shifts[i];
      if (shift.Id === undefined && !shift.Enabled) {
        continue;
      }
      shift.Sequence = i + 1;
      shift.EndTimeOffset = endtimeOffset;
      body.push(shift.toJSON());
    }
    return this.http.post<Shift[]>(`${environment.apiEndpoint}/shifts`, body);
  }
}
