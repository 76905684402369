import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { AuthService } from '#services/shared';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserService } from '#services/api';

@Injectable()
export class IsSystemAdmin  {
    constructor(
        private authSvc: AuthService,
        private userSvc:UserService,
        private router: Router
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this.userSvc.isSystemAdmin() || this.userSvc.isScheduler()) {
            return true;
        } else {
            this.router.navigate([ this.authSvc.isAuthenticated() ? '/error/no-access' : '/login']);
            return false;
        }
    }
}

@Injectable()
export class IsApplicationUser  {
    constructor(
        private authSvc: AuthService,
        private userSvc: UserService,
        private router: Router
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this.userSvc.isAuthorized()) {
            return true;
        } else {
            this.router.navigate([this.authSvc.isAuthenticated() ? '/error/no-access' : '/login']);
            return false;
        }
    }
}
