import { BusinessUnit } from 'models';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class BusinessUnitsService {
    constructor(
        private http: HttpClient
    ) { }

    public getBusinessUnits(): Observable<BusinessUnit[]> {
        return this.http.get<BusinessUnit[]>(`${environment.apiEndpoint}/business-unit`);
    }

    public addBusinessUnit(businessUnit: BusinessUnit): Observable<BusinessUnit> {
        return this.http.post<BusinessUnit>(`${environment.apiEndpoint}/business-unit`, businessUnit);
    }
}
