<div class="activity-report panel">
  <h2>{{'ACTIVITYREPORT.Title' | translate}}</h2>
  <div class="container">
    <div class="row">
      <div class="col-sm-2"><label>{{'ACTIVITYREPORT.ProjectName' | translate}}:</label></div>
      <div class="col-sm-6"><span>{{currentProject?.Name}}</span></div>
    </div>
    <div class="row">
      <div class="col-sm-2"><label>{{'ACTIVITYREPORT.ProjectId' | translate}}:</label></div>
      <div class="col-sm-6"><span>{{currentProject?.ProjectId}}</span></div>
    </div>
    <div class="row">
      <button class="btn btn-primary" (click)="onDeltaReportClick()">
        <span class="pi pi-home"></span>
        <span style="float:right"> </span>
      </button>
    </div>
    <div class="row">
      <label class="activity-info">*{{'ACTIVITYREPORT.24Hours' | translate}}</label>
    </div>
  </div>
  <hr>
  <div class="container">
    <app-activity-report-filters [projectId]="currentProjectId" [searchKey]='searchText' (filter)="applyFilter($event)"
      (groupActivities)="groupActivitiesBy($event)" (searchText)="searchActivities($event)"
      (collapse)="collapseAll($event)">
    </app-activity-report-filters>
  </div>
  <hr>
  <div *ngIf="activities && activities.length > 0">
    <div *ngIf="!isGrouped">
      <app-activity-report-grid [activities]="activities" [pageSize]="pageSize" [searchText]="searchText"
        [(sortOrder)]="sortOrder" [(sortField)]="sortField">
      </app-activity-report-grid>
    </div>

    <div *ngIf="isGrouped" class="group-activities">
      <p-accordion [multiple]="true">
        <p-accordionTab *ngFor="let act of groupedActivities | async" [selected]="tabExpanded">
          <p-header>
            <label>{{act.header}} :</label><label class="activity-panel-header">{{act.activities.length}}
              {{'ACTIVITYREPORT.Activities' | translate}}</label>
          </p-header>
          <app-activity-report-grid #agrid [activities]="act.activities" [searchText]="searchText" [pageSize]="pageSize"
            [(sortOrder)]="sortOrder" [(sortField)]="sortField">
          </app-activity-report-grid>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
  <div *ngIf="!activities || activities.length === 0">
    <h5>{{'ACTIVITYREPORT.NoData' | translate}}</h5>
  </div>

</div>
