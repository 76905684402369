export const PRIMARY_CREW: string = 'Crew-1';
export const SUPPORTING_CREW: string = 'Crew-2';
export const COMPANY_REPS: string = 'Co-Reps';
export const PRIORITY: string = 'Priority';
export const PA_JOB_NUMBER: string = 'PA Job Number';

export class ActivityCodeType {
    Name: string;
    Id: number;
    Scope: string;
}
