<div class="panel">
  <h2>{{'SYNCHRONIZATION.ActivityHistory' | translate}}</h2>
  <div class="container">
    <div class="row">
      <div class="col-sm-2"><label>{{'SYNCHRONIZATION.ProjectName' | translate}}:</label></div>
      <div class="col-sm-6"><span>{{(project | async)?.Name}}</span></div>
    </div>
    <div class="row">
      <div class="col-sm-2"><label>{{'SYNCHRONIZATION.PrimaveraId' | translate}}:</label></div>
      <div class="col-sm-6"><span>{{(project | async)?.ProjectId}}</span></div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <app-return-to-delta-report-btn [projectObjectId]="this.queryParams.projectObjectId"></app-return-to-delta-report-btn>
      </div>
    </div>
  </div>
  <hr>
  <div class="container">
    <app-activity-audit-log-filter (search)="ExecuteSearch($event)" [preFilters]="this.queryParams"></app-activity-audit-log-filter>
    <hr>
    <app-activity-audit-log-grid [logs]="this.logs"></app-activity-audit-log-grid>
  </div>
</div>
