<p-dialog
  header="Sync Master Data"
  [visible]="display"
  [modal]="true">
  <p>Clicking on the ‘Sync’ button refreshes supporting master data related to a project has been changed in Primavera.<br>
    Examples of supporting master data include: Resources, Project User Profiles, Activity Codes, OBS's, WBS Categories.<p></p>
        <p-footer>
            <button (click)="masterDataSync()" type="button" pButton icon="pi pi-refresh" label="Sync"></button>
            <button (click)="display = false" type="button" pButton icon="pi pi-times" label="Cancel"></button>
        </p-footer>
</p-dialog>
