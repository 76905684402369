<div class="shiftGrid">
  <h4>{{'COMPONENTS.ShiftConfiguration' | translate}}</h4>

  <!-- Project -->
  <div class="grid">
    <div class="col-2">
      <label>{{'COMPONENTS.Project' | translate}}: </label>
    </div>
    <div *ngIf="project" class="col-4">
      <input type="search" value="{{project.ProjectId}} - {{project.Name}}" disabled class="form-control">
    </div>
  </div>
  <!-- OBS -->
  <div class="grid">
    <div class="col-2">
      <label>{{'COMPONENTS.ProjectLocation' | translate}}: </label>
    </div>
    <div class="col-4">
      <input type="search" value="{{projectLocation}}" disabled class="form-control">
    </div>
  </div>
  <!-- Timezone -->
  <div class="grid">
    <div class="col-2">
      <label>{{'COMPONENTS.Timezone' | translate}}: </label>
    </div>
    <div class="col-4">
      <label>{{projectTimezone}}</label>
    </div>
  </div>
  <!-- Update By -->
  <div class="grid">
    <div class="col-2">
      <label>{{'COMPONENTS.UpdateDue' | translate}}: </label>
    </div>
    <div class="col-5">
      <select [(ngModel)]="offsetHour" class="shiftSelect" [disabled]="isProjectCompleted">
        <option *ngFor="let hour of offsetHourOptions" [ngValue]="hour">{{hour}}</option>
      </select>
      <label>{{'COMPONENTS.Hours' | translate}}</label>
      <select [(ngModel)]="offsetMinute" class="shiftSelectAlt" [disabled]="isProjectCompleted">
        <option *ngFor="let minute of offsetMinuteOptions" [ngValue]="minute">{{minute}}</option>
      </select>
      <label>{{'COMPONENTS.MinutesEndtime' | translate}}</label>
    </div>
  </div>
  <!-- Shifts -->
  <div class="grid">
    <div class="col-2">
      <label>{{'COMPONENTS.Shifts' | translate}}: </label>
    </div>
  </div>
  <p-table [value]="shifts">
    <ng-template pTemplate="header">
        <tr>
            <th scope="col">{{'COMPONENTS.StartTime' | translate}}</th>
            <th scope="col">{{'COMPONENTS.EndTime' | translate}}</th>
            <th scope="col">{{'COMPONENTS.ShiftName' | translate}}</th>
            <th scope="col">
              <p-button icon="pi pi-plus"  styleClass="p-button-rounded p-button-success" (onClick)="addShift()" [disabled]="isShiftLimitReached() || isProjectCompleted"></p-button>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-shift>
        <tr *ngIf="shift.Enabled">
            <td>
              <p-calendar appendTo="body" [(ngModel)]="shift.StartTime" [timeOnly]="true" [stepHour]=1 [stepMinute]=30 (ngModelChange)="toggleSaveButton()"></p-calendar>
            </td>
            <td>
              <p-calendar appendTo="body" [(ngModel)]="shift.EndTime" [timeOnly]="true" [stepHour]=1 [stepMinute]=30 (ngModelChange)="toggleSaveButton()"></p-calendar>
            </td>
            <td>
              <input type="text" pInputText [(ngModel)]="shift.Name" class="shiftInput" (ngModelChange)="toggleSaveButton()" />
            </td>
            <td>
              <p-button icon="pi pi-trash" styleClass="p-button-rounded p-button-danger p-button-text" (click)="deleteShift(shift)" [disabled]="isProjectCompleted"></p-button>
          </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="grid">
        <div class="col-2" *ngIf="!isProjectCompleted">
          <p-button label="{{'COMPONENTS.Save' | translate}}"
                    icon="pi pi-check"
                    styleClass="p-button-raised"
                    [disabled]="!enableSaveButton"
                    (click)="saveProjectShifts()"
                    >
        </p-button>
        </div>
      </div>
    </ng-template>
</p-table>
</div>

