import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ProjectService,CrewAssignmentService } from '#services/api'
import { TranslateService } from '@ngx-translate/core';
import { CrewAssignment } from '#models/crewAssignment';
import { GroupMessage } from '#models/groupmessage';

@Component({
  selector: 'app-team-communication',
  templateUrl: './team-communication.component.html',
  styleUrls: ['./team-communication.component.scss']
})
export class TeamCommunicationComponent implements OnInit {

  selectedProject: { id: number, name: string} = { id: null, name: ''};
  displayAddDialog: boolean = false;
  groupList: CrewAssignment[];
  selectedGroupList: CrewAssignment[];
  groupMessage: GroupMessage;
  message: string;
  constructor(
    private projectService: ProjectService,
    private messageSvc: MessageService,
    private confirmationSvc: ConfirmationService,
    private crewSvc: CrewAssignmentService,
    private translate: TranslateService) { }

  ngOnInit() {
    this.selectedProject.name = localStorage.getItem('selectedProject');
    this.selectedProject.id = parseInt(localStorage.getItem('selectedProjectId'));
    this.crewSvc
    .getAssignmentList(this.selectedProject.id)
    .subscribe(
      (data: CrewAssignment[]) => {
        data.forEach(a => {
          this.crewSvc.fullfiilModel(a);
        });
        this.groupList = data.filter(a => !a.IsDeleted);
        this.groupList = this.groupList.filter((value, index, self) => self.findIndex((m) => m.Crew.CrewId === value.Crew.CrewId) === index);
      }
    );
  }

  confirmSendEmail() {
    this.confirmationSvc.confirm({
      header: this.translate.instant('COMMUNICATION.ConfirmationHeader'),
      message: this.translate.instant('COMMUNICATION.EmailConfirmation'),
      acceptLabel: this.translate.instant('SHARED.Yes'),
      rejectVisible: true,
      accept: () => {
        this.sendEmailLookAhead();
      }
    });
  }

  confirmSendNotification() {
    this.confirmationSvc.confirm({
      header: this.translate.instant('COMMUNICATION.ConfirmationHeader'),
      message: this.translate.instant('COMMUNICATION.NotificationConfirmation'),
      acceptLabel: this.translate.instant('SHARED.Yes'),
      rejectVisible: true,
      accept: () => {
        this.sendProjectNotification();
      }
    });
  }

  sendEmailLookAhead() {
    this.projectService.emailLookaheadProjectStatus(this.selectedProject.id).subscribe({
      next:() => this.sendMessage('success', this.translate.instant('COMMUNICATION.EmailSent'), this.translate.instant('COMMUNICATION.EmailSentMessage')),
      error:() => {
        (async () => {
        this.sendMessage('error', this.translate.instant('COMMUNICATION.EmailNotSent'), this.translate.instant('COMMUNICATION.EmailNotSentMessage'))
        })();
      }
  });
  }

  sendProjectNotification() {
    this.projectService.primaveraDownProjectNotification(this.selectedProject.id).subscribe({
      next:() => this.sendMessage('success', this.translate.instant('COMMUNICATION.NotificationSent'), this.translate.instant('COMMUNICATION.NotificationSentMessage')),
      error:() => {
          (async () => {
        this.sendMessage('error', this.translate.instant('COMMUNICATION.NotificationNotSent'), this.translate.instant('COMMUNICATION.EmailNotSentMessage'))
      })();
      }
  });
  }

  sendMessage(type, message, detail) {
    this.messageSvc.add({
      severity: type,
      summary: message,
      sticky: true,
      detail: detail
    });
  }

  showDialog() {
    this.displayAddDialog = true;
  }

  closeDialog() {
    this.displayAddDialog = false;
  }

  sendGroupMessage() {
    this.groupMessage = { ProjectId: this.selectedProject.id, Message: this.message, CrewAssignments: this.selectedGroupList  };
    this.projectService.sendMessageToGroups(this.groupMessage).subscribe({
      next:() => this.sendMessage('success', this.translate.instant('COMMUNICATION.NotificationSent'), this.translate.instant('COMMUNICATION.NotificationSentMessage')),
      error:() =>
      {
        (async () => {
          this.sendMessage('error', this.translate.instant('COMMUNICATION.NotificationNotSent'), this.translate.instant('COMMUNICATION.EmailNotSentMessage'))
        })();
      }
  });
  }

}
