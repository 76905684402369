<p-table #dt [value]="activities" styleClass="p-datatable-gridlines" *ngIf="activities && activities.length > 0" [sortField]="sortField"
  [sortOrder]="sortOrder" [paginator]="showPaginator" [rows]="pageSize" paginatorPosition="bottom" [lazy]="false"
  filterBy="ActivityId,ActivityName,PrimaryResource,Crew1,Crew2,CoRep,Comment"
  [globalFilterFields]="['ActivityId', 'ActivityName', 'PrimaryResource','Crew1','Crew2','CoRep','Comment']"
 >

   <ng-template pTemplate="caption">
    <div class="flex">
        <span class="p-input-icon-left ml-auto">
            <input pInputText type="text"  (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
        </span>
    </div>
</ng-template>

  <ng-template pTemplate="header">
    <tr class="report-header">
      <th scope="col">
      <div
        [ngClass]="{'sorted': sortField == 'ActivityId', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
        <a (click)="onSortChange('ActivityId')">{{'COMPONENTS.ActivityId' | translate}}</a>
      </div>
      </th>
      <th scope="col">
      <div
        [ngClass]="{'sorted': sortField == 'ActivityName', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
        <a (click)="onSortChange('ActivityName')">{{'COMPONENTS.ActivityName' | translate}}</a>
      </div>
    </th>
    <th scope="col">
      <div
        [ngClass]="{'sorted': sortField == 'WorkPriority', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
        <a (click)="onSortChange('WorkPriority')">{{'COMPONENTS.WorkPriority' | translate}}</a>
      </div>
    </th>
    <th scope="col">
      <div
        [ngClass]="{'sorted': sortField == 'PrimaryResource', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
        <a (click)="onSortChange('PrimaryResource')">{{'COMPONENTS.PrimaryResource' | translate}}</a>
      </div>
    </th>
    <th scope="col">
      <div
        [ngClass]="{'sorted': sortField == 'PrimaveraPercentComplete', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
        <a (click)="onSortChange('PrimaveraPercentComplete')">{{'COMPONENTS.Primavera' | translate}} %</a>
      </div>
    </th>
    <th scope="col">
      <div
        [ngClass]="{'sorted': sortField == 'InternalPercentComplete', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
        <a (click)="onSortChange('InternalPercentComplete')">{{'COMPONENTS.MobileUpdated' | translate}} %</a>
      </div>
    </th>
    <th scope="col">
      <div
        [ngClass]="{'sorted': sortField == 'ActualDatesChanged', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
        <a (click)="onSortChange('ActualDatesChanged')">{{'COMPONENTS.ActualDatesUpdated' | translate}}</a>
      </div>
    </th>
    <th scope="col">
      <div
        [ngClass]="{'sorted': sortField == 'ActualStartDate', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
        <a (click)="onSortChange('ActualStartDate')">{{'COMPONENTS.MobileStartDate' | translate}}</a>
      </div>
    </th>
    <th scope="col">
      <div
        [ngClass]="{'sorted': sortField == 'ActualFinishDate', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
        <a (click)="onSortChange('ActualFinishDate')">{{'COMPONENTS.MobileFinishDate' | translate}}</a>
      </div>
    </th>
    <th scope="col">
      <div
        [ngClass]="{'sorted': sortField == 'Crew1', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
        <a (click)="onSortChange('Crew1')">{{'COMPONENTS.PrimaryCrew' | translate}}</a>
      </div>
    </th>
    <th scope="col">
      <div
        [ngClass]="{'sorted': sortField == 'CoRep', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
        <a (click)="onSortChange('CoRep')">{{'COMPONENTS.CompanyReps' | translate}}</a>
      </div>
    </th>
    <th scope="col">
      <div><a>{{'COMPONENTS.Comment' | translate}}</a></div>
    </th>
    <th scope="col" >
      <div><a>{{'COMPONENTS.History' | translate}}</a></div>
    </th>

    </tr>
  </ng-template>
  <ng-template let-a pTemplate="body">
    <tr class="report-row centerContent" [ngClass]="{'exception' : a.IsFutureShift === true || a.IsOutOfSequence === true}">
      <td>
        <span class="warn" title="Future shift" *ngIf="a.IsFutureShift === true">
            <i class="pi-exclamation-circle"></i>
        </span>
        <span class="warn" title="Out of Sequence" *ngIf="a.IsOutOfSequence === true">
          <i class="pi-exclamation-circle"></i>
      </span>
        <p-checkbox [(ngModel)]="a.isApproved" binary="true" *ngIf="showrejectpanel" [style]="{float: 'left'}"></p-checkbox>
        <span>{{a.ActivityId}}</span>
    </td>
      <td>{{a.ActivityName}}</td>
      <td>{{a.WorkPriority}}</td>
      <td>{{a.PrimaryResource}}</td>
      <td>{{a.PrimaveraPercentComplete}}</td>
      <td>{{a.InternalPercentComplete}}</td>
      <td>{{a.ActualsManuallyUpdated ? 'Yes' : 'No'}}</td>
     <td><ng-container *ngIf="!a.ActualsManuallyUpdated">
        {{a.ActualStartDate | momentPipe: 'L HH:mm': true}}
        {{a.ActualFinishDate | momentPipe: 'L HH:mm': true}}
      </ng-container></td>
      <td><ng-container *ngIf="a.ActualsManuallyUpdated">
        {{a.InternalActualStartDate | momentPipe: 'L HH:mm': true}}
        {{a.InternalActualFinishDate | momentPipe: 'L HH:mm': true}}
      </ng-container></td>
      <td>{{a.Crew1}}</td>
      <td>{{a.CoRep}}</td>
      <td><a *ngIf="a.HasJustifications" (click)="onClickShowJustifications(a)">{{'COMPONENTS.Details' | translate}}</a></td>
      <td><a (click)="this.onAuditLogClick(a)">{{'COMPONENTS.History' | translate}}</a>
    </tr>
    <tr>
      <td colspan="13">
    <div class="ug clearfix" *ngIf="showrejectpanel && (a.isApproved === false)">
      <p-card subtitle="Please specify rejection comment" [style]="{width: '95%'}"
        styleClass="container ui-card-shadow">
        <div>
          <textarea pInputTextarea rows="3" style="width:100%" [(ngModel)]="a.rejectionComment"></textarea>
        </div>
        <p-footer>
          <p-button label="{{'COMPONENTS.Cancel' | translate}}" styleClass="p-button-raised p-button-secondary" (click)="cancelRejection(a)"></p-button>&nbsp;
          <p-button label="{{'COMPONENTS.Reject' | translate}}" styleClass="p-button-raised" (click)="raiseRejection(a)"
            [disabled]="!a.rejectionComment || a.rejectionComment.length===0 "></p-button>
        </p-footer>
      </p-card>
    </div>
    <div *ngIf="a.DisplayJustifications">
      <app-activity-justifications [activityId]="a.Id"></app-activity-justifications>
      <hr style="margin-bottom: 0px">
    </div>
    </td>
    </tr>
  </ng-template>
</p-table>
<!-- <p-dataView #dv [value]="activities" *ngIf="activities && activities.length > 0" [sortField]="sortField"
  [sortOrder]="sortOrder" [paginator]="showPaginator" [rows]="pageSize" paginatorPosition="bottom"
  filterBy="ActivityId,ActivityName,PrimaryResource,Crew1,Crew2,CoRep,Comment">
  <p-header>
    <div class="grid report-header">
      <div class="col col-1"
        [ngClass]="{'sorted': sortField == 'ActivityId', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
        <a (click)="onSortChange('ActivityId')">{{'COMPONENTS.ActivityId' | translate}}</a>
      </div>
      <div class="col col-1"
        [ngClass]="{'sorted': sortField == 'ActivityName', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
        <a (click)="onSortChange('ActivityName')">{{'COMPONENTS.ActivityName' | translate}}</a>
      </div>
      <div class="col col-1"
        [ngClass]="{'sorted': sortField == 'WorkPriority', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
        <a (click)="onSortChange('WorkPriority')">{{'COMPONENTS.WorkPriority' | translate}}</a>
      </div>
      <div class="col col-1"
        [ngClass]="{'sorted': sortField == 'PrimaryResource', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
        <a (click)="onSortChange('PrimaryResource')">{{'COMPONENTS.PrimaryResource' | translate}}</a>
      </div>
      <div class="col col-1"
        [ngClass]="{'sorted': sortField == 'PrimaveraPercentComplete', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
        <a (click)="onSortChange('PrimaveraPercentComplete')">{{'COMPONENTS.Primavera' | translate}} %</a>
      </div>
      <div class="col col-1"
        [ngClass]="{'sorted': sortField == 'InternalPercentComplete', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
        <a (click)="onSortChange('InternalPercentComplete')">{{'COMPONENTS.MobileUpdated' | translate}} %</a>
      </div>
      <div class="col col-1"
        [ngClass]="{'sorted': sortField == 'ActualDatesChanged', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
        <a (click)="onSortChange('ActualDatesChanged')">{{'COMPONENTS.ActualDatesUpdated' | translate}}</a>
      </div>
      <div class="col col-1"
        [ngClass]="{'sorted': sortField == 'ActualStartDate', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
        <a (click)="onSortChange('ActualStartDate')">{{'COMPONENTS.MobileStartDate' | translate}}</a>
      </div>
      <div class="col col-1"
        [ngClass]="{'sorted': sortField == 'ActualFinishDate', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
        <a (click)="onSortChange('ActualFinishDate')">{{'COMPONENTS.MobileFinishDate' | translate}}</a>
      </div>
      <div class="col col-1"
        [ngClass]="{'sorted': sortField == 'Crew1', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
        <a (click)="onSortChange('Crew1')">{{'COMPONENTS.PrimaryCrew' | translate}}</a>
      </div>
      <div class="col col-1"
        [ngClass]="{'sorted': sortField == 'CoRep', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
        <a (click)="onSortChange('CoRep')">{{'COMPONENTS.CompanyReps' | translate}}</a>
      </div>
      <div class="col col-1"><a>{{'COMPONENTS.Comment' | translate}}</a></div>
      <div class="col col-1"><a>{{'COMPONENTS.History' | translate}}</a></div>
    </div>
  </p-header>
  <ng-template let-a pTemplate="listItem">
    <div class="grid report-row centerContent" [ngClass]="{'exception' : a.IsFutureShift === true || a.IsOutOfSequence === true}">
      <div class="col col-1">
        <span class="warn" title="Future shift" *ngIf="a.IsFutureShift === true">
            <i class="pi-exclamation-circle"></i>
        </span>
        <span class="warn" title="Out of Sequence" *ngIf="a.IsOutOfSequence === true">
          <i class="pi-exclamation-circle"></i>
      </span>
        <p-checkbox [(ngModel)]="a.isApproved" binary="true" *ngIf="showrejectpanel" [style]="{float: 'left'}"></p-checkbox>
        <span>{{a.ActivityId}}</span>
      </div>
      <div class="col col-1">{{a.ActivityName}}</div>
      <div class="col col-1">{{a.WorkPriority}}</div>
      <div class="col col-1">{{a.PrimaryResource}}</div>
      <div class="col col-1">{{a.PrimaveraPercentComplete}}</div>
      <div class="col col-1">{{a.InternalPercentComplete}}</div>
      <div class="col col-1">{{a.ActualsManuallyUpdated ? 'Yes' : 'No'}}</div>
      <ng-container *ngIf="!a.ActualsManuallyUpdated">
        <div class="col col-1">{{a.ActualStartDate | momentPipe: 'L HH:mm': true}}</div>
        <div class="col col-1">{{a.ActualFinishDate | momentPipe: 'L HH:mm': true}}</div>
      </ng-container>
      <ng-container *ngIf="a.ActualsManuallyUpdated">
        <div class="col col-1">{{a.InternalActualStartDate | momentPipe: 'L HH:mm': true}}</div>
        <div class="col col-1">{{a.InternalActualFinishDate | momentPipe: 'L HH:mm': true}}</div>
      </ng-container>
      <div class="col col-1">{{a.Crew1}}</div>
      <div class="col col-1">{{a.CoRep}}</div>
      <div class="col col-1"><a *ngIf="a.HasJustifications" (click)="onClickShowJustifications(a)">{{'COMPONENTS.Details' | translate}}</a></div>
      <div class="col col-1"><a (click)="this.onAuditLogClick(a)">{{'COMPONENTS.History' | translate}}</a></div>
    </div>
    <div class="ug clearfix" *ngIf="showrejectpanel && (a.isApproved === false)">
      <p-card subtitle="Please specify rejection comment" [style]="{width: '95%'}"
        styleClass="container ui-card-shadow">
        <div>
          <textarea pInputTextarea rows="3" style="width:100%" [(ngModel)]="a.rejectionComment"></textarea>
        </div>
        <p-footer>
          <p-button label="{{'COMPONENTS.Cancel' | translate}}" styleClass="ui-button-secondary" (click)="cancelRejection(a)"></p-button>&nbsp;
          <p-button label="{{'COMPONENTS.Reject' | translate}}" styleClass="ui-button-primary" (click)="raiseRejection(a)"
            [disabled]="!a.rejectionComment || a.rejectionComment.length===0 "></p-button>
        </p-footer>
      </p-card>
    </div>
    <div *ngIf="a.DisplayJustifications">
      <app-activity-justifications [activityId]="a.Id"></app-activity-justifications>
      <hr style="margin-bottom: 0px">
    </div>
  </ng-template>
</p-dataView> -->


<div *ngIf="!activities || activities.length ===0">
  <h5>{{'COMPONENTS.NoData' | translate}}</h5>
</div>
