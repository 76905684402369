
  <p-table #dvr [value]="activities" *ngIf="activities && activities.length > 0" [sortField]="sortField"
    [sortOrder]="sortOrder" [paginator]="true" [rows]="10" paginatorPosition="bottom" [rowsPerPageOptions]="[10, 25, 50]"
    [globalFilterFields]="['ActivityId','Name','WbsName','Status','Crew1','Crew2','CoRep']"  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
    <ng-template pTemplate="caption">
      <div class="flex">
        <span class="p-input-icon-left ml-auto">
        <label>{{'COMPONENTS.SearchActivity' | translate}}</label>
        <input pInputText type="search" (keyup)="dvr.filterGlobal($event.target.value, 'contains')" class="form-control" placeholder="{{'SHARED.StartTyping' | translate}}" />
      </span>
      </div>
  </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th scope="col" pSortableColumn="Status">

          <label>{{'COMPONENTS.Status' | translate}}</label><p-sortIcon field="Status"></p-sortIcon>

      </th>
    <th scope="col" pSortableColumn="ActivityId">

          <label>{{'COMPONENTS.ActivityId' | translate}}</label><p-sortIcon field="ActivityId"></p-sortIcon>

      </th>
    <th scope="col" pSortableColumn="Name">
        <div
          [ngClass]="{'sorted': sortField == 'Name', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
          <label>{{'COMPONENTS.ActivityName' | translate}}</label><p-sortIcon field="Name"></p-sortIcon>
        </div>
      </th>
    <th scope="col" pSortableColumn="WbsName">
        <div
          [ngClass]="{'sorted': sortField == 'WbsName', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
          <label>{{'COMPONENTS.JobName' | translate}}</label>
          <p-sortIcon field="WbsName"></p-sortIcon>
        </div>
      </th>
    <th scope="col" pSortableColumn="PrimaveraPercentComplete">
        <div
          [ngClass]="{'sorted': sortField == 'PrimaveraPercentComplete', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
          <label>{{'COMPONENTS.Primavera' | translate}} %</label>
          <p-sortIcon field="PrimaveraPercentComplete"></p-sortIcon>
        </div>
      </th>
    <th scope="col" pSortableColumn="InternalPercentageComplete">
        <div
          [ngClass]="{'sorted': sortField == 'InternalPercentComplete', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
          <label>{{'COMPONENTS.MobileUpdated' | translate}} %</label>
          <p-sortIcon field="InternalPercentageComplete"></p-sortIcon>
        </div>
      </th>
    <th scope="col" pSortableColumn="Crew1">
        <div
          [ngClass]="{'sorted': sortField == 'Crew1', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
          <label>{{'COMPONENTS.PrimaryCrew' | translate}}</label>
          <p-sortIcon field="Crew1"></p-sortIcon>
        </div>
      </th>
    <th scope="col" pSortableColumn="Crew2">
        <div
          [ngClass]="{'sorted': sortField == 'Crew2', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
          <label>{{'COMPONENTS.SupportingCrew' | translate}}</label>
          <p-sortIcon field="Crew2"></p-sortIcon>
        </div>
      </th>
    <th scope="col" pSortableColumn="CoRep">
        <div
          [ngClass]="{'sorted': sortField == 'CoRep', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
          <label>{{'COMPONENTS.CompanyReps' | translate}}</label>
          <p-sortIcon field="CoRep"></p-sortIcon>
        </div>
      </th>
    <th scope="col">
        <div>
          <label>{{'COMPONENTS.Comments' | translate}}</label>
        </div>
      </th>
      </tr>
    </ng-template>
    <ng-template let-a pTemplate="body">

<tr>
          <td>{{getStatusText(a.Status)}}</td>
          <td>{{a.ActivityId}}</td>
          <td>{{a.Name}}</td>
          <td>{{a.WbsName}}</td>
          <td>{{a.PrimaveraPercentComplete}}</td>
          <td>{{a.InternalPercentComplete}}</td>
          <td>{{a.Crew1}}</td>
          <td>{{a.Crew2}}</td>
          <td>{{a.CoRep}}</td>
          <td><a *ngIf="a.HasJustifications" (click)="onClickShowJustifications(a)">{{'COMPONENTS.Details' | translate}}</a></td>
</tr>
<tr>
        <td colspan="10">
          <div *ngIf="a.DisplayJustifications" class="activity-justifications">
            <app-activity-justifications [activityId]="a.Id"></app-activity-justifications>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
          <td colspan="10">
           <h5>No Records Found</h5>
        </td>
      </tr>
  </ng-template>
  </p-table>
  <div *ngIf="!activities || activities.length === 0">
    <h5>{{'COMPONENTS.NoDataAvailable' | translate}}</h5>
  </div>


