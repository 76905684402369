export const SYSTEM_ADMIN: string = 'SystemAdmin';
export const WEB_PORTAL: string = 'WebPortal';

export const SCHEDULER: string = 'SC';
export const COMPANY_REP: string = 'CR';
export const COMPANY_FORMAN: string = 'CF';


export class ProjectRole {
  ProjectId: number;
  Roles: string[];
}
