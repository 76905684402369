import { Component } from '@angular/core';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent  {
  spinner = faSpinner;
  size = '10x';

  constructor(
    public loadingIndicatorSvc: LoadingIndicatorService
  ) { }

 
}
