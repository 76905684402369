<ng-container *ngIf="this.tableData && this.tableData.length !== 0; else elseTemplate">
    <div class="non-scrollable-table">
      <p-table [columns]="this.cols"
        [value]="this.tableData"
        [autoLayout]="true"
        [rows]="20"
        sortField="CreateDate"
        sortOrder="-1">

        <ng-template pTemplate="header" let-columns>
          <tr>
            <th scope="col">{{'COMPONENTS.OverwritePrimavera' | translate}} %</th>
            <th scope="col" *ngFor="let col of columns" [pSortableColumn]="col.filterBy ? col.filterBy : col.field">
              {{col.header}}<p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td>
              <p-checkbox name="Name?" [value]="!rowData.upload" (onChange)="rowData.upload = !rowData.upload" binary="true"></p-checkbox>
            </td>
            <td *ngFor="let col of columns">{{rowData[col.field]}}</td>
          </tr>
          <tr *ngIf="!rowData.upload">
            <td [colSpan]="this.cols.length + 1">
              <div>
                <p-card subtitle="Please specify the override reason" [style]="{width: '100%'}"
                styleClass="container ui-card-shadow">
                  <textarea pInputTextarea rows="3" style="width:100%" [(ngModel)]="rowData.overrideComment"></textarea>
                </p-card>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-container>
  <ng-template #elseTemplate>
    <H4>{{'COMPONENTS.NoDataToUpload' | translate}}</H4>
  </ng-template>
