<div>
  <h2>{{'MOBILEMANAGEMENT.Title' | translate}}</h2>
  <h6 style="color: #ed4337">{{'MOBILEMANAGEMENT.Disclaimer' | translate}}</h6>
</div>

<div>
  <div>
    <div class="grid"><div class="col-2"><h4 style="text-align:left;float:left"><i class="pi pi-mobile"></i>{{'MOBILEMANAGEMENT.VersionList' | translate}}</h4></div>
      <div class="col-2 col-offset-8">
      <button type="button" class="btn btn-primary action-btn" [disabled]="isAddMode" (click)="enableAddNewVersion()">
        <span class="pi pi-plus-circle"></span>
        <span>{{'MOBILEMANAGEMENT.AddNew' | translate}}</span>
      </button></div>
    </div>
    <div *ngIf="isAddMode">

      <div class="grid">
        <div class="col-1">
          {{'MOBILEMANAGEMENT.Version' | translate}}
        </div>
        <div class="col-4"><input type="text" pInputText placeholder="{{'SHARED.PleaseEnterVersion' | translate}}"
            [(ngModel)]="newVersion.Version">
        </div>
      </div>
      <div class="grid">
        <div class="col-1">
          {{'MOBILEMANAGEMENT.Status' | translate}}
        </div>
        <div class="col-4">
          <p-dropdown placeholder="{{'SHARED.PleaseSelect' | translate}}" [options]="statusList" [(ngModel)]="newVersion.Status"></p-dropdown>
        </div>
      </div>
      <div class="grid">
        <button type="button" class="btn btn-success action-btn" (click)="saveVersion(newVersion, true)">
          <span class="pi pi-save"></span>
          <span>{{'MOBILEMANAGEMENT.Save' | translate}}</span>
        </button>
        <button type="button" class="btn btn-danger action-btn" (click)="cancelAdd()">
          <span class="pi pi-ban"></span>
          <span>{{'MOBILEMANAGEMENT.Cancel' | translate}}</span>
        </button>
      </div>
    </div>
    <p-table #dt [value]="versionList" sortMode="multiple" styleClass="p-datatable-striped" [paginator]="true" [rows]="20">
      <ng-template pTemplate="header">
        <tr>
          <th scope="col" pSortableColumn="Version">
            {{'MOBILEMANAGEMENT.Version' | translate}}
            <p-sortIcon field="Version" ariaLabel="{{'PROJECTINFO.ActivateSort' | translate}}"
              ariaLabelDesc="{{'PROJECTINFO.ActivateSortDesc' | translate}}" ariaLabelAsc="{{'PROJECTINFO.ActivateSortAsc' | translate}}">
            </p-sortIcon>
          </th>
          <th scope="col" pSortableColumn="Status">
            {{'MOBILEMANAGEMENT.Status' | translate}}
            <p-sortIcon field="Status" ariaLabel="{{'PROJECTINFO.ActivateSort' | translate}}" ariaLabelDesc="{{'PROJECTINFO.ActivateSortAsc' | translate}}"
              ariaLabelAsc="{{'PROJECTINFO.ActivateSortDesc' | translate}}">
            </p-sortIcon>
          </th>
          <th scope="col">
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-version>
        <tr>
          <td>{{version.Version}}</td>
          <td *ngIf="!isEditMode">{{version.Status}}</td>
          <td *ngIf="isEditMode">
            <p-dropdown placeholder="{{'SHARED.PleaseSelect' | translate}}" [options]="statusList" [(ngModel)]="version.Status"></p-dropdown>
          </td>
          <td>
            <div class="btn-container right" *ngIf="!isEditMode">
              <p-button icon=" pi pi-pencil" styleClass="p-button-rounded p-button-info p-button-text" (click)="editMode()">
              </p-button>
              <p-button icon="pi pi-trash" styleClass="p-button-rounded p-button-danger p-button-text" (click)="deleteVersion(version)">
              </p-button>
            </div>
            <div class="btn-container right" *ngIf="isEditMode">
              <p-button icon="pi pi-save" styleClass="p-button-rounded p-button-success p-button-text"
                (click)="saveVersion(version,false)">
              </p-button>
              <p-button icon="pi pi-ban" styleClass="p-button-rounded p-button-danger p-button-text" (click)="disableEditMode()">
              </p-button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
