import { Injectable } from '@angular/core';

@Injectable()
export class LoadingIndicatorService {
  private counter: number;

  header: string;
  content: string;

  constructor() {
    this.counter = 0;
  }

  public show(header: string = null, content: string = null): void {
    header = header || 'Loading, please wait...';
    this.header = header;
    this.content = content;
    this.counter++;
  }

  public hide(): void {
    this.counter--;
    if (!this.isVisible()) {
      this.header = null;
      this.content = null;
    }
  }

  public isVisible(): boolean {
    return this.counter > 0;
  }
}
