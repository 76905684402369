import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MtaServerErrorHandler } from 'helpers/MtaServerErrorHandler';
import { environment } from '#environments/environment';
import { ErrorDialogService } from '#services/shared/error.dialog.service';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {

  constructor(private _confirmationService: ErrorDialogService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if(error.status !== 502) {
          if (error.url.includes(environment.apiEndpoint) && error.status !== 401) {
            const model = MtaServerErrorHandler.convertToErrorDialogModel(error);
            this._confirmationService.add(model);
          }
          return throwError(() => error);
        } else {
          throw new Error('Error in primavera');
        }
      })
    );
  }
}
