import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LoggerService, AuthService, NotificationService, Utils, AsyncCommunicationService } from './';
import { ErrorDialogService } from './error.dialog.service';

@NgModule({
  declarations: [
  ],
  imports: [
  ],
  providers: [
    LoggerService,
    AuthService,
    NotificationService,
    Utils,
    ErrorDialogService,
    AsyncCommunicationService
  ],
  exports: [
    TranslateModule
  ]
})

export class SharedModule { }
